import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { MenuOpen } from "@mui/icons-material";

const ProductTierSection = React.memo(({ lender }) => {
  const navigate = useNavigate();
  const handleViewProductTiers = () => navigate(`/lenders/${lender._id}/products`);
  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Product Tiers</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        <Button
          sx={{ width: "200px", ml: "8px" }}
          xs={12}
          variant="contained"
          startIcon={<MenuOpen />}
          onClick={handleViewProductTiers}
        >
          VIEW PRODUCT TIERS
        </Button>
      </Grid>
    </Grid>
  );
});

export default ProductTierSection;
