import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Add, Delete, Edit } from "@mui/icons-material";
import { gridDateComparator } from "@mui/x-data-grid";
import clsx from "clsx";
import { debounce } from "lodash";
import moment from "moment";
import { APPLICATION_TYPES } from "../../constants";
import { useAddLenderMutation, useDeleteLenderMutation, useLazyGetAllLendersQuery } from "../../store/slices/apiSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
import PageLoader from "../Application/PageLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const Lenders = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const navigate = useNavigate();
  const [params, setParams] = useState({ offset: 1 });
  const [filterType, setFilterType] = useState("all");
  const [trigger, { data, isLoading, isFetching }] = useLazyGetAllLendersQuery();
  const [addLender] = useAddLenderMutation();
  const [deleteLender] = useDeleteLenderMutation();
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [lender, setLender] = useState({
    lender: "",
    type: "consumer",
  });
  const [lenderToRemove, setLenderToRemove] = useState(null);
  const masterViewColumns = [
    {
      field: "lender",
      headerName: "Lender Name",
      type: "string",
      width: 90,
      editable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Application Type",
      type: "string",
      width: 110,
      editable: false,
      renderCell: (params) => (
        <Typography fontSize={"14px"} textTransform={"capitalize"}>
          {params.value}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      type: "date",
      width: 80,
      editable: false,
      renderCell: (params) => {
        const dateString = moment(params.value).format("M-DD-YY");
        const timeString = moment(params.value).format("h:mm a");
        return (
          <div>
            <Typography style={{ fontSize: "14px" }}>{dateString}</Typography>
            <Typography style={{ fontSize: "11px", color: "#999" }}>{timeString}</Typography>
          </div>
        );
      },
      flex: 1,
      sortComparator: (v1, v2) => gridDateComparator(v1, v2),
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      type: "date",
      width: 80,
      editable: false,
      renderCell: (params) => {
        const dateString = moment(params.value).format("M-DD-YY");
        const timeString = moment(params.value).format("h:mm a");
        return (
          <div>
            <Typography style={{ fontSize: "14px" }}>{dateString}</Typography>
            <Typography style={{ fontSize: "11px", color: "#999" }}>{timeString}</Typography>
          </div>
        );
      },
      flex: 1,
      sortComparator: (v1, v2) => gridDateComparator(v1, v2),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <Stack direction={"row"} columnGap={"8px"}>
          <IconButton disableRipple onClick={() => handleDeleteDialog(params.row)} variant="contained">
            <Delete color="error" />
          </IconButton>
          <IconButton disableRipple onClick={() => handleEditLender(params.row)} variant="contained">
            <Edit />
          </IconButton>
        </Stack>
      ),
      flex: 1,
    },
  ];

  const submitDisabled = useMemo(
    () => !lender.lender || !lender.type || lender.lender === "",
    [lender.lender, lender.type],
  );

  const handleClose = () => {
    setLender({
      lender: "",
      type: "consumer",
    });
    setOpen(false);
  };

  const handleAddLender = () => {
    addLender({
      body: {
        ...lender,
      },
    });
    setOpen(false);
  };

  const handleEditLender = (lender) => navigate(`/lenders/${lender._id}`);
  const handleRemoveLender = () => {
    deleteLender(lenderToRemove._id);
    setLenderToRemove(null);
    setIsDeleteOpen(false);
  };

  const handleDeleteDialog = (lender) => {
    setLenderToRemove(lender);
    setIsDeleteOpen(true);
  };

  const rowCountState = useMemo(() => {
    if (data && !isFetching) return data?.totalDocs;
    return 0;
  }, [data, isFetching]);

  const debounced = useCallback(
    debounce(
      (value) =>
        trigger({
          cursor: 1,
          lender: value,
          type: filterType,
          limit: 10,
        }),
      1000,
    ),
    [filterType],
  );

  useEffect(
    () =>
      trigger({
        cursor: params.offset,
        lender: searchTerm,
        type: filterType,
        limit: 10,
      }),
    [params.offset, filterType],
  );

  const handleSortModelChange = useCallback((sortModel) => {
    const sort = sortModel[0];
    trigger({
      cursor: params.offset,
      lender: searchTerm,
      type: filterType,
      limit: 10,
      sort,
    });
  }, []);

  const handleFilterModelChange = useCallback((filterModel) => {
    let columnFilter = filterModel.items[0];
    trigger({
      cursor: params.offset,
      lender: searchTerm,
      type: filterType,
      limit: 10,
      columnFilter,
    });
  }, []);

  return (
    <React.Fragment>
      {!data && isLoading ? (
        <Grid
          container
          style={{
            minWidth: "95vw",
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageLoader text="Lenders loading..." />
        </Grid>
      ) : (
        <Grid container item direction="column" spacing={2} style={{ padding: "20px", minWidth: "99vw" }}>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "40px",
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                fontWeight: 800,
                letterSpacing: "-0.5px",
              }}
            >
              Lenders
            </Typography>
            <div className={classes.root}>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <Input
                  className="input-border-bottom"
                  id="application-search"
                  autoComplete={false}
                  variant="filled"
                  placeholder="Search…"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    debounced(e.target.value, 1000);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchTerm("");
                            searchTerm !== "" && debounced("", 1000);
                          }}
                          color="secondary"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </div>
          </Grid>

          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "40px",
            }}
          >
            <ToggleButtonGroup
              value={filterType}
              exclusive
              onChange={(e) => setFilterType(e.currentTarget.value)}
              aria-label="text alignment"
            >
              <ToggleButton value="all" aria-label="centered">
                <Typography>All</Typography>
              </ToggleButton>
              {Object.keys(APPLICATION_TYPES).map((key) => (
                <ToggleButton value={APPLICATION_TYPES[key]} aria-label="centered">
                  <Typography>{APPLICATION_TYPES[key]}</Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Button variant="contained" startIcon={<Add />} onClick={() => setOpen(true)}>
              ADD NEW LENDER
            </Button>
          </Grid>

          <Grid item sx={{ margin: "auto", width: "100%" }}>
            <DataGridTable
              data={data?.docs ?? []}
              columns={masterViewColumns}
              onRowClick={(event) => {}}
              rowsPerPageOptions={[10]}
              pageSize={10}
              rowCount={rowCountState}
              params={params}
              setParams={setParams}
              page={data?.page ?? 1}
              isLoading={isFetching}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add New Lender</DialogTitle>
        <DialogContent>
          <Stack direction={"row"} columnGap={"16px"} mt={"16px"}>
            <TextField
              fullWidth
              label="Name"
              variant="filled"
              type="text"
              name="lender"
              value={lender.lender}
              onChange={(e) => setLender({ ...lender, lender: e.target.value })}
            />
            <TextField
              select
              label="Application Type"
              fullWidth
              value={lender.type}
              onChange={(e) => setLender({ ...lender, type: e.target.value })}
            >
              {Object.keys(APPLICATION_TYPES).map((key) => (
                <MenuItem key={key} value={APPLICATION_TYPES[key]}>
                  {APPLICATION_TYPES[key].charAt(0).toUpperCase() + APPLICATION_TYPES[key].slice(1)}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleAddLender} variant="contained" disabled={submitDisabled}>
            ADD NEW LENDER
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove Lender</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to remove the selected lender?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleRemoveLender} variant={"contained"} color="error">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Lenders;
