import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { debounce, get, isEqual, merge, orderBy, uniqWith } from "lodash";
import moment from "moment";
import ProductSelectorFilterBy from "./ProductSelectorFilterBy";

import ProductSelectorOrderBy from "./ProductSelectorOrderBy";
import Product from "./Product";
// import commercialProducts from "../../products/commercial";
// import consumerProducts from "../../products/consumer";
import calculateRepayments from "../../utils/calculateRepayments";
import { evaluateCriteria, calculateLoading, calculateFees, calculateExtras } from "../../utils/evaluateCriteria";
import getMonthlyRate from "../../utils/getMonthlyRate";
import {
  // consumerLenderList,
  // commercialLenderList,
  productTypes,
  productLenderFilterKey,
  // personalLoanLenderList,
  getCriteriaFilterList,
  APPLICATION_TYPES,
} from "../../constants";
import calculateComparisonRate from "../../utils/calculateComparisonRate";
import { userSelector } from "../../store/slices/userSlice";
import { getLocalStorage, setLocalStorage } from "../../utils/localstorage";
import {
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  userDetailsSelector,
  entitySelector,
  customersSelector,
  saveValue,
} from "../../store/slices/applicationFormSlice";
// import personalLoanProducts from "../../products/personal";
// import CustomSnackBar from "../customComponents/CustomSnackBar";
// import VerifyDialog from "../customComponents/VerifyDialog";
import { glassesAssetsSearchableList } from "../Asset";
import calculateEffectiveRate from "../../utils/calculateEffectiveRate";

const ProductSelector = React.memo(({ onShowRequired, lenderData }) => {
  const dispatch = useDispatch();

  const [fitFilterValue, setFitFilterValue] = useState("3 criteria off");

  const { open } = useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);
  const isCommercial = application.applicationType === "commercial";
  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  const user = useSelector(userSelector);
  const introducerDeclarationAgree = application?.introducerDeclarationAgree;
  const criteriaFilterListDefault = getCriteriaFilterList[application.applicationType];

  const [criteriaFilterValue, setCriteriaFilterValue] = useState([]);
  const [criteriaFilterList, setCriteriaFilterList] = useState(criteriaFilterListDefault);

  // const products = lenderData?.data?.getAllLendersData?.docs;
  const products = structuredClone(lenderData?.data?.getAllLendersData?.docs ?? []);
  const lenderList = structuredClone(lenderData?.data?.getAllLendersData?.docs ?? []).map((lender) => {
    const { lender: lenderName } = lender;
    return lenderName;
  });

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    typeOfSale,
    glassesMake,
    glassesModel,
    glassesVariant,
    make,
    model,
  } = useSelector(assetSelector);

  const {
    balloon = 0,
    term = 0,
    tradeIn = 0,
    payout = 0,
    deposit = 0,
    brokerage = 0,
    rateAdjustment = 0,
    purpose,
    originationFee = 0,
    loanAmount: loanAmountSaved,
  } = useSelector(loanDetailsSelector);

  const [productsMap, setProductsMap] = useState([]);
  // const [orderByParameters, setOrderByParameters] = useState([]);
  const [orderByParameters, setOrderByParameters] = useState([
    ["fit", "asc"],
    ["repayments", "asc"],
  ]);
  // const [orderByParametersList, setOrderByParametersList] = useState([]);
  const [orderByParametersList, setOrderByParametersList] = useState([
    ["fit", "repayments"],
    ["asc", "asc"],
  ]);

  const [filterByParameters, setFilterByParameters] = useState(lenderList);

  const [filterByProductType, setFilterByProductType] = useState(productTypes);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", handle.setDimension);
    return () => {
      window.removeEventListener("resize", handle.setDimension);
    };
  }, [screenSize]);

  // const lenderSettingsNamesList = user?.lenderSettings?.map((setting) => {
  //   if (setting?.lenderEmail) {
  //     return setting?.lender;
  //   }
  // });

  // const lenderSettingsNames = lenderSettingsNamesList?.filter(
  //   (setting) => setting !== undefined && setting !== null
  // );

  const handle = {
    setDimension: () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
      });
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => netAssetValue + brokerageAmount + fees,
    loanAmountConsumer: (fees, netAssetValue) => netAssetValue + fees,
    evaluateProducts: () => {
      const customerProfile = customers?.[0]?.customerProfile;
      const creditProfile = customers?.[0]?.creditProfile;
      const employer = customers?.[0]?.employers?.[0];
      const productList = products
        ?.flatMap((product) =>
          product?.productTiers?.map((tier) => {
            if (!tier) return null;

            const defaultBrokerage = calculateExtras(product.brokerage, {
              asset,
              loanDetails,
              entity: entity?.[0],
            })[0];

            const newLoanDetails = {
              loanAmount: assetValue - deposit,
              term,
              deposit,
              balloon,
            };

            const FEESLIST = calculateFees(product.fees, {
              asset,
              loanDetails: newLoanDetails,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            });

            const feesList = FEESLIST.map((fee) => {
              const feeValue = fee.value * 1;
              if (fee.calc === "percent") {
                const newFeeValue = (feeValue / 100) * newLoanDetails.loanAmount;
                return { ...fee, value: newFeeValue };
              }
              if (fee.name === "Origination fee")
                return {
                  ...fee,
                  value: originationFee < feeValue ? parseFloat(originationFee) : feeValue,
                };
              return fee;
            });

            const monthlyFees = feesList.find((fee) => fee.frequency === "monthly");
            const feesTotal = feesList.reduce((accum, fee) => {
              if (fee.percentage) {
                return (fee.value / 100) * newLoanDetails.loanAmount + accum;
              }
              if (fee.frequency === "monthly") return accum;
              if (fee.capitalised) return fee.value + accum;
              return accum;
            }, 0);

            const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
            const adjustedBrokerage = brokerage * 1;

            let finalBrokerage = adjustedBrokerage > defaultBrokerage?.max ? defaultBrokerage?.max : adjustedBrokerage;
            if (!isCommercial) {
              if (rateAdjustment === -2) finalBrokerage = 0;
              if (rateAdjustment === -1.5) finalBrokerage = defaultBrokerage?.value * 0.25;
              if (rateAdjustment === -1) finalBrokerage = defaultBrokerage?.value * 0.5;
              if (rateAdjustment === -0.5) finalBrokerage = defaultBrokerage?.value * 0.75;
              if (rateAdjustment === 0) finalBrokerage = defaultBrokerage?.value;
            }
            const brokerageAmount = netAssetValue * (finalBrokerage / 100);

            let loanAmount = isCommercial
              ? handle.loanAmountCommercial(feesTotal, netAssetValue, brokerageAmount)
              : handle.loanAmountConsumer(feesTotal, netAssetValue);
            if (application.applicationType === APPLICATION_TYPES.PERSONAL) loanAmount = loanAmountSaved;

            const loadingList = calculateLoading(product.loading, {
              asset,
              loanDetails: {
                ...loanDetails,
                ...{
                  loanAmount,
                  brokerageRate: adjustedBrokerage,
                  deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
                },
              },
              entity: entity?.[0],
              customerProfile,
            });

            const loadingTotal = loadingList.reduce(
              (accum, item) => (item.result === true ? item.value + accum : accum),
              0,
            );

            let RATE;
            if (Array.isArray(tier.rate)) {
              const scoreCriteria = tier.criteria.find((criteria) => criteria.attribute === "score");
              if (
                scoreCriteria &&
                creditProfile?.score >= scoreCriteria.value.min &&
                creditProfile?.score < scoreCriteria.value.max
              ) {
                const percentage =
                  ((creditProfile.score - scoreCriteria.value.max) /
                    (scoreCriteria.value.max - scoreCriteria.value.min)) *
                  100 +
                  100;
                RATE = (percentage / 100) * (tier.rate[1] - tier.rate[0]) + tier.rate[0];
              } else {
                RATE = tier.rate[0];
              }
            } else {
              RATE = tier.rate;
            }

            RATE = RATE + rateAdjustment + loadingTotal;
            const quoteDetails = handle.calculateQuote(loanAmount, balloon, term, RATE);
            const quoteWithNoBalloon = handle.calculateQuote(loanAmount, 0, term, RATE);

            const balloonAmount = (balloon / 100) * assetValue;
            const presentValue = balloonAmount / Math.pow(1 + RATE, term);
            const trueLoanAmount = loanAmount - presentValue;

            // const comparisonRateRaw = calculateComparisonRate(
            //   term,
            //   -quoteWithNoBalloon.repayments,
            //   trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0),
            //   0, // (balloon / 100) * assetValue
            //   isCommercial ? 1 : 0,
            //   RATE / 100,
            // );

            const comparisonRateRaw = calculateComparisonRate(
              term,
              -quoteWithNoBalloon.repayments,
              trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0),
              balloon, // (balloon / 100) * assetValue
              isCommercial ? 1 : 0,
              RATE / 100,
            );

            const effectiveRate = calculateEffectiveRate(
              term,
              quoteDetails.repayments,
              -(netAssetValue + feesTotal),
              balloonAmount,
              isCommercial === "Yes" ? 0 : 1,
              RATE / 100
            );


            // const comparisonRate = comparisonRateRaw * 12 * 100;
            let comparisonRate = comparisonRateRaw * 12 * 100;

            if (comparisonRate < RATE) {
              comparisonRate = RATE
            }
            // const compoundingYears = 12
            // const newRate = RATE / 100
            // const compoundRate = newRate/compoundingYears
            // const effectiveRate = (Math.pow(1 + compoundRate, compoundingYears) - 1) * 100

            const LOAN_DETAILS = {
              ...loanDetails,
              ...{
                loanAmount,
                repayments: quoteDetails.repayments,
                deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
              },
            };

            // ================================================================================================
            // For Logic Reference

            // const productRequireFields = get(product, "requiredFields");
            const productRequireFields = product.requiredFields;
            const requiredFields = { ...productRequireFields };

            //remove fields for angle
            if (application.applicationType !== APPLICATION_TYPES.PERSONAL) {
              if (!glassesAssetsSearchableList.includes(application.asset.assetType)) {
                let indexGlassMake = requiredFields?.asset?.indexOf("glassesMake");
                if (indexGlassMake !== -1) {
                  requiredFields?.asset?.splice(indexGlassMake, 1);
                  requiredFields?.asset?.push("make");
                }
                let indexGlassModel = requiredFields?.asset?.indexOf("glassesModel");
                if (indexGlassModel !== -1) {
                  requiredFields?.asset?.splice(indexGlassModel, 1);
                  requiredFields?.asset?.push("model");
                }
                let indexGlassVariant = requiredFields?.asset?.indexOf("glassesVariant");
                if (indexGlassVariant !== -1) {
                  requiredFields?.asset?.splice(indexGlassVariant, 1);
                }
              } else {
                let indexGlassMake = requiredFields?.asset?.indexOf("make");
                if (indexGlassMake !== -1) {
                  requiredFields?.asset?.splice(indexGlassMake, 1);
                  requiredFields?.asset?.push("glassesMake");
                }
                let indexGlassModel = requiredFields?.asset?.indexOf("model");
                if (indexGlassModel !== -1) {
                  requiredFields?.asset?.splice(indexGlassModel, 1);
                  requiredFields?.asset?.push("glassesModel");
                }
                let indexGlassVariant = requiredFields?.asset?.indexOf("variant");
                if (indexGlassVariant !== -1) {
                  requiredFields?.asset?.splice(indexGlassVariant, 1);
                  requiredFields?.asset?.push("glassesVariant");
                } else {
                  let indexGlassVarian = requiredFields?.asset?.indexOf("glassesVariant");
                  if (indexGlassVarian === -1) {
                    requiredFields?.asset?.push("glassesVariant");
                  }
                }
              }
            }

            const missingFields = {};
            if (
              (application.applicationType === APPLICATION_TYPES.CONSUMER ||
                application.applicationType === APPLICATION_TYPES.COMMERCIAL) &&
              requiredFields?.asset
            ) {
              missingFields.asset = requiredFields?.asset?.filter((field) => !get(application.asset, field));
              // if glasses key then change it to non glasses key
              if (missingFields.asset.includes("glassesModel")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesModel"), 1);
                missingFields?.asset.push("model");
              }
              if (missingFields.asset.includes("glassesMake")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesMake"), 1);
                missingFields?.asset.push("make");
              }
              if (missingFields.asset.includes("glassesVariant")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesVariant"), 1);
                if (product.lender !== "Pepper") {
                  missingFields?.asset.push("variant");
                }
              }
              // if (
              //   get(application.asset, "model") ||
              //   get(application.asset, "make")
              // ) {
              //   let fieldsToRemove = ["make", "model"];
              //   const updatedMissingFields = missingFields.asset.filter(
              //     (item) => !fieldsToRemove.includes(item)
              //   );

              //   missingFields.asset = updatedMissingFields;
              // }
            }
            //entity
            const entitiesMissingFieldError = [];
            if (application.applicationType === APPLICATION_TYPES.COMMERCIAL && requiredFields?.entity) {
              requiredFields?.entity?.filter((field) => {
                // !get(application?.entities?.[0], field)
                if (application?.entities && application?.entities?.length > 0) {
                  if (field.includes("addresses")) {
                    application?.entities.map((customerele) => {
                      const fieldVal = field.split("addresses[0].")[1];
                      const isAddressExit = customerele?.addresses.findIndex((address) => !address[fieldVal]);
                      if (isAddressExit >= 0) {
                        entitiesMissingFieldError.push(`entityAddress_${customerele._id}_${isAddressExit}`);
                        return `${field}_${customerele._id}`;
                      } else if (customerele?.addresses.length <= 0) {
                        entitiesMissingFieldError.push(`entityAddress_${customerele._id}_${0}`);
                        return `${field}_${customerele._id}`;
                      }
                    });
                  } else {
                    const isValExit = application?.entities.findIndex((customer) => !customer[field]);

                    if (isValExit >= 0) {
                      entitiesMissingFieldError.push(`${field}_${application?.entities[isValExit]._id}`);
                      return `${field}_${application?.entities[isValExit]._id}`;
                    }
                  }
                } else {
                  entitiesMissingFieldError.push(`${field}_${application?.entities}_0`);
                }
              });
            }
            missingFields.entity = entitiesMissingFieldError;

            //remove monthAt and yearsAt if city is not null validation
            if (requiredFields?.customer) {
              if (!get(application?.customers?.[0], "addresses[0].city")) {
                const filterField = ["addresses[0].monthsAt", "addresses[0].yearsAt"];
                requiredFields.customer = requiredFields?.customer.filter((field) => !filterField.includes(field));
              }
            }

            //validation for customer
            const customerMissingFieldError = [];
            // valaidation of remove email, number, numberOfDependants
            requiredFields?.customer?.filter((field) => {
              if (application?.customers && application?.customers?.length > 0) {
                // const customerMissingFieldObj = {index: index};
                if (field === "emails?.slice(-1)[0].address") {
                  const isEmailExit = application?.customers.findIndex(
                    (customer) => !customer.emails?.slice(-1)[0]?.address,
                  );
                  if (isEmailExit >= 0)
                    customerMissingFieldError.push(`address_${application?.customers[isEmailExit]._id}`);
                  return `${field}`;
                  // return isValExit >= 0 ? true :false;
                }

                if (field === "phoneNumbers?.slice(-1)[0].number") {
                  // return !application?.customers?.[0]?.phoneNumbers?.slice(-1)[0]?.number;
                  const isNumExit = application?.customers.findIndex(
                    (customer) => !customer.phoneNumbers?.slice(-1)[0]?.number,
                  );
                  if (isNumExit >= 0) customerMissingFieldError.push(`number_${application?.customers[isNumExit]._id}`);
                  return `${field}`;
                  // return isValExit >= 0 ? {...customerMissingFieldObj,number: `customer[${isAddress}].phoneNumbers?.slice(-1)[0]?.number`} :false;
                }

                if (field.includes("customerProfile")) {
                  const keyVal = field.split(".");
                  const isNODExit = application?.customers.findIndex(
                    (customer) => customer[keyVal[0]] && !customer[keyVal[0]][keyVal[1]],
                  );
                  if (isNODExit >= 0 && !field.includes("numberOfDependants"))
                    customerMissingFieldError.push(`${field}_${application?.customers[isNODExit]._id}`);
                  return `${field}`;
                }

                // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
                //   ? false
                //   : true;

                application?.customers.map((customerele) => {
                  if (field.includes("addresses")) {
                    if (customerele?.addresses.length > 0) {
                      let months = 0;
                      customerele?.addresses.map((address) => {
                        months = months + address.yearsAt * 12;
                        months = months + address.monthsAt;
                      });
                      if (months < 36) {
                        customerMissingFieldError.push(
                          `fullAddress_customer_${customerele._id}_${customerele?.addresses.length}`,
                        );
                      }
                    }

                    const fieldVal = field.split("addresses[0].")[1];
                    const isAddressExit = customerele?.addresses.findIndex((address) => !address[fieldVal]);
                    if (isAddressExit >= 0) {
                      customerMissingFieldError.push(`${fieldVal}_customer_${customerele._id}_${isAddressExit}`);
                      return `${field}_${customerele._id}`;
                    } else if (customerele?.addresses.length <= 0) {
                      customerMissingFieldError.push(`${fieldVal}_customer_${customerele._id}_${0}`);
                      return `${field}_${customerele._id}`;
                    }
                    if (fieldVal.includes("fullAddress") && customerele?.addresses.length > 0) {
                      const isAddressAusExit = customerele?.addresses.findIndex((address) => address.country?.toLowerCase() !== 'australia');
                      if (isAddressAusExit >= 0) {
                        customerMissingFieldError.push(`${fieldVal}_customer_${customerele._id}_${isAddressAusExit}_customError`);
                        return `${field}_${customerele._id}`;
                      }
                    }
                  }
                  if (field.includes("customerAssets")) {
                    const fieldVal = field.split("customerAssets.[0].")[1];
                    const isAssetsExit = customerele?.customerAssets.findIndex((address) => !address[fieldVal]);
                    if (isAssetsExit >= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${isAssetsExit}`);
                      return `${field}`;
                    } else if (customerele?.customerAssets.length <= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${0}`);
                      return `${field}_${customerele._id}`;
                    }
                  }
                  if (field.includes("liabilities")) {
                    const fieldVal = field.split("liabilities.[0].")[1];
                    const isAssetsExit = customerele?.liabilities.findIndex((address) => !address[fieldVal]);
                    if (isAssetsExit >= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${isAssetsExit}`);
                      return `${field}`;
                    } else if (customerele?.liabilities.length <= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${0}`);
                      return `${field}_${customerele._id}`;
                    }
                  }
                  if (field.includes("income")) {
                    const fieldVal = field.split("income.[0].")[1];
                    const isAssetsExit = customerele?.income.findIndex((address) => !address[fieldVal]);
                    if (isAssetsExit >= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${isAssetsExit}`);
                      return `${field}`;
                    } else if (customerele?.income.length <= 0) {
                      customerMissingFieldError.push(`${field}_${customerele._id}_${0}`);
                      return `${field}_${customerele._id}`;
                    }
                  }
                  if (field.includes("expenses")) {
                    const fieldVal = field.split("expenses.[0].")[1];
                    const isAssetsExit = customerele?.expenses.findIndex((address) => !address[fieldVal]);
                    if (isAssetsExit >= 0) {
                      customerMissingFieldError.push(`${field}_expenses_${customerele._id}_${isAssetsExit}`);
                      return `${field}`;
                    } else if (customerele?.expenses.length <= 0) {
                      customerMissingFieldError.push(`${field}_expenses_${customerele._id}_${0}`);
                      return `${field}_${customerele._id}`;
                    }
                  }
                  if (field.includes("employers")) {
                    const fieldVal = field.split("employers.[0].")[1];
                    if (fieldVal && fieldVal.includes("address")) {
                      const keyVal = fieldVal.split(".");
                      const isEmployerAddExit = customerele?.employers.findIndex(
                        (address) => !address.address || !address[keyVal[0]][keyVal[1]],
                      );
                      if (isEmployerAddExit >= 0) {
                        customerMissingFieldError.push(`${field}_employer_${customerele._id}_${isEmployerAddExit}`);
                        return `${field}`;
                      }
                      if (keyVal.includes("fullAddress") && customerele?.employers.length > 0) {
                        const isEmployerAddAusExit = customerele?.employers.findIndex(
                          (address) => !address.address || address.address.country?.toLowerCase() !== 'australia'
                        );
                        if (isEmployerAddAusExit >= 0) {
                          customerMissingFieldError.push(`${field}_employer_${customerele._id}_${isEmployerAddAusExit}_customError`);
                          return `${field}`;
                        }
                      }
                    } else {
                      const isEmployerExit = customerele?.employers.findIndex((address) => !address[fieldVal]);
                      if (isEmployerExit >= 0) {
                        customerMissingFieldError.push(`${field}_${customerele._id}_${isEmployerExit}`);
                        return `${field}`;
                      } else if (customerele?.employers.length <= 0) {
                        customerMissingFieldError.push(`${field}_${customerele._id}_${0}`);
                        return `${field}_${customerele._id}`;
                      } else if (customerele?.employers.length > 0 && fieldVal.includes("timeOfEmployment")) {
                        const isEmployerTimeExit = customerele?.employers.findIndex((address) => +address.timeOfEmployment < 24);
                        if (isEmployerTimeExit >= 0) {
                          customerMissingFieldError.push(`${field}_${customerele._id}_${isEmployerTimeExit}_customError`);
                          return `${field}`;
                        }
                      }
                    }
                  }
                });

                // return isValExit >= 0 ? {...customerMissingFieldObj,yearsAt: `customer[${isAddress}].addresses[0].yearsAt`} :false;
                // const isAddressMonth = application?.customers.findIndex((customer)=>!customer.addresses[0].monthsAt);
                // if(isAddressMonth >= 0) return `${field}_${application?.customers[isAddressMonth]._id}`
                // return isAddressMonth >= 0 ? {...customerMissingFieldObj,monthsAt: `customer[${isAddress}].addresses[0].monthsAt`} :false;

                // if (field === `addresses[0].monthsAt` || field === `addresses[0].yearsAt`) {
                //   // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
                //   //   ? false
                //   //   : true;
                //   const isYearExit = application?.customers.findIndex((customer)=>!customer.addresses[0].yearsAt);
                //   if(isYearExit >= 0) return `${field}_${application?.customers[isYearExit]._id}`
                //   // return isValExit >= 0 ? {...customerMissingFieldObj,yearsAt: `customer[${isAddress}].addresses[0].yearsAt`} :false;
                //   const isAddressMonth = application?.customers.findIndex((customer)=>!customer.addresses[0].monthsAt);
                //   if(isAddressMonth >= 0) return `${field}_${application?.customers[isAddressMonth]._id}`
                //   // return isAddressMonth >= 0 ? {...customerMissingFieldObj,monthsAt: `customer[${isAddress}].addresses[0].monthsAt`} :false;
                // }

                // if (field === `dateOfBirth` || field === `dateOfBirth`) {
                //   const date = get(application?.customers?.[0], field);
                //   const diff = moment().diff(moment(date).format('DD-MM-YYYY'), "months");
                //   return diff > 18 ? false : true;
                // }

                // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
                //   ? false
                //   : true;
                if (
                  !field.includes("addresses") &&
                  !field.includes("customerAssets") &&
                  !field.includes("liabilities") &&
                  !field.includes("income") &&
                  !field.includes("expenses") &&
                  !field.includes("employers") &&
                  field !== "emails?.slice(-1)[0].address" &&
                  field !== "phoneNumbers?.slice(-1)[0].number" &&
                  field !== "customerProfile.numberOfDependants" &&
                  !field.includes("customerProfile")
                ) {
                  const isValExit = application?.customers.findIndex((customer) => !customer[field]);

                  if (isValExit >= 0) {
                    customerMissingFieldError.push(`${field}_${application?.customers[isValExit]._id}`);
                    return `${field}`;
                  } else if (field === 'dateOfBirth') {
                    const isAgeExit = application?.customers.findIndex((customer) => moment().diff(moment(customer.dateOfBirth).format("DD-MM-YYYY"), 'year') < 18);
                    if (isAgeExit >= 0) {
                      customerMissingFieldError.push(`${field}_${application?.customers[isAgeExit]._id}_customError`);
                      return `${field}`;
                    }
                  }
                }
                // return !get(application?.customers?.[0], field);
              }
            });

            // const customerMissingFieldError = [];
            // application?.customers?.map((customer) => {
            //   const customerMissingFieldObj = { customerId: customer._id };
            //   requiredFields?.customer?.map((field) => {
            //     if (field === "emails?.slice(-1)[0].address") {
            //       const isAddExit = application?.customers.findIndex(
            //         (customer) => !customer.emails?.slice(-1)[0]?.address,
            //       );
            //       // return !application?.customers?.[0]?.emails?.slice(-1)[0]?.address;
            //       // return isValExit >= 0 ? true :false;
            //       if (isAddExit >= 0) customerMissingFieldObj.address = `emails?.slice(-1)[0]?.address`;
            //       // return { ...customerMissingFieldObj, address: `emails?.slice(-1)[0]?.address` };
            //       return;
            //     }

            //     if (field === "phoneNumbers?.slice(-1)[0].number") {
            //       // return !application?.customers?.[0]?.phoneNumbers?.slice(-1)[0]?.number;
            //       const isNumExit = application?.customers.findIndex(
            //         (customer) => !customer.phoneNumbers?.slice(-1)[0]?.number,
            //       );
            //       // if(isNumExit >= 0) return `${field}_${application?.customers[isNumExit]._id}`
            //       // return isValExireturn isValExit >= 0 ? {...customerMissingFieldObj,number: `customer[${isAddress}].phoneNumbers?.slice(-1)[0]?.number`} :false;t >= 0 ? {...customerMissingFieldObj,number: `customer[${isAddress}].phoneNumbers?.slice(-1)[0]?.number`} :false;
            //       if (isNumExit >= 0)
            //         // return { ...customerMissingFieldObj, number: `phoneNumbers?.slice(-1)[0]?.number` };
            //         customerMissingFieldObj.number = `phoneNumbers?.slice(-1)[0]?.number`;
            //       return;
            //     }

            //     if (field === "customerProfile.numberOfDependants") {
            //       // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
            //       //   ? false
            //       //   : true;
            //       const isNODExit = application?.customers.findIndex(
            //         (customer) => !customer.customerProfile.numberOfDependants,
            //       );
            //       // if(isNODExit >= 0) return `${field}_${application?.customers[isNODExit]._id}`
            //       if (isNODExit >= 0) customerMissingFieldObj.numberOfDependants = `customerProfile.numberOfDependants`;
            //       return;
            //       // return { ...customerMissingFieldObj, numberOfDependants: `customerProfile.numberOfDependants` };
            //       // return isValExit >= 0 ? {...customerMissingFieldObj,numberOfDependants: `customer[${isAddress}].customerProfile.numberOfDependants`} :false;
            //     }

            //     // if (field === `addresses[0].monthsAt` || field === `addresses[0].yearsAt`) {
            //     //   // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
            //     //   //   ? false
            //     //   //   : true;
            //     //   const isYearExit = application?.customers.findIndex((customer) => !customer.addresses[0].yearsAt);
            //     //   // if (isYearExit >= 0) return `${field}_${application?.customers[isYearExit]._id}`;
            //     //   if (isYearExit >= 0) return { ...customerMissingFieldObj, yearsAt: `addresses[0].yearsAt` };
            //     //   // return isValExit >= 0 ? {...customerMissingFieldObj,yearsAt: `customer[${isAddress}].addresses[0].yearsAt`} :false;
            //     //   const isAddressMonth = application?.customers.findIndex(
            //     //     (customer) => !customer.addresses[0].monthsAt,
            //     //   );
            //     //   if (isAddressMonth >= 0)
            //     //     return { ...customerMissingFieldObj, monthsAt: `customer[${isAddress}].addresses[0].monthsAt` };
            //     //   // return isAddressMonth >= 0 ? {...customerMissingFieldObj,monthsAt: `customer[${isAddress}].addresses[0].monthsAt`} :false;
            //     // }

            //     // if (field === `dateOfBirth` || field === `dateOfBirth`) {
            //     //   const date = get(application?.customers?.[0], field);
            //     //   const diff = moment().diff(moment(date).format('DD-MM-YYYY'), "months");
            //     //   return diff > 18 ? false : true;
            //     // }

            //     // return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
            //     //   ? false
            //     //   : true;
            //     const isValExit = application?.customers.findIndex((customer) => !customer[field]);
            //     if (isValExit >= 0) customerMissingFieldObj[field] = field;
            //     return; //return { ...customerMissingFieldObj, [field]: field };
            //   });
            //   customerMissingFieldError.push(customerMissingFieldObj);
            // });
            missingFields.customer = customerMissingFieldError;

            // const addressesData = application?.customers?.[0]?.addresses;
            // if (addressesData) {
            //   const index = addressesData?.length - 1;

            //   missingFields.customer = missingFields?.customer?.filter((field) => {
            //     if (field === `addresses[${index}].monthsAt` || field === `addresses[${index}].yearsAt`) {
            //       return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
            //         ? false
            //         : true;
            //     }
            //     return !get(application?.customers?.[0], field);
            //   });
            // }
            // if (addressesData && addressesData.length > 1) {
            //   const lastAddAddress = addressesData[addressesData.length - 1];
            //   const i = addressesData.length - 1;
            //   if (lastAddAddress?.city !== undefined && lastAddAddress?.yearsAt === undefined) {
            //     missingFields.customer.push(`addresses[${i}].yearsAt`);
            //   }
            //   if (lastAddAddress?.city !== undefined && lastAddAddress?.monthsAt === undefined) {
            //     missingFields.customer.push(`addresses[${i}].monthsAt`);
            //   }
            // }
            // if (get(application?.customers?.[0], "addresses[0].yearsAt") < 1) {
            //   const lastAddress = addressesData[addressesData.length - 1];
            //   if (lastAddress?.yearsAt !== undefined && lastAddress?.monthsAt !== undefined) {
            //     const isYearExist = addressesData.filter((ele) => ele?.yearsAt >= 1);
            //     if (isYearExist && isYearExist.length <= 0) {
            //       const monthTotal = addressesData
            //         ?.map((ele) => ele?.monthsAt)
            //         .reduce((acc, curr) => (curr ? acc + curr : acc), 0);
            //       if (monthTotal < 12) {
            //         const i = addressesData.length;
            //         missingFields.customer.push(
            //           `addresses[${i}].city`,
            //           `addresses[${i}].state`,
            //           `addresses[${i}].postCode`,
            //         );
            //       }
            //     }
            //   }
            // }
            // added age validation on dynamic customers
            // if (application?.customers?.length > 0) {
            //   const index = application?.customers?.length - 1;

            //   //age
            //   missingFields.customer = missingFields?.customer?.filter((field) => {
            //     if (field === `addresses[${index}].monthsAt` || field === `addresses[${index}].yearsAt`) {
            //       return get(application?.customers?.[0], field) || get(application?.customers?.[0], field) === 0
            //         ? false
            //         : true;
            //     }
            //     return !get(application?.customers?.[0], field);
            //   });
            // }

            if (application.applicationType !== APPLICATION_TYPES.COMMERCIAL && requiredFields?.agreement) {
              missingFields.agreement = requiredFields?.agreement?.filter(
                (field) => !application?.introducerDeclarationAgree,
              );
            }
            // if (requiredFields?.loanDetails) {
            //   missingFields.loanDetails = requiredFields?.loanDetails?.filter(
            //     (field) => !get(loanDetails?.rate, field)
            //   );
            // }

            // if (!value || value === undefined) return;

            // const findFirstName = application.customers.filter((el) => !el.firstName);
            // // console.log("findFirstName", findFirstName);
            // let errorMsg = error;
            // console.log("application?.entities?.length", application?.entities?.length);
            // if (
            //   application?.entities?.length >= 0 &&
            //   !error.includes("entities[i].abn")
            // ) {
            //   console.log("===");
            //   errorMsg.push("entities[i].abn");
            //   setError(errorMsg);
            //   return;
            // }
            // if (findFirstName.length <= 0 && !error.includes("customer[i].firstName")) {
            //   errorMsg.push("customer[i].firstName");
            //   setError(errorMsg);
            //   return;
            // }

            // Map product details with application data to check required field data are available or not
            // console.log("application", application);
            let requiredFieldsLength,
              missingFieldsCustomer,
              missingFieldsEntity,
              missingFieldsLength,
              missingFieldsAgreement;
            if (isCommercial) {
              requiredFieldsLength = product?.requiredFields?.asset?.concat(
                product?.requiredFields?.entity,
                product?.requiredFields?.customer,
                // product?.requiredFields?.loanDetails
              );

              missingFieldsCustomer = missingFields?.customer?.flat();
              missingFieldsEntity = missingFields?.entity?.flat();
              missingFieldsLength = missingFields?.asset?.concat(missingFieldsCustomer, missingFieldsEntity);
            } else if (application.applicationType === APPLICATION_TYPES.CONSUMER) {
              requiredFieldsLength = product?.requiredFields?.asset?.concat(
                product?.requiredFields?.customer,
                product?.requiredFields?.agreement,
                // product?.requiredFields?.loanDetails
              );
              missingFieldsCustomer = missingFields?.customer?.flat();
              missingFieldsAgreement = missingFields?.agreement?.flat();
              missingFieldsLength = missingFields?.asset?.concat(missingFieldsCustomer, missingFieldsAgreement);
            } else if (application.applicationType === APPLICATION_TYPES.PERSONAL) {
              requiredFieldsLength = product?.requiredFields?.customer?.concat(product?.requiredFields?.agreement);
              missingFieldsCustomer = missingFields?.customer?.flat();
              missingFieldsAgreement = missingFields?.agreement?.flat();
              missingFieldsLength = missingFields?.customer?.concat(missingFieldsAgreement);
            } else {
            }

            const uniqueSet = new Set();
            const uniqueMissingFields = [];

            missingFieldsLength?.forEach(item => {
              const corePart = item.replace(/(_[a-f0-9]{24})/g, '').replace(/(_\d+)/g, '');
              if (!uniqueSet.has(corePart)) {
                uniqueSet.add(corePart);
                uniqueMissingFields.push(item);
              }
            });

            const requiredFieldsData = {
              totalLength:
                product.lender === "Pepper"
                  ? Number(requiredFieldsLength?.length) - 1
                  : requiredFieldsLength?.length || 0,
              availableDataLength:
                product.lender === "Pepper"
                  ? Number(requiredFieldsLength?.length - uniqueMissingFields?.length) - 1
                  : requiredFieldsLength?.length - uniqueMissingFields?.length || 0,
              remainingDataFields: missingFields, // put all the validation message in this array to show on hover
            };

            // ================================================================================================

            const productDetails = {
              lender: product.lender,
              productName: tier.name,
              name: tier.name,
              logo: product.logoURL,
              lenderCredentials: product?.lenderCredentials,
              requiredFieldsData: requiredFieldsData,
            };

            const criteriaResults = evaluateCriteria(tier.criteria, {
              asset,
              loanDetails: LOAN_DETAILS,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            }).filter((result) => {
              if (!result.criteria) return result;
              if (result.criteria.every((criteria) => criteria.result)) return result;
              if (
                !result.criteria.some((criteria) => criteria.result) &&
                result.criteria.some((item) => result.primary === item.attribute)
              ) {
                return result.criteria.find((item) => result.primary === item.attribute);
              }
              return result;
            });

            const overallResult = criteriaResults.reduce((count, item) => (item?.result ? count + 1 : count), 0);

            function tierInfo() {
              return tier?.info?.[0] ? tier?.info : [];
            }

            const financeAmount =
              application?.applicationType === APPLICATION_TYPES.PERSONAL ? loanDetails?.loanAmount : 1 * netAssetValue;

            return merge(productDetails, {
              effectiveRate: effectiveRate,
              comparisonRate: comparisonRate,
              financeAmount: financeAmount,
              loanAmount: quoteDetails.repayments * term,
              repayments: (quoteDetails.repayments || 0) + (monthlyFees?.value || 0),
              brokerage: finalBrokerage,
              brokerageAmount: brokerageAmount,
              monthlyFees,
              fees: feesList,
              feesTotal,
              loading: { loadingList, loadingTotal },
              criteriaResults,
              fit: criteriaResults.length - overallResult,
              criteria: `${overallResult}/${criteriaResults.length}`,
              qualification: criteriaResults.length - overallResult,
              productType: tier.productType,
              labels: [tier.productType],
              // rate: (RATE + loadingTotal) * 1,
              rate: RATE,
              info: [...tierInfo(), ...(product?.info || [])],
              date: product.date,
              isCommercial,
              actions: product.actions,
            });
          }),
        )
        .filter(Boolean);

      const list = productList
        .flat()
        // .filter((product) => product.repayments > 0)
        .filter((product) => filterByParameters?.includes(product?.lender))
        // .filter((product) =>
        //   lenderSettingsNames
        //     ? lenderSettingsNames?.includes(product?.lender)
        //     : product
        // )
        .filter((product) => filterByProductType?.includes(product?.productType))
        .filter((product) => handle.filterByCriteriaFn(product, criteriaFilterValue));

      const groupedList = list.reduce((acc, product) => {
        acc[product.lender] = acc[product.lender] || [];
        acc[product.lender].push(product);
        return acc;
      }, {});

      const prioritisedList = Object.values(groupedList).map(
        (products) =>
          products.sort((a, b) => {
            if (a.fit !== b.fit) return a.fit - b.fit;
            return a.rate - b.rate;
          })[0],
      );

      setProductsMap(orderBy(prioritisedList, ...orderByParametersList));
    },
    filterByCriteriaFn: (product, criteriaFilterValue) => {
      const criteriaResults = product.criteriaResults.map((result) => {
        if (result.result || result.result === undefined) return result.attribute;
        return result.result;
      });
      const matches = Array.isArray(criteriaFilterValue)
        ? criteriaFilterValue.map((criteria) => criteriaResults.includes(criteria))
        : [criteriaResults.includes(criteriaFilterValue)];

      return matches.every((i) => i);
    },
    calculateQuote: (loanAmount, balloon, term, rate) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        (balloon / 100) * assetValue,
        isCommercial ? 1 : 0,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    orderByFn: (event) => {
      // const targetValue = event.target.value;
      const targetValue = event;
      const params = targetValue.map((i) => i[0]);
      // const order = targetValue.map((i) => i[1]);

      if (user.status === "new" && params[0] === "rate") {
        // setOrderByParameters([params[0]]);
        // setOrderByParametersList([params, order]);
        handle.setFilterToLocalStore("orderBy", [params[0]]);
        return dispatch(saveValue({ walkthroughIndex: 3 }));
      }

      // setOrderByParameters(targetValue);
      // setOrderByParametersList([params, order]);
      handle.setFilterToLocalStore("orderBy", targetValue);
    },
    filterByFn: (event) => {
      // setFilterByParameters(event.target.value);
      handle.setFilterToLocalStore("filterBy", event.target.value);
    },
    filterByProductTypeFn: (event) => {
      // setFilterByProductType(event.target.value);
      handle.setFilterToLocalStore("filterByProductType", event.target.value);
    },
    filterByFitFn(event) {
      // setFitFilterValue(event.target.value);
      handle.setFilterToLocalStore("filterByFit", event.target.value);
    },
    filterByCriteria: (event) => {
      if (user?.status === "new") {
        if (event.target.value === "assetType") {
          // setCriteriaFilterValue([event.target.value]);
          handle.setFilterToLocalStore("filterByCriteria", [event.target.value]);
          if (user?.walkthroughIndex < 6) {
            dispatch(saveValue({ walkthroughIndex: 6 }));
          }
          return;
        }
      }
      // setCriteriaFilterValue(event.target.value);
      handle.setFilterToLocalStore("filterByCriteria", event.target.value);
    },
    setFilterToLocalStore: (key, filteredValue) => {
      const getProductFilter = getLocalStorage(productLenderFilterKey);

      if (!getProductFilter) {
        setCriteriaFilterList(productLenderFilterKey);
        setLocalStorage(productLenderFilterKey, null);
      }

      let filteredData = {
        orderBy: orderByParameters,
        orderByList: orderByParametersList,
        filterBy: filterByParameters,
        filterByProductType: filterByProductType,
        filterByFit: fitFilterValue,
        filterByCriteria: criteriaFilterValue,
      };

      switch (key) {
        case "orderBy":
          const uniqOrderBy = uniqWith(filteredValue, isEqual);

          setOrderByParameters(uniqOrderBy);
          filteredData.orderBy = uniqOrderBy;

          // Update setOrderByList
          // ======================================================================
          const targetValue = filteredValue;
          const params = targetValue.map((i) => i[0]);
          const order = targetValue.map((i) => i[1]);

          if (user.status === "new" && params[0] === "rate") {
            setOrderByParametersList([params, order]);
            filteredData.orderByList = [params, order];
            return dispatch(saveValue({ walkthroughIndex: 3 }));
          }
          setOrderByParametersList([params, order]);
          filteredData.orderByList = [params, order];
          // ======================================================================
          break;
        case "orderByList":
          setOrderByParametersList(filteredValue);
          filteredData.orderByList = filteredValue;
          break;
        case "filterBy":
          setFilterByParameters(filteredValue);
          filteredData.filterBy = filteredValue;
          break;
        case "filterByProductType":
          setFilterByProductType(filteredValue);
          filteredData.filterByProductType = filteredValue;
          break;
        case "filterByFit":
          setFitFilterValue(filteredValue);
          filteredData.filterByFit = filteredValue;
          break;
        case "filterByCriteria":
          setCriteriaFilterValue(filteredValue);
          filteredData.filterByCriteria = filteredValue;
          break;

        default:
          break;
      }

      const data = {
        ...getProductFilter,
        [application._id]: filteredData,
      };

      setLocalStorage(productLenderFilterKey, data);
    },
  };

  useEffect(() => {
    handle.evaluateProducts();
  }, [
    customers,
    introducerDeclarationAgree,
    brokerage,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    orderByParameters,
    filterByParameters,
    filterByProductType,
    fitFilterValue,
    typeOfSale,
    criteriaFilterValue,
    assetValue,
    deposit,
    tradeIn,
    payout,
    glassesMake,
    glassesModel,
    glassesVariant,
    make,
    model,
    asset,
    loanAmountSaved,
    originationFee,
    purpose,
    rateAdjustment,
  ]);

  const debounced = debounce(handle.evaluateProducts, 500);

  useEffect(() => {
    debounced();
  }, [assetValue]);

  useEffect(() => {
    // Update Filter list
    const getProductFilter = getLocalStorage(productLenderFilterKey);

    if (getProductFilter) {
      const isFilterData = getProductFilter[application._id];
      if (isFilterData) {
        const { orderBy, orderByList, filterBy, filterByProductType, filterByFit, filterByCriteria } =
          getProductFilter[application._id];

        if (orderByList?.[0]?.length <= 0) {
          setOrderByParameters([
            ["fit", "asc"],
            ["repayments", "asc"],
          ]);

          setOrderByParametersList([
            ["fit", "repayments"],
            ["asc", "asc"],
          ]);
        } else {
          setOrderByParameters(orderBy);
          setOrderByParametersList(orderByList);
        }

        // setOrderByParameters(orderBy);
        // setOrderByParametersList(orderByList);
        setFilterByParameters(filterBy);
        setFilterByProductType(filterByProductType);
        setFitFilterValue(filterByFit);
        setCriteriaFilterValue(filterByCriteria);
        return;
      }
    }
  }, [application]);

  const productsList = useMemo(
    () =>
      productsMap
        ?.slice(0, 50)
        .map((productDetails, i) => (
          <Product
            key={productDetails.lender + productDetails.productType + productDetails.loanAmount + productDetails.rate}
            productDetails={productDetails}
            user={user}
            application={application}
            screenSize={screenSize}
            onShowRequired={onShowRequired}
          />
        )),
    [productsMap],
  );

  return (
    <React.Fragment>
      <Stack
        direction="row"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        {open && (
          <Stack direction="row">
            <ProductSelectorFilterBy
              list={lenderList}
              handleFilterBy={handle.filterByFn}
              value={filterByParameters}
              title="Lenders"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={productTypes}
              handleFilterBy={handle.filterByProductTypeFn}
              value={filterByProductType}
              title="Product type"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={criteriaFilterList}
              handleFilterBy={handle.filterByCriteria}
              value={criteriaFilterValue}
              title="Must match"
              multiple
              screenSize={screenSize}
            />
          </Stack>
        )}
        <ProductSelectorOrderBy handleOrderBy={handle.orderByFn} value={orderByParameters} screenSize={screenSize} />
      </Stack>

      <Stack>{productsList}</Stack>

      {!productsMap?.[0] && (
        <Stack justifyContent="center" alignItems="center" style={{ height: "200px" }}>
          <Typography>No matching products</Typography>
        </Stack>
      )}
    </React.Fragment>
  );
});

// function setProducts(applicationType) {
//   if (applicationType === "consumer") return consumerProducts;
//   if (applicationType === "personal") return personalLoanProducts;

//   return commercialProducts;
// }

// function setLenders(applicationType) {
//   if (applicationType === "consumer") return consumerLenderList;
//   if (applicationType === "personal") return personalLoanLenderList;

//   return commercialLenderList;
// }

export default ProductSelector;
