import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import _ from "lodash";
import CRITERIA from "../../../../utils/criteria";
import { criteriaFunctions, criteriaObjects } from "../../../../constants";
import CriteriaInput from "../CriteriaInput";
import {
  updateCurrentlyEditingFee,
  updateCurrentLenderFees,
  updateCurrentLenderLoadings,
  updateCurrentLenderBrokerages,
  lenderSelector,
  setEditLenderPayload,
} from "../../../../store/slices/lenderSlice";

const CriteriaContent = ({
  criteria,
  index,
  feeIndex,
  loadingIndex,
  brokerageIndex,
  lender,
  // lenderDetail,
  // editLender,
  setFee,
  setLoading,
  setBrokerage,
  keyString,
  editLenderLoading,
}) => {
  // const lenderId = lender?._id;
  const [criteriaType, setCriteriaType] = useState(null);
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [localCriteria, setLocalCriteria] = useState(criteria);

  const dispatch = useDispatch();
  const {
    currentlyEditingFee,
    currentLenderFees,
    currentLenderLoadings,
    currentLenderBrokerages,
    lender: lenderState,
  } = useSelector(lenderSelector);

  const lenderId = lenderState?._id;

  useEffect(() => {
    setLocalCriteria(criteria);
  }, [criteria]);

  useEffect(() => {
    const { _id, updatedAt, createdAt, productTier, __v, ...rest } = localCriteria;
    let criteriaKey = criteriaObjects.find((key) => {
      // Weird bug, the CRITERIA[key] contains an _id when it shouldn't
      const { _id, ...toCompare } = { ...CRITERIA[key] };
      return _.isEqual(toCompare, rest);
    });
    if (!criteriaKey) {
      if (
        typeof localCriteria?.value === "object" &&
        localCriteria?.value instanceof Object &&
        !Array.isArray(localCriteria?.value)
      ) {
        const criteriaFunctionKeys = criteriaFunctions.filter((key) => CRITERIA[key].length > 1);
        criteriaKey = criteriaFunctionKeys.find((key) =>
          _.isEqual(CRITERIA[key](localCriteria?.value?.min, localCriteria?.value?.max), rest),
        );
        if (!criteriaKey) {
          // for date values
          const { attribute, condition, object } = rest;
          criteriaKey = criteriaFunctionKeys.find((key) => {
            const {
              attribute: criteriaAttribute,
              condition: criteriaCondition,
              object: criteriaObject,
            } = CRITERIA[key](criteria?.value?.min, criteria?.value?.max);
            return _.isEqual(
              { attribute: criteriaAttribute, condition: criteriaCondition, object: criteriaObject },
              { attribute, condition, object },
            );
          });
        }
      } else {
        const criteriaFunctionKeys = criteriaFunctions.filter((key) => CRITERIA[key].length === 1);
        criteriaKey = criteriaFunctionKeys.find((key) => _.isEqual(CRITERIA[key](criteria?.value), rest));
        if (!criteriaKey) {
          const { attribute, condition, object } = rest;
          criteriaKey = criteriaFunctionKeys.find((key) => {
            const {
              attribute: criteriaAttribute,
              condition: criteriaCondition,
              object: criteriaObject,
            } = CRITERIA[key](criteria?.value);
            return _.isEqual(
              { attribute: criteriaAttribute, condition: criteriaCondition, object: criteriaObject },
              { attribute, condition, object },
            );
          });
        }
      }
    }
    setCriteriaType(criteriaKey);
  }, [localCriteria]);

  const updateCriteriaInStore = (updatedCriteria) => {
    const criteriaArr = currentlyEditingFee.criteria.map((c, i) => {
      if (i === index) return updatedCriteria;
      return c;
    });

    const { _id, ...rest } = currentlyEditingFee;
    const updatedLenderDetail = {
      ...rest,
      criteria: criteriaArr,
    };

    let updatedArray;
    switch (keyString) {
      case "fees":
        updatedArray = currentLenderFees.map((info, i) => {
          const { _id: infoId, ...rest } = info;
          return i === feeIndex ? updatedLenderDetail : rest;
        });
        dispatch(updateCurrentLenderFees(updatedArray));
        break;
      case "loading":
        updatedArray = currentLenderLoadings.map((info, i) => {
          const { _id: infoId, ...rest } = info;
          return i === loadingIndex ? updatedLenderDetail : rest;
        });
        dispatch(updateCurrentLenderLoadings(updatedArray));
        break;
      case "brokerage":
        updatedArray = currentLenderBrokerages.map((info, i) => {
          const { _id: infoId, ...rest } = info;
          return i === brokerageIndex ? updatedLenderDetail : rest;
        });
        dispatch(updateCurrentLenderBrokerages(updatedArray));
        break;
      default:
        return;
    }

    dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
    dispatch(
      setEditLenderPayload({
        lenderId: lenderState?._id,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          [keyString]: updatedArray,
        },
      }),
    );
  };

  const handleCriteriaType = (e) => {
    const newCriteriaType = e.target.value;
    setCriteriaType(newCriteriaType);
    setIsFieldDirty(true);

    // Update local criteria when type changes
    if (criteriaObjects.includes(newCriteriaType)) {
      const updatedCriteria = CRITERIA[newCriteriaType];
      setLocalCriteria(updatedCriteria);
      updateCriteriaInStore(updatedCriteria);
    }
  };

  const handleInputChange = (e, newValue) => setCriteriaType(newValue);

  const handleAutoComplete = (e) => setIsFieldDirty(true);

  const handleRemoveCriteria = () => {
    const updatedCriteria = currentlyEditingFee.criteria.filter((c, i) => i !== index);
    const updatedLenderDetail = {
      ...currentlyEditingFee,
      criteria: updatedCriteria,
    };

    let updatedLenderDetailArr;

    if (keyString === "fees") {
      updatedLenderDetailArr = currentLenderFees.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === feeIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });
      dispatch(updateCurrentLenderFees(updatedLenderDetailArr));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      setFee(updatedLenderDetail);
    }

    if (keyString === "loading") {
      updatedLenderDetailArr = currentLenderLoadings.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === loadingIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });
      dispatch(updateCurrentLenderLoadings(updatedLenderDetailArr));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      setLoading(updatedLenderDetail);
    }

    if (keyString === "brokerage") {
      updatedLenderDetailArr = currentLenderBrokerages.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === brokerageIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });
      dispatch(updateCurrentLenderBrokerages(updatedLenderDetailArr));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      setBrokerage(updatedLenderDetail);
    }

    setLocalCriteria(updatedLenderDetail?.criteria);

    dispatch(
      setEditLenderPayload({
        lenderId: lenderId ?? lenderState?._id,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          [keyString]: updatedLenderDetailArr,
        },
      }),
    );
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     [keyString]: updatedLenderDetailArr,
    //   },
    // });
  };

  return (
    <React.Fragment key={index}>
      <Grid item xs={3}>
        <Autocomplete
          value={criteriaType}
          options={Object.keys(CRITERIA).map((key) => key)}
          getOptionLabel={(option) => option || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Criteria Type"
              size="small"
              onChange={handleCriteriaType}
              variant="filled"
              fullWidth
              sx={{ height: "100%" }}
            />
          )}
          onChange={handleAutoComplete}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid container item xs={7} columnSpacing={"16px"} rowGap={"16px"} alignItems={"stretch"}>
        {criteriaType && (
          <CriteriaInput
            criteria={localCriteria}
            criteriaType={criteriaType}
            isFieldDirty={isFieldDirty}
            lender={lenderState}
            index={index}
            feeIndex={feeIndex}
            loadingIndex={loadingIndex}
            brokerageIndex={brokerageIndex}
            // lenderDetail={lenderDetail}
            // editLender={editLender}
            editLenderLoading={editLenderLoading}
            keyString={keyString}
            updateCriteria={(updatedCriteria) => {
              setLocalCriteria(updatedCriteria);
              updateCriteriaInStore(updatedCriteria);
            }}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton disableRipple onClick={handleRemoveCriteria}>
          <Delete />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
};

export default CriteriaContent;
