import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";
import Brokerage from "../Card/Brokerage";

const BrokerageSection = React.memo(({ lender }) => {
  // setLender
  const lenderId = lender?._id;
  // const [editLender, { data: editLenderData, isLoading: editLenderLoading }] = useEditLenderMutation();
  const [editLender, { isLoading: editLenderLoading }] = useEditLenderMutation();

  // useEffect(() => {
  //   if (editLenderData && !editLenderLoading) debounce(() => setLender(editLenderData), 500);
  // }, [editLenderLoading]);

  const handleAddBrokerage = () => {
    const brokerage = [
      ...lender.brokerage.map((b) => {
        const { _id, ...rest } = b;
        return rest;
      }),
      {
        criteria: [],
        max: 0,
        value: 0,
      },
    ];
    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
        brokerage,
      },
    });
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Brokerage</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        {(lender?.brokerage ?? []).length > 0 && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            xs={9}
            direction="row"
            style={{ margin: "0px 0px 7px 0px" }}
            rowGap={"16px"}
          >
            {lender.brokerage.map((brokerage, index) => (
              <Brokerage
                data={brokerage}
                key={index}
                index={index}
                editLender={editLender}
                lender={lender}
                editLenderLoading={editLenderLoading}
              />
            ))}
          </Grid>
        )}
        <Button
          sx={{ width: "200px", ml: "8px" }}
          xs={12}
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddBrokerage}
        >
          ADD BROKERAGE
        </Button>
      </Grid>
    </Grid>
  );
});

export default BrokerageSection;
