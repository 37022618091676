import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import * as Yup from "yup";
import LicencePassportToggle from "./LicencePassportToggle";
import { saveCustomerProfile, updateCustomer } from "../../store/slices/applicationFormSlice";
import regex from "../../utils/regex";
import { ID_TYPE, customerTitleList, fieldRequiredMessage, validCustomerTitle } from "../../constants";
import RadioButtonGroup from "../Utils/RadioButtonGroup";

const CustomerPersonalDetails = React.memo(
  ({ customer, applicationId, key, fieldErrors = {}, setFieldErrors, customerId }) => {
    const dispatch = useDispatch();
    const [allValues, setAllValue] = useState({
      customerTitle: customer?.customerTitle || "",
      firstName: customer?.firstName || "",
      middleNames: customer?.middleNames || "",
      lastName: customer?.lastName || "",
      IDNumber: customer?.IDNumber || "",
      IDCardNumber: customer.IDCardNumber || "",
      dateOfBirth: customer?.dateOfBirth || "",
      residencyStatusValue: customer?.customerProfile?.residencyStatus,
      maritalStatusValue: customer?.customerProfile?.maritalStatus,
      IDExpiryDate: customer?.IDExpiryDate || "",
      IDType: customer?.IDType || ID_TYPE.LICENCE
    });

    // const [fieldErrors, setFieldErrors] = useState({
    //   customerTitle: "",
    //   firstName: "",
    //   middleNames: "",
    //   lastName: "",
    //   IDNumber: "",
    //   dateOfBirth: "",
    //   IDCardNumber: "",
    // });

    useEffect(() => {
      if (customer) {
        setAllValue({
          customerTitle: customer?.customerTitle || "",
          firstName: customer?.firstName || "",
          middleNames: customer?.middleNames || "",
          lastName: customer?.lastName || "",
          IDNumber: customer?.IDNumber || "",
          IDCardNumber: customer.IDCardNumber || "",
          dateOfBirth: customer?.dateOfBirth || "",
          residencyStatusValue: customer?.customerProfile?.residencyStatus,
          maritalStatusValue: customer?.customerProfile?.maritalStatus,
          IDExpiryDate: customer?.IDExpiryDate || "",
          IDType: customer?.IDType || ID_TYPE.LICENCE,
        })
      }
    }, [customer])

    const validationSchema = Yup.object({
        customerTitle: Yup.string().oneOf(validCustomerTitle, "Please select title").required("Title is required"),
        firstName: Yup.string()
          .required(fieldRequiredMessage.firstName)
          .matches(regex.customerNameRegex, "Only letters and ' - characters allowed.")
          .min(2, "Maximum of 2 characters")
          .max(50, "Maximum of 50 characters"),
        middleNames: Yup.string()
          .optional()
          .matches(regex.customerNameRegex, "Only letters and ' - characters allowed.")
          .max(50, "Maximum of 50 characters"),
        lastName: Yup.string()
          .required(fieldRequiredMessage.lastName)
          .matches(regex.customerNameRegex, "Only letters and ' - characters allowed.")
          .min(2, "Maximum of 2 characters")
          .max(50, "Maximum of 50 characters"),
        IDNumber: Yup.string()
          .optional()
          .matches(regex.drivingPassportRegex, "Only alphanumeric and - characters allowed.")
          .min(6, "Maximum of 6 characters")
          .max(15, "Maximum of 15 characters"),
        dateOfBirth: Yup.string()
          .typeError("Please enter a valid date")
          .matches(regex.customerDateOfBirthRegexOptional, "Invalid date,please enter a valid date")
          .optional()
          .max(new Date(), "Date is not select in the future"),
        IDCardNumber: Yup.string()
        .optional()
        .matches(regex.drivingPassportRegex, "Only alphanumeric and - characters allowed.")
        .min(6, "Maximum of 7 characters")
        .max(15, "Maximum of 16 characters"),
      IDExpiryDate: Yup.string()
        .typeError("Please enter a valid date")
        .matches(regex.customerDateOfBirthRegexOptional, "Invalid date,please enter a valid date")
        .max(new Date(), "Date is not select in the future"),
    })


    const validateField = async (name, fieldName, value) => {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    };

    const handle = {
      onChangeField: async (e, nameField) => {
        let isValid = true;
        const name = nameField.split("_")[0];
        if (name === "firstName" || name === "middleNames" || name === "lastName") {
          isValid = regex.customerNameRegex.test(e) && e?.length <= 50; // nameRegex
        } else if (name === "IDNumber") {
          isValid = regex.drivingPassportRegex.test(e) && e?.length <= 15;
        } else if (name === "IDCardNumber") {
          isValid = e?.length <= 16;
        }
        if (!isValid) {
          await validateField(name, nameField, e);
          return;
        }

        setAllValue({ ...allValues, [name]: e });
        await validateField(name, nameField, e);
      },
      onChangeRadio: (value, name) => {
        setAllValue({ ...allValues, [name]: value });
      },
      handleSaveCustomer: (data) => {
        dispatch(
          updateCustomer({
            customerId: customer._id,
            data: {
              application: applicationId,
              ...data,
            },
          }),
        );
      },
      handleResidencyStatus: (value) => {
        let oldVal = customer?.customerProfile?.residencyStatus;
        if (oldVal === value) {
          return;
        }
        dispatch(
          saveCustomerProfile({
            residencyStatus: value,
            customer: customer._id,
            applicationId,
          }),
        );
      },
      handleMaritalStatus: (value) => {
        let oldVal = customer?.customerProfile?.maritalStatus;
        if (oldVal === value) {
          return;
        }
        dispatch(
          saveCustomerProfile({
            maritalStatus: value,
            customer: customer._id,
            applicationId,
          }),
        );
      },
      handleLicenseNumber: () =>
        customer
          ? customer?.documents?.find((doc) => (doc.details?.licence_number ? doc.details?.licence_number : ""))
            ?.details?.licence_number
          : "",
      handleSaveIDType: (value) => {
        handle.handleSaveCustomer({
          IDType: value,
        });
      },
      blurFn: (field, value) => {
        try {
          const fieldName = field.split("_")[0];
          let fieldErr = fieldErrors[field];
          if (fieldName === "middleNames") {
            let isValid = regex.nameOptionalRegex.test(value);
            if (isValid) {
              fieldErr = "";
            } else {
              fieldErr = "Only letters and ' - characters allowed.";
            }
          } else if (fieldName === "IDNumber") {
            let isValid = regex.drivingPassportRegexOptional.test(value);
            if (isValid) {
              fieldErr = "";
            } else {
              fieldErr = "Only alphanumeric, space and - characters allowed.";
            }
          }
          else if (fieldName === "customerTitle") {
            let isValid = validCustomerTitle.includes(value)
            if (isValid) {
              fieldErr = "";
            } else {
              fieldErr = "Please select title";
            }
          }
          // else if(fieldName == "dateOfBirth"){
          //   let isValid = regex.dateOfBirthRegexOptional.test(value)
          //   if(isValid){
          //     fieldErr =""
          //   }else{
          //     fieldErr = "Only alphanumeric, space and - characters allowed.";
          //   }
          // }

          setFieldErrors((pre) => ({
            ...pre,
            [field]: fieldErr,
          }));
          if (fieldErr === "") {
            let oldVal = customer[field];
            if (oldVal !== value) {
              handle.handleSaveCustomer({
                [fieldName]: fieldName === "dateOfBirth" ? dayjs(value, "DD-MM-YYYY").format("DD-MM-YYYY") : value,
              });
            }
          }
        } catch (error) {
          const newErrors = {};
          error.inner.forEach((validationError) => {
            newErrors[validationError.path] = validationError.message;
          });
          setFieldErrors(newErrors);
        }
      },
    };

    const isLargeScreen = useMediaQuery("(min-width: 768px)");

    return (
      <React.Fragment>
        <Grid
          style={{
            margin: "0 0 30px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "30px",
          }}
        >
          <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
            <Typography id="input-slider" fontWeight={600}>
              Personal details
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }} margin={"0 0 10px"} width={"100%"}>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="customer-title">Title* </InputLabel>
                  <Select
                    size="small"
                    labelId="customer-title"
                    id="demo-simple-select"
                    value={allValues?.customerTitle}
                    label="Title"
                    name={`customerTitle_${customer._id}`}
                    onChange={(event) => {
                      handle.onChangeField(event?.target?.value, `customerTitle_${customer._id}`);
                    }}
                    onBlur={(event) => handle.blurFn(`customerTitle_${customer._id}`, event.target.value)}
                    error={fieldErrors ? fieldErrors[`customerTitle_${customer._id}`] : ''}
                  >
                    {customerTitleList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                  {fieldErrors && fieldErrors[`customerTitle_${customer._id}`] &&
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldErrors[`customerTitle_${customer._id}`]}</FormHelperText>
                  }
                  {/* <InputLabel id="customer-title">Title*</InputLabel>
                  <Select
                    size="small"
                    labelId="customer-title"
                    id="demo-simple-select"
                    value={allValues?.customerTitle}
                    label="Title"
                    onChange={(event) => {
                      handle.onChangeField(event?.target?.value, "customerTitle");
                    }}
                    onBlur={(event) => handle.blurFn("customerTitle", event.target.value)}
                    error={customerId === customer?._id && fieldErrors?.customerTitle}
                  >
                    {customerTitleList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                  {customerId === customer?._id && fieldErrors?.customerTitle && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldErrors.customerTitle}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  type="text"
                  name={`firstName_${customer._id}`}
                  label="First name"
                  variant="filled"
                  size="small"
                  value={allValues?.firstName}
                  error={fieldErrors[`firstName_${customer._id}`]}
                  helperText={fieldErrors[`firstName_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event?.target?.value, `firstName_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`firstName_${customer._id}`, e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name={`middleNames_${customer._id}`}
                  label="Other given names"
                  variant="filled"
                  size="small"
                  value={allValues?.middleNames}
                  error={fieldErrors[`middleNames_${customer._id}`]}
                  helperText={fieldErrors[`middleNames_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event?.target?.value, `middleNames_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`middleNames_${customer._id}`, e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  type="text"
                  name={`lastName_${customer._id}`}
                  label="Last name"
                  variant="filled"
                  size="small"
                  value={allValues?.lastName}
                  error={fieldErrors[`lastName_${customer._id}`]}
                  helperText={fieldErrors[`lastName_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event?.target?.value, `lastName_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`lastName_${customer._id}`, e.target.value)}
                />
              </Grid>
            </Stack>

            {/* <Grid item md={12} sm={12}>
            <Typography id="input-slider" gutterBottom>
            ID Number
            </Typography>
            </Grid> */}
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }} margin={"0 0 10px"} width={"100%"}>
              <Grid item xs={12} sm={4}>
                <InputMask
                  mask="99-99-9999"
                  value={allValues?.dateOfBirth || ""}
                  disabled={false}
                  maskChar={null}
                  onChange={(event) => {
                    handle.onChangeField(event?.target.value || null, `dateOfBirth_${customer._id}`);
                  }}
                  onBlur={(e) => handle.blurFn(`dateOfBirth_${customer._id}`, e.target.value)}
                >
                  {() => (
                    <TextField
                      label="Date of birth"
                      size="small"
                      fullWidth
                      placeholder="DD-MM-YYYY"
                      variant="filled"
                      name={`dateOfBirth_${customer._id}`}
                      style={{ background: "#ffffff" }}
                      error={fieldErrors[`dateOfBirth_${customer._id}`]}
                      helperText={fieldErrors[`dateOfBirth_${customer._id}`]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  // required
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name={`IDNumber_${customer._id}`}
                  label={allValues?.IDType === ID_TYPE.LICENCE ? 'Driver licence' : 'Passport no'}
                  variant="filled"
                  size="small"
                  value={allValues?.IDNumber}
                  error={fieldErrors[`IDNumber_${customer._id}`]}
                  helperText={fieldErrors[`IDNumber_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event.target.value, `IDNumber_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`IDNumber_${customer._id}`, e.target.value)}
                />
                <LicencePassportToggle allValues={allValues} setAllValue={setAllValue} handleSaveIDType={handle.handleSaveIDType} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  // required
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name={`IDCardNumber_${customer._id}`}
                  label={(allValues?.IDType === ID_TYPE.LICENCE ? ID_TYPE.LICENCE : ID_TYPE.PASSPORT) + " Card No"}
                  variant="filled"
                  size="small"
                  value={allValues?.IDCardNumber}
                  error={fieldErrors[`IDCardNumber_${customer._id}`]}
                  helperText={fieldErrors[`IDCardNumber_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event.target.value, `IDCardNumber_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`IDCardNumber_${customer._id}`, e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputMask
                  mask="99-99-9999"
                  value={allValues?.IDExpiryDate || ""}
                  disabled={false}
                  maskChar={null}
                  onChange={(event) => {
                    handle.onChangeField(event?.target.value || null, `IDExpiryDate_${customer._id}`);
                  }}
                  onBlur={(e) => handle.blurFn(`IDExpiryDate_${customer._id}`, e.target.value)}
                >
                  {() => (
                    <TextField
                      label={(allValues?.IDType === ID_TYPE.LICENCE ? ID_TYPE.LICENCE : ID_TYPE.PASSPORT) + " Expiry Date"}
                      size="small"
                      fullWidth
                      placeholder="DD-MM-YYYY"
                      variant="filled"
                      name={`IDExpiryDate_${customer._id}`}
                      style={{ background: "#ffffff" }}
                      error={fieldErrors[`IDExpiryDate_${customer._id}`]}
                      helperText={fieldErrors[`IDExpiryDate_${customer._id}`]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Stack>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="subtitle2">Residency status</Typography>
                  <RadioButtonGroup
                    required
                    name="residencyStatusValue"
                    value={allValues?.residencyStatusValue}
                    handleValue={(value) => {
                      handle.onChangeRadio(value, "residencyStatusValue");
                      handle.handleResidencyStatus(value);
                    }}
                    style={{
                      margin: "0 0 0 8px",
                      flexWrap: `${!isLargeScreen ? "wrap" : "unset"}`,
                    }}
                    optionStyle={{
                      width: `${!isLargeScreen ? "100%" : "50%"}`,
                      padding: "8px",
                      minHeight: "20px",
                    }}
                    options={[
                      { icon: "House", label: "Australian Citizen" },
                      { icon: "House", label: "Permanent Resident" },
                      { icon: "HomeWork", label: "New Zealand Citizen" },
                      { icon: "HomeWork", label: "Working Visa" },
                      { icon: "House", label: "Student Visa" },
                    ]}
                  />
                  {fieldErrors && fieldErrors[`customerProfile.residencyStatus_${customer._id}`] &&
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldErrors[`customerProfile.residencyStatus_${customer._id}`]}</FormHelperText>
                  }
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Marital status</Typography>
                <RadioButtonGroup
                  required
                  value={allValues?.maritalStatusValue}
                  name={"maritalStatusValue"}
                  handleValue={(value) => {
                    handle.onChangeRadio(value, "maritalStatusValue");
                    handle.handleMaritalStatus(value);
                  }}
                  style={{
                    margin: "0 0 0 8px",
                    flexWrap: `${!isLargeScreen ? "wrap" : "unset"}`,
                  }}
                  options={[
                    { icon: "House", label: "Married/Defacto" },
                    { icon: "HomeWork", label: "Single/Divorced/Widowed" },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  },
);

export default CustomerPersonalDetails;
