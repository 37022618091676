import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
import Loading from "../Card/Loading";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";

const LoadingSection = React.memo(({ lender }) => {
  // setLender
  const lenderId = lender?._id;
  // const [editLender, { data: editLenderData, isLoading: editLenderLoading }] = useEditLenderMutation();
  const [editLender, { isLoading: editLenderLoading }] = useEditLenderMutation();

  // useEffect(() => {
  //   if (editLenderData && !editLenderLoading) debounce(() => setLender(editLenderData), 500);
  // }, [editLenderLoading]);

  const handleAddLoading = () => {
    const loading = [
      ...lender.loading.map((loading) => {
        const { _id, ...rest } = loading;
        return rest;
      }),
      {
        criteria: [],
        valueType: "fixed",
        name: "Fee title",
        value: 0,
      },
    ];
    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
        loading,
      },
    });
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Loading</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        {(lender?.loading ?? []).length > 0 && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            xs={9}
            direction="row"
            style={{ margin: "0px 0px 7px 0px" }}
            rowGap={"16px"}
          >
            {lender.loading.map((loading, index) => (
              <Loading
                data={loading}
                key={index}
                index={index}
                editLender={editLender}
                lender={lender}
                editLenderLoading={editLenderLoading}
              />
            ))}
          </Grid>
        )}
        <Button
          sx={{ width: "200px", ml: "8px" }}
          xs={12}
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddLoading}
        >
          ADD LOADING
        </Button>
      </Grid>
    </Grid>
  );
});

export default LoadingSection;
