import * as React from "react";
import { useDispatch } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";

import {
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  LinearProgress,
  Avatar,
  Divider,
  Box,
  TextareaAutosize,
  Button,
} from "@mui/material";

import io from "socket.io-client";
import TimeAgoComponent from "../Utils/TimeAgoComponent";
import { addApplicationComment } from "../../store/slices/applicationFormSlice";

const Comments = React.memo(({ comments = [], applicationId, gettingApplicationComment }) => {
  const minTextareaRows = 3;

  const dispatch = useDispatch();
  const [socket, setSocket] = React.useState(null);
  const [commentsState, setCommentsState] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(false);
  const [currentRoom, setCurrentRoom] = React.useState(null);
  const [newComment, setNewComment] = React.useState("");
  const [textAreaRows, setTextAreaRows] = React.useState(minTextareaRows);

  React.useEffect(() => {
    if (comments.length > 0) {
      setCommentsState(comments.slice(0, 10));
      setHasMore(comments.length > 10);
    }
  }, [comments]);

  React.useEffect(() => {
    if (!socket) {
      setSocket(
        io(`${process.env.REACT_APP_API_URL}/applicationComment`, {
          transports: ["websocket", "polling"],
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 10000,
          auth: {
            "x-access-token": localStorage.getItem("token") || "",
          },
        }),
      );
    }

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  React.useEffect(() => {
    if (!socket) return;
    if (applicationId) {
      if (currentRoom && currentRoom !== applicationId) {
        socket.emit("leaveApplicationCommentRoom", currentRoom, () => {
          socket.emit("joinApplicationCommentRoom", applicationId);
          socket.disconnect();
          setCurrentRoom(applicationId);
        });
      } else if (!currentRoom) {
        socket.emit("joinApplicationCommentRoom", applicationId);
        setCurrentRoom(applicationId);
      }

      const handleNewComment = (newComment) => {
        setCommentsState((prevComments) => [newComment, ...prevComments]);
      };

      const handleConnect = () => {
        socket.emit("joinApplicationCommentRoom", applicationId);
      };

      const handleReconnect = () => {
        socket.emit("joinApplicationCommentRoom", applicationId);
      };

      socket.on("newComment", handleNewComment);
      socket.on("connect", handleConnect);
      socket.on("reconnect", handleReconnect);

      return () => {
        socket.emit("leaveApplicationCommentRoom", currentRoom);
        socket.off("newComment", handleNewComment);
        socket.off("connect", handleConnect);
        socket.off("reconnect", handleReconnect);
      };
    }
  }, [applicationId, currentRoom, socket]);

  const handle = {
    handleTextAreaChange: (event) => {
      const textareaLineHeight = 24; // Adjust this value based on your textarea's line-height
      const minRows = minTextareaRows;
      const maxRows = 6; // Maximum number of rows before scrolling
      const previousRows = event.target.rows;

      event.target.rows = minRows; // Reset the rows to the minimum

      const currentRows = Math.ceil((event.target.scrollHeight - textareaLineHeight) / textareaLineHeight);

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      // Set the number of rows to the maximum of either currentRows or minRows
      setTextAreaRows(Math.max(currentRows, minRows));

      setNewComment(event.target.value);
    },
    handleAddComment: () => {
      if (newComment !== "") {
        dispatch(addApplicationComment({ comment: newComment, applicationId }));
        setNewComment("");
      }
    },
    fetchMoreData: () => {
      if (commentsState.length >= comments.length) {
        setHasMore(false);
        return;
      }
      const nextBatch = comments.slice(commentsState.length, commentsState.length + 10);
      setCommentsState((prevComments) => [...prevComments, ...nextBatch]);
    },
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "0 15px 0 15px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          style={{
            marginBottom: 20,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Comments
        </Typography>
      </Stack>
      {gettingApplicationComment ? (
        <Box sx={{ margin: "auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "calc(77vh - 77px)",
            height: "calc(77vh - 77px)",
            flexGrow: 1,
            marginBottom: 0.5,
            overflow: "auto",
            padding: "0px 0px 16px 0px",
            scrollbarWidth: "thin",
          }}
          id="scrollableBox"
        >
          <InfiniteScroll
            dataLength={commentsState.length}
            next={handle.fetchMoreData}
            hasMore={hasMore}
            loader={<LinearProgress />}
            scrollableTarget="scrollableBox"
          >
            <List sx={{ width: "100%", maxWidth: "97%" }}>
              {commentsState.map((comment, index) => (
                <React.Fragment key={comment._id}>
                  <ListItem alignItems="flex-start" sx={{ padding: "8px 0px 8px 0px" }}>
                    <ListItemAvatar>
                      <Avatar>
                        {comment.user.firstName[0]}
                        {comment.user.lastName[0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <Typography variant="">{`${comment.user.firstName} ${comment.user.lastName}`}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            <TimeAgoComponent timestamp={comment.createdAt} />
                          </Typography>
                        </div>
                      }
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
                        >
                          {comment.comment}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < commentsState.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              ))}
              {!hasMore && commentsState.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center", width: "100%", marginTop: 5 }}
                      >
                        No more comments
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </InfiniteScroll>
        </Box>
      )}
      <Box sx={{ position: "sticky", bottom: 0, padding: "16px 0" }}>
        <TextareaAutosize
          minRows={textAreaRows}
          maxRows={10}
          value={newComment}
          onChange={handle.handleTextAreaChange}
          style={{ width: "100%", marginBottom: "10px", resize: "none", padding: "10px" }}
        />
        <Button variant="contained" color="primary" fullWidth onClick={handle.handleAddComment}>
          Add Comment
        </Button>
      </Box>
    </Box>
  );
});

export default Comments;
