import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Skeleton,
  TextField,
  Typography,
  Button,
  Autocomplete,
} from "@mui/material";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import { debounce } from "lodash";
import moment from "moment";
import * as Yup from "yup";

import { useSnackbar } from "notistack";
// import EntityEmail from "./EntityEmail";
// import EntityPhoneNumber from "./EntityPhoneNumber";
// import { isDigitsOnly } from "../../utils/isDigitsOnly";
import regex from "../../utils/regex";
import {
  ERRORS_MESSAGE,
  SUCCESS_MESSAGE,
  entityType,
  entityStatus,
  industryList,
  validEntityStatus,
  creditCardModalInfo,
  FILE_AWAITING_MESSAGE,
  validIndustryList,
} from "../../constants";
import { userSelector } from "../../store/slices/userSlice";
import {
  createEntity,
  entitySelector,
  updateEntity,
  saveValue,
  getASICData,
  userDetailsSelector,
  removeEntity,
  getCommercialCreditFile,
  setValidationRequireFieldError,
} from "../../store/slices/applicationFormSlice";

import { getBusinessByABN } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";
import Document from "../CustomerDetails/Document";
import { formatAmount, transformCreditHistoryName, transformPublicProfileName } from "../Utils/helperFunction";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";
import { HtmlTooltip } from "../ProductSelector/Product";

const Entity = React.memo(({ application, entitys, index, entities, fieldsErrors, setFieldsErrors }) => {
  const dispatch = useDispatch();

  const { gettingASICData, gettingASICPPSRData, gettingCreditProfile, gettingEntityDetails, gettingEquifaxCreditCompanyFile, validationRequireFieldError } =
    useSelector(userDetailsSelector);
  const userDetails = useSelector(userSelector);
  const { entityFieldErrors } = validationRequireFieldError;

  const entity = useSelector(entitySelector);
  const { enqueueSnackbar } = useSnackbar();
  const [businessSearchValue, setBusinessSearchValue] = useState({
    searchValueBusiness: "",
    businessSearchInputText: "",
  });

  // const [fieldErrors, setFieldErrors] = useState({
  //   business_search: "",
  //   turnover: "",
  //   industryType: "",
  //   emailAddress: "",
  //   phoneNumber: "",
  // });

  const [turnover, setTurnoverValue] = useState({});
  const [publicRecordValue, setPublicRecordValue] = useState([]);
  const [creditHistoryValue, setCreditHistoryValue] = useState([]);
  const [creditHistoryDefaults, setCreditHistoryDefaults] = useState({
    paymentDefaultsList: [],
    categoryDefaultsList: [],
    statusDefaultsList: [],
  });
  const [industryTypeValue, setIndustryTypeValue] = useState(entitys?.industryType || "");
  // const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  // const [entityOptions, setEntityOptions] = useState([]);

  const numOfColumns = 3;
  const numOfRows = 2;

  const columnLayouts = [
    [4, 12],
    [4, 12, 12],
  ];

  const validationSchema = Yup.object({
    turnover: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers and decimal points allowed.")
      .max(12, "Maximum of 12 characters"),
    industryType: Yup.string()
      .optional()
      .oneOf(validIndustryList, "Please select valid industry type")
    // .required("Industry type is required"),
  });

  const validateField = async (fieldName, value) => {
    try {
      const name = fieldName.split("_")[0];
      await validationSchema.validateAt(name, { [name]: value });
      // setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      dispatch(setValidationRequireFieldError({ ...validationRequireFieldError, entityFieldErrors: { ...entityFieldErrors, [fieldName]: "" } }))
    } catch (error) {
      // setFieldErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [fieldName]: error.message,
      // }));
      dispatch(setValidationRequireFieldError({ ...validationRequireFieldError, entityFieldErrors: { ...entityFieldErrors, [fieldName]: error.message } }))
    }
  };

  const handle = {
    entityFn: (update) => {
      dispatch(updateEntity(update));
    },
    entityDetailsFn: async (details) => {
      dispatch(saveValue({ gettingEntityDetails: true }));
      // setFieldErrors({ business_search: "" });
      const abrData = await getBusinessByABN(details.Abn);

      if (abrData.EntityName !== entity?.entityName) {
        dispatch(createEntity({ abrData, applicationId: application._id })).then((res) => {
          if (res?.payload?.data?.data) {
            // enqueueSnackbar(SUCCESS_MESSAGE.applicationCreateSuccessMsg, {
            //   variant: "success",
            //   autoHideDuration: 5000,
            // });
          } else {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      } else {
        dispatch(saveValue({ gettingEntityDetails: false }));
      }
    },
    loadingGridFirst: (
      <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
        {Array.from({ length: numOfColumns * numOfRows }).map((_, index) => (
          <Grid key={index} item sm={4}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        ))}
      </Grid>
    ),
    loadingGridSecond: (
      <Grid item container spacing={2}>
        {columnLayouts.map((columns, rowIndex) => (
          <Grid key={rowIndex} item container>
            {columns.map((sm, columnIndex) => (
              <Grid key={columnIndex} item sm={sm}>
                <Skeleton variant="text" />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    ),
    onChangeFieldTurnover: async (e, fieldName) => {
      // const name = fieldName.split("_")[0];
      let isValid = true;

      isValid = regex.allowOnlyNumber.test(e) && e?.length <= 12;

      if (!isValid) {
        await validateField(fieldName, e);
        return;
      } else {
        setTurnoverValue({ [entitys._id]: e });
        await validateField(fieldName, e);
      }
    },
    onChangeFieldIndustryType: async (e, name) => {
      // let isValid = true;

      // isValid = regex.alphaNumericAndSpecificChar.test(e) && e?.length <= 50;

      if (!e) {
        setIndustryTypeValue("");
        await validateField(name, e);
        return;
      } else {
        setIndustryTypeValue(e);
        await validateField(name, e);
      }
    },
    addAddress: (newAddressValue) => {
      if (newAddressValue) {
        dispatch(
          updateEntity({
            entityId: entitys._id,
            applicationId: application._id,
            address: newAddressValue,
            isManualAddress: false
          }),
        );
      }
    },
    updateAddress: (newAddressValue) => {
      if (newAddressValue) {
        dispatch(updateEntity(newAddressValue));
      }
    },
    removeAddress: (newAddressValue) => {
      if (newAddressValue) {
        dispatch(
          updateEntity({
            entityId: entitys._id,
            addressId: newAddressValue?._id,
          }),
        );
      }
    },
  };

  const debounced = debounce(async (inputValue) => {
    // if (inputValue) {
    // setLoadingBusinessSearch(true);
    // if (isDigitsOnly.test(inputValue)) {
    // const businessByABN = await getBusinessByABN(inputValue);
    // setLoadingBusinessSearch(false);
    // if (businessByABN) {
    //   setEntityOptions([businessByABN]);
    //   return;
    // }
    // }
    // const businesses = await getBusinessByName(inputValue);
    // setLoadingBusinessSearch(false);
    // if (businesses?.Names?.length > 0) {
    //   setEntityOptions(businesses.Names);
    // } else {
    //   setEntityOptions([
    //     {
    //       Name: "No results found",
    //     },
    //   ]);
    // }
    // }
  }, 500);

  useEffect(() => {
    // const withValueArray = [];
    const publicRecordValueArray = [];
    const creditHistoryValueArray = [];
    const creditDefaults = {
      paymentDefaultsList: [],
      categoryDefaultsList: [],
      statusDefaultsList: [],
    };

    if (entitys?.creditProfile?.publicReports) {
      Object.values(entitys?.creditProfile?.publicReports).forEach((category) => {
        if (Array.isArray(category)) {
          category.forEach((item) => {
            const temp = {
              ...item,
              amount: category.find((record) => record.name === `${item.name}_Value`)?.amount,
            };
            if (item.name && !item.name.includes("_Value")) {
              // withValueArray.push(item);
              publicRecordValueArray.push(temp);
            }
            // else {
            //   publicRecordValueArray.push(temp);
            // }
          });
        }
      });

      setPublicRecordValue(publicRecordValueArray);
    }

    // Set credit History below
    if (entitys?.creditProfile?.creditHistory) {
      const entityCreditHistory = entitys?.creditProfile?.creditHistory;
      Object.values(entityCreditHistory).forEach((category) => {
        if (Array.isArray(category)) {
          category.forEach((item) => {
            const temp = {
              ...item,
              amount: category.find((record) => record.name === `${item.name}_Value`)?.amount,
            };
            if (item.name && !item.name.includes("_Value")) {
              creditHistoryValueArray.push(temp);
            }
          });
        }
      });

      setCreditHistoryValue(creditHistoryValueArray);

      // Set credit profile payment Defaults
      Object.values(entityCreditHistory?.paymentDefaultsList).forEach((category) => {
        if (Array.isArray(category)) {
          category.forEach((item) => {
            const temp = {
              ...item,
              amount: category.find((record) => record.name === `${item.name}_Value`)?.amount,
            };
            // if (item.name && !item.name.includes("_Value")) {
            //   creditDefaults?.paymentDefaultsList.push(temp);
            // }
            if (item.name && (item.name.includes("_Value") || item.name.includes("-Value"))) {
              // Do nothing
            } else {
              creditDefaults?.paymentDefaultsList.push(temp);
            }
          });
        }
      });

      // Set credit profile category Defaults
      Object.values(entityCreditHistory?.categoryDefaultsList).forEach((category) => {
        if (Array.isArray(category)) {
          category.forEach((item) => {
            const temp = {
              ...item,
              amount: category.find((record) => record.name === `${item.name}_Value`)?.amount,
            };
            if (item.name && (item.name.includes("_Value") || item.name.includes("-Value"))) {
              // Do nothing
            } else {
              creditDefaults?.categoryDefaultsList.push(temp);
            }
          });
        }
      });

      // Set credit profile status Defaults
      Object.values(entityCreditHistory?.statusDefaultsList).forEach((category) => {
        if (Array.isArray(category)) {
          category.forEach((item) => {
            const temp = {
              ...item,
              amount: category.find((record) => record.name === `${item.name}_Value`)?.amount,
            };
            if (item.name && !item.name.includes("-Value")) {
              creditDefaults?.statusDefaultsList.push(temp);
            }
          });
        }
      });

      setCreditHistoryDefaults(creditDefaults);
    }
  }, [entitys?.creditProfile]);

  useEffect(() => {
    if (!gettingASICData) {
      dispatch(saveValue({ gettingASICData: null }));
    }
  }, [gettingASICData]);

  useEffect(() => {
    debounced(businessSearchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [businessSearchValue?.searchValueBusiness, businessSearchValue?.businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchValue({
        searchValueBusiness: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  const renderPpsrComponent = (entitys) => {
    if (entitys?.ppsr) {
      if (entitys?.ppsr?.registrations?.length > 0) {
        return (
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={600}>
              PPSR
            </Typography>
            <Grid container item direction="row">
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Type
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Security group
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Start date
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  End date
                </Typography>
              </Grid>
            </Grid>
            {entitys?.ppsr?.registrations.map((registration) => (
              <Grid container item direction="row">
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                    {registration.collateralClassDescription}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                    {registration.securityPartyGroups.map((group) => (
                      <Stack>{group.organisationName}</Stack>
                    ))}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                    {moment(registration.startTime, "DD-MM-YYYY").format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                    {registration.endTime}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        );
      } else {
        return;
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={600} textAlign={"center"} sx={{ padding: "15px" }}>
            No PPSR data available
          </Typography>
        </Grid>;
      }
    } else {
      return null;
    }
  };
  // const findEmail = entitys?.emails?.find((email) => email.status === "current");

  // const findMobileNumber = entitys?.phoneNumbers?.find((number) => number.status === "current");

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <BusinessTwoToneIcon style={{ marginRight: "10px" }} />
        <Typography fontWeight={600}>
          {/* {index < 1 ? "Entity" : `Entity ${index + 1}`} */}
          {`Entity #${index + 1}`}
        </Typography>
      </Grid>

      <Grid
        conatiner
        xs={12}
        sm={12}
        md={10}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        {entitys && (
          <Grid key={entitys?.entityName} style={{ margin: "0 0 40px 0" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ margin: "0 0 20px" }}>
              <Typography>{entitys?.entityName}</Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setFieldsErrors(false);
                  dispatch(
                    removeEntity({
                      entityId: entitys._id,
                      applicationId: application._id,
                    }),
                  );
                }}
              >
                Remove entity
              </Button>
            </Stack>
            <Grid item container style={{ margin: " 0 0 10px" }}>
              {entitys?.abn && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    ABN
                  </Typography>
                  <Typography variant="body2">{readableABNString(entitys?.abn)}</Typography>
                </Grid>
              )}

              {entitys?.acn && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    ACN
                  </Typography>
                  <Typography variant="body2">{readableACNString(entitys?.acn)}</Typography>
                </Grid>
              )}

              {entitys?.gst && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    GST
                  </Typography>
                  <Typography variant="body2">
                    {entitys?.gst ? `${moment().diff(moment(entitys?.gst), "months")} months` : "Not registered"}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container style={{ margin: "0 0 10px 0" }}>
              {entitys?.entityName && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Entity name
                  </Typography>
                  <Typography variant="body2">{entitys?.entityName}</Typography>
                </Grid>
              )}

              {entitys?.entityType && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Entity type
                  </Typography>
                  <Typography variant="body2">{entitys?.entityType}</Typography>
                </Grid>
              )}

              {entitys?.timeInBusiness && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Time in business
                  </Typography>
                  <Typography variant="body2">
                    {`${moment().diff(moment(entitys?.timeInBusiness), "months")} months`}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container style={{ margin: "0 0 10px 0" }}>
              {entitys?.status && (
                <Grid item lg={4} md={4} sm={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Status
                  </Typography>
                  <Typography variant="body2">{entitys?.status}</Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                {entitys?.businessNames?.length > 0 && (
                  <>
                    <Typography variant="subtitle2" fontWeight={600}>
                      Business names
                    </Typography>
                    <Typography variant="body2">
                      {entitys?.businessNames.map((name, i) => (
                        <span>{entitys?.businessNames.length - 1 === i ? `${name}` : `${name}, `}</span>
                      ))}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container style={{ margin: "0 0 10px 0" }}>
              {entitys?.addresses?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Business addresses
                  </Typography>
                  {entitys?.addresses.map((address) => (
                    <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                      {address.fullAddress}
                    </Typography>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid container style={{ margin: "0 0 20px 0" }}>
              {renderPpsrComponent(entitys)}
            </Grid>
            {entitys?.creditProfile && (
              <Grid container style={{ marginBottom: "20px" }}>
                {/* Public Record Information */}
                {entitys?.creditProfile && entitys?.creditProfile?.publicReports && (
                  <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      style={{
                        marginBottom: "10px",
                        textDecorationLine: "underline",
                      }}
                    >
                      Public Record Information
                    </Typography>
                    <Grid container item direction="row">
                      <Grid item sm={12} xs={12} md={12} style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle2" fontWeight={600}>
                          External Administrations : {entitys?.creditProfile.publicReports?.externalAdministrations}
                          <br />
                          Previous Company Names : {entitys?.creditProfile.publicReports?.previousCompanyNames}
                        </Typography>
                      </Grid>

                      <Grid container sm={12} xs={12} md={12} columns={{ xs: 12, sm: 12, md: 12 }} direction="column">
                        {publicRecordValue?.map((item, index) => (
                          <Grid item sm={2} md={2} xs={2} key={index}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontWeight: [0, 3, 6].includes(index) ? 600 : 400,
                              }}
                            >
                              {`${transformPublicProfileName(item.name)} ${item.count} ${item.name.includes("Petitions") ? "" : `$${item.amount}` || 0
                                }`}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* Credit History Section */}
                {entitys?.creditProfile && entitys?.creditProfile?.creditHistory && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      style={{
                        marginBottom: "10px",
                        textDecorationLine: "underline",
                      }}
                    >
                      Credit History Snapshot
                    </Typography>
                    <Grid container item spacing={{ xs: 1, md: 2, sm: 2 }} direction="row" sm={12} xs={12} md={12}>
                      <Grid item sm={12} xs={6} md={6}>
                        <Grid container item>
                          <Grid item sm={6} xs={7} md={6}></Grid>
                          <Grid
                            item
                            sm={3}
                            xs={2}
                            md={2}
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Number
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            md={4}
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Amount
                          </Grid>
                        </Grid>
                        {creditHistoryValue?.map((item, index) => (
                          <Grid container item spacing={1}>
                            <Grid
                              item
                              sm={6}
                              xs={7}
                              md={6}
                              style={{
                                fontSize: "14px",
                                margin: "0 0 5px",
                                fontWeight: [0, 3, 6].includes(index) ? 600 : 400,
                              }}
                            >
                              {`${transformCreditHistoryName(item.name)}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={2}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {`${item.count}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={4}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {formatAmount(item?.amount)}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>

                      {/* Defaults List */}
                      <Grid item sm={12} xs={6} md={6}>
                        <Grid container item>
                          <Grid item sm={6} xs={7} md={6}></Grid>
                          <Grid
                            item
                            sm={3}
                            xs={2}
                            md={3}
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Number
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            md={3}
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Amount
                          </Grid>
                        </Grid>
                        {creditHistoryDefaults?.paymentDefaultsList?.map((item, index) => (
                          <Grid container item>
                            <Grid
                              item
                              sm={6}
                              xs={7}
                              md={6}
                              style={{
                                fontSize: "14px",
                                margin: "0 0 5px",
                              }}
                            >
                              {`${transformCreditHistoryName(item.name)}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={2}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {`${item.count}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {formatAmount(item?.amount)}
                            </Grid>
                          </Grid>
                        ))}
                        <Typography variant="subtitle2" fontWeight={600}>
                          Category of Default
                        </Typography>
                        {creditHistoryDefaults?.categoryDefaultsList?.map((item, index) => (
                          <Grid container item>
                            <Grid
                              item
                              sm={6}
                              xs={7}
                              md={6}
                              style={{
                                fontSize: "14px",
                                margin: "0 0 5px",
                              }}
                            >
                              {`${transformCreditHistoryName(item.name)}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={2}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {`${item.count}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {formatAmount(item?.amount)}
                            </Grid>
                          </Grid>
                        ))}
                        <Typography variant="subtitle2" fontWeight={600}>
                          Default Status
                        </Typography>
                        {creditHistoryDefaults?.statusDefaultsList?.map((item, index) => (
                          <Grid container item>
                            <Grid
                              item
                              sm={6}
                              xs={7}
                              md={6}
                              style={{
                                fontSize: "14px",
                                margin: "0 0 5px",
                              }}
                            >
                              {`${transformCreditHistoryName(item.name)}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={2}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {`${item.count}`}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={3}
                              style={{
                                fontSize: "14px",
                                textAlign: "end",
                              }}
                            >
                              {formatAmount(item?.amount)}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container item style={{ margin: "-10px 0 30px 0" }}>
              {entitys?.addresses.length === 0 && entitys?.entityType && (
                <Grid item sm={8} style={{ marginBottom: "20px" }}>
                  {/* <Typography variant="caption">Enter address</Typography> */}
                  <GoogleAddressSearch
                    customerId={entitys?._id}
                    singleCustomerId={entities?.[0]?._id}
                    applicationId={application._id}
                    guarantorFieldErrors={fieldsErrors}
                    setGuarantorFieldErrors={setFieldsErrors}
                    handleCreateAddress={handle.addAddress}
                    handleUpdateAddress={handle.updateAddress}
                    handleRemoveAddress={handle.removeAddress}
                    isEntity={true}
                    address={entitys ? entitys?.addresses?.slice(-1)[0] : ""}
                    addressFieldName={`entityAddress_${entitys?._id}_0`}
                    validateComponentField={validateField}
                  />
                </Grid>
              )}
              {/* <Grid container item spacing={1}>
                <Grid item sm={4}>
                  <EntityEmail
                    applicationId={application._id}
                    entityId={entitys?._id}
                    entityFieldErrors={fieldErrors}
                    setEntityFieldErrors={setFieldErrors}
                    email={entitys ? findEmail || entitys?.emails?.slice(-1)[0] : ""}
                  // singleCustomerId={customerId}
                  />
                </Grid>
                <Grid item sm={4}>
                  <EntityPhoneNumber
                    applicationId={application._id}
                    entityFieldErrors={fieldErrors}
                    setEntityFieldErrors={setFieldErrors}
                    entityId={entitys?._id}
                    phoneNumber={entitys ? findMobileNumber || entitys?.phoneNumbers?.slice(-1)[0] : ""}
                  // singleCustomerId={customerId}
                  />
                </Grid>
              </Grid> */}
            </Grid>
            <Grid container item style={{ margin: "-10px 0 10px 0" }}>
              <Grid container item spacing={1}>
                <Grid item md={4} sm={5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    type="text"
                    // name="turnover"
                    name={`turnover_${entitys?._id}`}
                    label="Turnover (yearly)"
                    variant="filled"
                    size="small"
                    value={turnover?.[entitys?._id] || entitys?.turnover || ""}
                    error={entityFieldErrors && entityFieldErrors[`turnover_${entitys._id}`]}
                    helperText={entityFieldErrors && entityFieldErrors[`turnover_${entitys._id}`]}
                    onChange={(event) => handle.onChangeFieldTurnover(event?.target?.value, `turnover_${entitys?._id}`)}
                    onBlur={(event) => {
                      if (entitys?.turnover !== event.target.value) {
                        handle.entityFn({
                          entityId: entitys?._id,
                          turnover: event.target.value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item md={8} sm={7}>
                  <Autocomplete
                    value={industryTypeValue}
                    options={industryList}
                    name={`industryType_${entitys._id}`}
                    onChange={(event, newValue) => {
                      handle.onChangeFieldIndustryType(newValue?.value, `industryType_${entitys._id}`);
                    }}
                    onBlur={(event) => {
                      if (entitys?.industryType !== event.target.value) {
                        handle.entityFn({
                          entityId: entitys?._id,
                          industryType: event?.target?.value,
                        });
                      }
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField size="small" {...params} name={`industryType_${entitys._id}`} label="Industry or business type" variant="filled" error={entityFieldErrors && entityFieldErrors[`industryType_${entitys._id}`]}
                        helperText={entityFieldErrors && entityFieldErrors[`industryType_${entitys._id}`]} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {entitys?.status && !validEntityStatus?.includes(entitys?.status) && (
              <Grid container style={{ marginBottom: "5px", paddingBottom: "10px" }}>
                <Typography fontSize="13px" fontWeight={600}>
                  Note :{" "}
                </Typography>
                <Typography color="#d32f2f" fontSize="13px" fontWeight={600}>
                  This is a Deregistered ASIC organisation. Current ASIC Extracts cannot be purchased for Deregistered.
                </Typography>
              </Grid>
            )}
            {gettingEquifaxCreditCompanyFile
              && entitys?.creditProfile?.publicReports
              && !entitys?.documents?.find(
                (doc) => doc.name === "Commercial Credit File")
              // && <DocumentLoader />
              && <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingEquifaxCreditPDFMsg}</Typography>
            }
            <Grid container style={{ margin: "10px 0 5px 0" }}>
              {entitys?.documents?.map((doc, index) => (
                <Grid key={doc?._id || index} style={{ alignItems: "start" }}>
                  <Document document={doc} name="asicDocument" />
                </Grid>
              ))}
              {/* Make loader for equifax pdf load condition : credit profile data should be there and file is missing */}

            </Grid>
            <Grid container spacing={1} alignItems="stretch">
              {entitys?.status && entitys?.status === entityStatus.active && (
                <>
                  {!entitys?.acn ||
                    (!entitys?.directors?.[0] && (
                      <Grid item md={4} sm={4}>
                        <HtmlTooltip
                          tooltipPadding={"20px"}
                          title={
                            !userDetails?.is_service_enable && (
                              <React.Fragment>
                                <Typography color="inherit" marginBottom="5px">
                                  {creditCardModalInfo.mainTitle}
                                </Typography>
                                <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                  {creditCardModalInfo.subTitle}
                                </Typography>
                                <Link to="/billing/creditCard">
                                  <Button variant="outlined" size="small">
                                    {creditCardModalInfo.btnText}
                                  </Button>
                                </Link>
                              </React.Fragment>
                            )
                          }
                        >
                          <Card
                            style={{ height: "100%" }}
                            onClick={() => {
                              if (!userDetails?.is_service_enable || gettingASICData === entitys?._id) return;
                              dispatch(saveValue({ gettingASICData: entitys?._id }));
                              dispatch(
                                getASICData({
                                  acn: entitys?.acn,
                                  applicationId: application?._id,
                                  entityId: entitys?._id,
                                }),
                              ).then((res) => {
                                if (
                                  res?.payload?.data &&
                                  Object.keys(res?.payload?.data).length > 0 &&
                                  res?.payload?.data?.entities
                                ) {
                                  const fendedEntity = res?.payload?.data?.entities?.find(
                                    (entity) => entity?._id === entitys?._id,
                                  );
                                  if (
                                    fendedEntity?.addresses?.length &&
                                    entitys?.addresses &&
                                    entitys?.addresses?.length > 0
                                  ) {
                                    handle.removeAddress(entitys?.addresses?.[0]);
                                  }

                                  enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
                                    variant: "success",
                                    autoHideDuration: 5000,
                                  });
                                } else {
                                  enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                                    variant: "error",
                                    autoHideDuration: 5000,
                                  });
                                }
                              });
                            }}
                          >
                            <CardActionArea
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "start",
                                cursor:
                                  !userDetails?.is_service_enable || gettingASICData === entitys?._id ? "auto" : "",
                                opacity:
                                  !userDetails?.is_service_enable || gettingASICData === entitys?._id ? "0.4" : "",
                                backgroundColor:
                                  !userDetails?.is_service_enable || gettingASICData === entitys?._id ? "#dfdfdf" : "",
                              }}
                            >
                              <CardContent>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{
                                    alignItems: "center",
                                    // height: "40px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <img height="40px" src="/static/asic-logo.png" alt="" />
                                  <Typography fontWeight={600}>Get ASIC extract</Typography>
                                </Stack>
                                <Typography fontSize={13}>
                                  Retrieve full ASIC extract including directors and beneficial owners for{" "}
                                  {entitys?.entityName}.
                                </Typography>
                              </CardContent>
                              {gettingASICData === entitys?._id && (
                                <Stack
                                  spacing={2}
                                  style={{
                                    background: "rgba(0,0,0, 0.5)",
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    color: "#fff",
                                  }}
                                >
                                  <CircularProgress />
                                  <Typography>Getting entity data...</Typography>
                                </Stack>
                              )}
                            </CardActionArea>
                          </Card>
                        </HtmlTooltip>
                      </Grid>
                    ))}
                  {!entitys?.acn ||
                    (!entitys?.directors?.[0] && (
                      <Grid item md={4} sm={4}>
                        <HtmlTooltip
                          tooltipPadding={"20px"}
                          title={
                            !userDetails?.is_service_enable && (
                              <React.Fragment>
                                <Typography color="inherit" marginBottom="5px">
                                  {creditCardModalInfo.mainTitle}
                                </Typography>
                                <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                  {creditCardModalInfo.subTitle}
                                </Typography>
                                <Link to="/billing/creditCard">
                                  <Button variant="outlined" size="small">
                                    {creditCardModalInfo.btnText}
                                  </Button>
                                </Link>
                              </React.Fragment>
                            )
                          }
                        >
                          <Card
                            style={{ height: "100%" }}
                            onClick={() => {
                              if (!userDetails?.is_service_enable || !gettingASICPPSRData === entitys?._id) return;
                              dispatch(saveValue({ gettingASICPPSRData: entitys?._id }));
                              dispatch(
                                getASICData({
                                  acn: entitys?.acn,
                                  applicationId: application?._id,
                                  entityId: entitys?._id,
                                  ppsr: true,
                                }),
                              ).then((res) => {
                                if (
                                  res?.payload?.data &&
                                  Object.keys(res?.payload?.data).length > 0 &&
                                  res?.payload?.data?.entities
                                ) {
                                  const fendedEntity = res?.payload?.data?.entities?.find(
                                    (entity) => entity?._id === entitys?._id,
                                  );
                                  if (
                                    fendedEntity?.addresses?.length &&
                                    entitys?.addresses &&
                                    entitys?.addresses?.length > 0
                                  ) {
                                    handle.removeAddress(entitys?.addresses?.[0]);
                                  }
                                  enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
                                    variant: "success",
                                    autoHideDuration: 5000,
                                  });
                                } else {
                                  enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                                    variant: "error",
                                    autoHideDuration: 5000,
                                  });
                                }
                              });
                            }}
                          >
                            <CardActionArea
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "start",
                                cursor:
                                  !userDetails?.is_service_enable || gettingASICPPSRData === entitys?._id ? "auto" : "",
                                opacity:
                                  !userDetails?.is_service_enable || gettingASICPPSRData === entitys?._id ? "0.4" : "",
                                backgroundColor:
                                  !userDetails?.is_service_enable || gettingASICPPSRData === entitys?._id
                                    ? "#dfdfdf"
                                    : "",
                              }}
                            >
                              <CardContent>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{
                                    alignItems: "center",
                                    // height: "40px",

                                    marginBottom: "10px",
                                  }}
                                >
                                  <img height="40px" src="/static/asic-logo.png" alt="" />
                                  <img height="40px" src="/static/ppsr-logo.png" alt="" />
                                  <Typography fontWeight={600}>Get ASIC extract & PPSR data</Typography>
                                </Stack>
                                <Typography fontSize={13}>
                                  Retrieve full ASIC extract and PPSR data for {entitys?.entityName}.
                                </Typography>
                              </CardContent>
                              {gettingASICPPSRData === entitys?._id && (
                                <Stack
                                  spacing={2}
                                  style={{
                                    background: "rgba(0,0,0, 0.5)",
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    color: "#fff",
                                  }}
                                >
                                  <CircularProgress />
                                  <Typography>Getting entity data...</Typography>
                                </Stack>
                              )}
                            </CardActionArea>
                          </Card>
                        </HtmlTooltip>
                      </Grid>
                    ))}
                </>
              )}
              {entitys?.entityType !== entityType.soleTrader ? (
                <>
                  {!entitys?.creditProfile?.publicReports && (
                    <Grid item md={4} sm={4}>
                      <HtmlTooltip
                        tooltipPadding={"20px"}
                        title={
                          !userDetails?.is_service_enable && (
                            <React.Fragment>
                              <Typography color="inherit" marginBottom="5px">
                                {creditCardModalInfo.mainTitle}
                              </Typography>
                              <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                {creditCardModalInfo.subTitle}
                              </Typography>
                              <Link to="/billing/creditCard">
                                <Button variant="outlined" size="small">
                                  {creditCardModalInfo.btnText}
                                </Button>
                              </Link>
                            </React.Fragment>
                          )
                        }
                      >
                        <Card
                          style={{ height: "100%" }}
                          onClick={() => {
                            if (!userDetails?.is_service_enable || gettingCreditProfile === entitys?._id) return;
                            dispatch(saveValue({ gettingCreditProfile: entitys?._id }));
                            dispatch(
                              getCommercialCreditFile({
                                entityId: entitys._id,
                                applicationId: application._id,
                              }),
                            ).then((res) => {
                              if (res.payload.data && Object.keys(res.payload?.data).length > 0) {
                                enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
                                  variant: "success",
                                  autoHideDuration: 5000,
                                });
                              } else {
                                enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
                                  variant: "error",
                                  autoHideDuration: 5000,
                                });
                              }
                            });
                          }}
                        >
                          <CardActionArea
                            // disabled
                            style={{
                              height: "100%",
                              display: "flex",
                              alignContent: "start",
                              cursor:
                                !userDetails?.is_service_enable || gettingCreditProfile === entitys?._id ? "auto" : "",
                              opacity:
                                !userDetails?.is_service_enable || gettingCreditProfile === entitys?._id ? "0.4" : "",
                              backgroundColor:
                                !userDetails?.is_service_enable || gettingCreditProfile === entitys?._id
                                  ? "#dfdfdf"
                                  : "",
                            }}
                          >
                            <CardContent>
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  alignItems: "center",
                                  // height: "40px",
                                  marginBottom: "10px",
                                }}
                              >
                                <img height="20px" src="/static/equifax-logo.png" alt="" />
                                <Typography fontWeight={600}>Get Company Credit Report</Typography>
                              </Stack>

                              <Typography fontSize={13}>
                                Retrieve a company credit report from Equifax for {entitys?.entityName}.
                              </Typography>
                              {/* <Typography fontWeight={600} style={{ marginTop: 10 }}>
                        (Coming soon)
                      </Typography> */}
                            </CardContent>
                            {gettingCreditProfile === entitys?._id && (
                              <Stack
                                spacing={2}
                                style={{
                                  background: "rgba(0,0,0, 0.5)",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                  color: "#fff",
                                }}
                              >
                                <CircularProgress />
                                <Typography>Getting equifax data...</Typography>
                              </Stack>
                            )}
                          </CardActionArea>
                        </Card>
                      </HtmlTooltip>
                    </Grid>
                  )}
                </>
              ) : null}
            </Grid>
          </Grid>
        )}

        {gettingEntityDetails && (
          <Grid style={{ margin: "0 0 40px" }}>
            <Grid>
              <Typography style={{ margin: "0 0 10px" }}>Getting entity details...</Typography>
            </Grid>
            <Grid>
              {handle.loadingGridFirst}
              {handle.loadingGridFirst}
              {handle.loadingGridSecond}
            </Grid>
          </Grid>
        )}
        {/* 
        <Grid container style={{ margin: "0 0 20px 0" }}>
          <Grid item lg={9} md={12} sm={12}>
            <Typography
              fontWeight={600}
              // variant="subtitle2"
              // sx={{ fontWeight: "bold" }}
              style={{ marginBottom: "5px", marginTop: "20px" }}
            >
              Add another entity
            </Typography>
            <AutoSearchHOC
              setBusinessSearchValue={setBusinessSearchValue}
              setEntityOptions={setEntityOptions}
              application={application?.entities?.length}
              loadingBusinessSearch={loadingBusinessSearch}
              entityOptions={entityOptions}
              updatedSearch={handle.entityDetailsFn}
              businessSearchValue={businessSearchValue}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
            {fieldsErrors && !entitys && (
              <Typography
                style={{
                  marginTop: "5px",
                  marginLeft: "14px",
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                }}
              >
                Please select entity
              </Typography>
            )}
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
});

export default Entity;
