import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Select, Box, Chip, MenuItem } from "@mui/material";
import { debounce } from "lodash";
import { format } from "date-fns";
import moment from "moment";
import {
  lenderSelector,
  setEditLenderPayload,
  updateCurrentlyEditingFee,
  updateCurrentLenderFees,
  updateCurrentLenderLoadings,
  updateCurrentLenderBrokerages,
} from "../../../../store/slices/lenderSlice";
import {
  AGE_OF_ASSETS_AT_END,
  ConditionFieldOptions,
  FUNCTIONS_DATE_USING_MONTHS,
  FUNCTIONS_DATE_USING_YEARS,
  FUNCTIONS_DATE_VALUES,
  FUNCTIONS_PARSE_TO_STRING,
  FUNCTIONS_WITH_ENUMS,
  criteriaFunctions,
  criteriaObjects,
  employmentTypes,
  livingArrangementsTypes,
  typeOfSaleList,
} from "../../../../constants";
import assets from "../../../../assetTypes.json";
import consumerAssetTypes from "../../../../utils/consumerAssetTypes";
import CRITERIA from "../../../../utils/criteria";
import { NumericFormatCustom } from "../../../../utils/currencyMaskFormat";

const CriteriaInput = ({
  criteria,
  criteriaType,
  isFieldDirty,
  lender,
  // editLender,
  editLenderLoading,
  // lenderDetail,
  index,
  feeIndex,
  loadingIndex,
  brokerageIndex,
  keyString,
  updateCriteria,
}) => {
  const dispatch = useDispatch();
  const {
    currentlyEditingFee,
    lender: lenderState,
    currentLenderFees,
    currentLenderLoadings,
    currentLenderBrokerages,
  } = useSelector(lenderSelector);

  const lenderId = lender?._id;

  const listName = FUNCTIONS_WITH_ENUMS.find((name) => name === criteriaType);

  const isComputedValueString = FUNCTIONS_PARSE_TO_STRING.some((prefix) => criteriaType?.includes(prefix));

  const isValueDate = FUNCTIONS_DATE_VALUES.some(
    (prefix) =>
      criteriaType?.includes(prefix) && !AGE_OF_ASSETS_AT_END.some((criteriaName) => criteriaName === criteriaType),
  );

  useEffect(() => {
    // Fix for criteria objects not saving properly
    if (criteriaObjects.includes(criteriaType)) {
      const updatedCriteria = CRITERIA[criteriaType];
      let criteriaArr = [];
      if (currentlyEditingFee.criteria.length > 0) {
        criteriaArr = currentlyEditingFee.criteria.map((c, i) => {
          const { _id, ...rest } = c;
          if (i === index) return updatedCriteria;
          else return rest;
        });
      } else {
        criteriaArr.push(updatedCriteria);
      }
      const { _id, ...rest } = currentlyEditingFee;

      const updatedLenderDetail = {
        ...rest,
        criteria: criteriaArr,
      };

      let newValue;

      if (keyString === "fees") {
        newValue = currentLenderFees.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === feeIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderFees(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "loading") {
        newValue = currentLenderLoadings.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === loadingIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderLoadings(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "brokerage") {
        newValue = currentLenderBrokerages.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === brokerageIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderBrokerages(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      dispatch(
        setEditLenderPayload({
          lenderId: lenderId ?? lenderState?._id,
          body: {
            ...Object.fromEntries(
              Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers"),
            ),
            [keyString]: newValue,
          },
        }),
      );
    }
  }, [criteriaType]);

  const initialEnumValues = () => {
    if (listName && !Array.isArray(criteria?.value)) return [criteria?.value];
    else if (listName && Array.isArray(criteria?.value)) return criteria?.value;
    else return [];
  };

  const isValueDateUsingMonths = FUNCTIONS_DATE_USING_MONTHS.some((prefix) => criteriaType?.includes(prefix));

  const isValueDateUsingYears = FUNCTIONS_DATE_USING_YEARS.some((prefix) => criteriaType?.includes(prefix));

  const isAgeOfAsset = FUNCTIONS_DATE_VALUES.some(
    (name) => criteriaType.includes(name) && !AGE_OF_ASSETS_AT_END.some((type) => type === criteriaType),
  );

  const valueDate = () => {
    if (isValueDateUsingMonths && !isAgeOfAsset) return moment(moment()).diff(criteria?.value, "M");
    else if (isValueDateUsingMonths && isAgeOfAsset) {
      if (criteriaType.includes("GreaterThan")) {
        const matches = (criteria?.label ?? "").match(/>(\d+)\s*mths/);
        return matches && matches[1] ? parseInt(matches[1]) : 0;
      } else if (criteriaType.includes("LessThan")) {
        const matches = (criteria?.label ?? "").match(/<(\d+)\s*mths/);
        return matches && matches[1] ? parseInt(matches[1]) : 0;
      }
    } else if (isValueDateUsingYears) return moment(moment()).diff(criteria?.value, "years");
  };

  const valueDateMinMax = () => {
    let min;
    let max;
    if (isValueDateUsingMonths && !isAgeOfAsset) {
      min = moment(moment()).diff(criteria?.value?.max, "M");
      max = moment(moment()).diff(criteria?.value?.min, "M");
    } else if (isValueDateUsingMonths && isAgeOfAsset) {
      if (criteriaType.includes("Between")) {
        const matches = (criteria?.label ?? "").match(/(\d+)\s*-\s*(\d+)\s*mths/);
        if (matches && matches[1] && matches[2]) {
          min = parseInt(matches[1]);
          max = parseInt(matches[2]);
        } else {
          min = 0;
          max = 0;
        }
      }
    } else if (isValueDateUsingYears) {
      min = moment(moment()).diff(criteria?.value?.max, "years");
      max = moment(moment()).diff(criteria?.value?.min, "years");
    }
    return {
      min,
      max,
    };
  };

  const [value, setValue] = useState((!isValueDate ? criteria?.value : valueDate()) ?? null);
  const [enumValues, setEnumValues] = useState(initialEnumValues());
  const [minValue, setMinValue] = useState(isValueDate ? valueDateMinMax().min : criteria?.value?.min ?? null);
  const [maxValue, setMaxValue] = useState(isValueDate ? valueDateMinMax().max : criteria?.value?.max ?? null);
  const [isMinDisabled, setIsMinDisabled] = useState(false);
  const [isMaxDisabled, setIsMaxDisabled] = useState(false);

  useEffect(() => {
    if (!isValueDate) {
      setValue(criteria?.value ?? null);
    } else {
      setValue(valueDate());
    }
    setEnumValues(initialEnumValues());
    setMinValue(isValueDate ? valueDateMinMax().min : criteria?.value?.min ?? null);
    setMaxValue(isValueDate ? valueDateMinMax().max : criteria?.value?.max ?? null);
  }, [criteria]);

  let listOptions = []; // for list enums on select component

  switch (
    listName // for list enums on select component
  ) {
    case "typeOfSale":
      listOptions = Object.values(typeOfSaleList).map((value) => value);
      break;
    case "livingArrangements":
      listOptions = Object.values(livingArrangementsTypes).map((value) => value);
      break;
    case "employmentType":
      listOptions = Object.values(employmentTypes).map((value) => value);
      break;
    case "assetType":
      if (lenderState?.type === "commercial") {
        listOptions = assets.assetTypes.map((asset) => asset.label);
      } else {
        // For consumer and personal
        listOptions = consumerAssetTypes.map((asset) => asset.label);
      }
      break;
    case "assetCondition":
      listOptions = ConditionFieldOptions;
      break;
    default:
      break;
  }

  const handleListChange = (event) => {
    // for list enums on select component
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valuesArr = typeof value === "string" ? value.split(",") : value;
    setEnumValues(valuesArr);
    let updatedCriteria = CRITERIA[criteriaType](valuesArr);

    if (criteria?._id) {
      updatedCriteria._id = criteria._id;
    }

    const criteriaArr = currentlyEditingFee.criteria.map((c, i) => {
      if (i === index) return updatedCriteria;
      else return c;
    });

    const { _id, ...rest } = currentlyEditingFee;
    const updatedLenderDetail = {
      ...rest,
      criteria: criteriaArr,
    };

    let newValue;

    if (keyString === "fees") {
      newValue = currentLenderFees.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === feeIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });

      dispatch(updateCurrentLenderFees(newValue));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
    }

    if (keyString === "loading") {
      newValue = currentLenderLoadings.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === loadingIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });

      dispatch(updateCurrentLenderLoadings(newValue));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
    }

    if (keyString === "brokerage") {
      newValue = currentLenderBrokerages.map((info, i) => {
        const { _id: infoId, ...rest } = info;

        if (i === brokerageIndex) {
          return updatedLenderDetail;
        } else {
          return rest;
        }
      });

      dispatch(updateCurrentLenderBrokerages(newValue));
      dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
    }

    dispatch(
      setEditLenderPayload({
        lenderId: lenderId ?? lenderState?._id,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          [keyString]: newValue,
        },
      }),
    );
    // debounce(
    //   () =>
    //     editLender({
    //       lenderId,
    //       body: {
    //         ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //         [keyString]: newValue,
    //       },
    //     }),
    //   750,
    // )();
  };

  useEffect(() => {
    if (isMinDisabled && !editLenderLoading) {
      debounce(() => setIsMinDisabled(false), 3000)();
    }
  }, [isMinDisabled, editLenderLoading]);

  useEffect(() => {
    if (isMaxDisabled && !editLenderLoading) {
      debounce(() => setIsMaxDisabled(false), 3000)();
    }
  }, [isMaxDisabled, editLenderLoading]);

  const handleMinValue = (e) => {
    if (maxValue !== "") {
      const minValue = parseFloat(e.target.value.includes("$") ? e.target.value.replace(/[\$,]/g, "") : e.target.value);
      let updatedCriteria = CRITERIA[criteriaType](minValue, parseFloat(maxValue));

      if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }

      const criteriaArr = currentlyEditingFee.criteria.map((c, i) => {
        if (i === index) return updatedCriteria;
        else return c;
      });

      const { _id, ...rest } = currentlyEditingFee;
      const updatedLenderDetail = {
        ...rest,
        criteria: criteriaArr,
      };

      let newValue;

      if (keyString === "fees") {
        newValue = currentLenderFees.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === feeIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderFees(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "loading") {
        newValue = currentLenderLoadings.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === loadingIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderLoadings(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "brokerage") {
        newValue = currentLenderBrokerages.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === brokerageIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderBrokerages(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      dispatch(
        setEditLenderPayload({
          lenderId: lenderId ?? lenderState?._id,
          body: {
            ...Object.fromEntries(
              Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers"),
            ),
            [keyString]: newValue,
          },
        }),
      );
      // debounce(
      //   () =>
      //     editLender({
      //       lenderId,
      //       body: {
      //         ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
      //         [keyString]: newValue,
      //       },
      //     }),
      //   750,
      // )();
    }
  };

  const handleMaxValue = (e) => {
    if (minValue !== "") {
      const maxValue = parseFloat(e.target.value.includes("$") ? e.target.value.replace(/[\$,]/g, "") : e.target.value);
      let updatedCriteria = CRITERIA[criteriaType](parseFloat(minValue), maxValue);

      if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }

      const criteriaArr = currentlyEditingFee.criteria.map((c, i) => {
        if (i === index) return updatedCriteria;
        else return c;
      });
      const { _id, ...rest } = currentlyEditingFee;
      const updatedLenderDetail = {
        ...rest,
        criteria: criteriaArr,
      };

      let newValue;

      if (keyString === "fees") {
        newValue = currentLenderFees.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === feeIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderFees(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "loading") {
        newValue = currentLenderLoadings.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === loadingIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderLoadings(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      if (keyString === "brokerage") {
        newValue = currentLenderBrokerages.map((info, i) => {
          const { _id: infoId, ...rest } = info;

          if (i === brokerageIndex) {
            return updatedLenderDetail;
          } else {
            return rest;
          }
        });

        dispatch(updateCurrentLenderBrokerages(newValue));
        dispatch(updateCurrentlyEditingFee(updatedLenderDetail));
      }

      dispatch(
        setEditLenderPayload({
          lenderId: lenderId ?? lenderState?._id,
          body: {
            ...Object.fromEntries(
              Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers"),
            ),
            [keyString]: newValue,
          },
        }),
      );

      // debounce(
      //   () =>
      //     editLender({
      //       lenderId,
      //       body: {
      //         ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
      //         [keyString]: newValue,
      //       },
      //     }),
      //   750,
      // )();
    }
  };

  const handleValue = (e) => {
    if (e.target.value !== "") {
      const updatedValue = isNaN(e.target.value)
        ? e.target.value
        : parseFloat(e.target.value.includes("$") ? e.target.value.replace(/[\$,]/g, "") : e.target.value);

      let updatedCriteria = CRITERIA[criteriaType](updatedValue);
      if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }

      updateCriteria(updatedCriteria);
    }
  };

  // const minDebounced = useCallback(debounce(handleMinValue, 750), [maxValue]);
  // const maxDebounced = useCallback(debounce(handleMaxValue, 750), [minValue]);

  const getComputedDate = useMemo(() => {
    if (criteriaType) {
      if (criteriaObjects.includes(criteriaType)) return CRITERIA[criteriaType].value;
      else if (CRITERIA[criteriaType]) {
        if (CRITERIA[criteriaType].length === 1) return CRITERIA[criteriaType](value === "" ? 0 : value).value;
        else {
          const computedDate = () => {
            let min;
            let max;
            if (minValue === "" || 0 || !minValue) min = criteria?.value?.min;
            else min = CRITERIA[criteriaType](minValue, maxValue).value.min;
            if (maxValue === "" || 0 || !maxValue) max = criteria?.value?.max;
            else max = CRITERIA[criteriaType](minValue, maxValue).value.max;
            return {
              min,
              max,
            };
          };
          return computedDate();
        }
      }
      return null;
    }
    return null;
  }, [value, minValue, maxValue, criteriaType]);

  useEffect(() => {
    if (isFieldDirty) {
      setValue("");
      setMaxValue("");
      setMinValue("");
      setEnumValues([]);
    }
  }, [isFieldDirty, criteriaType]);

  if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 2) {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            disabled={isMinDisabled}
            {...(!isComputedValueString && {
              InputProps: {
                inputComponent: NumericFormatCustom,
              },
            })}
            label="Minimum Value"
            variant="filled"
            type="text"
            name="minVal"
            value={minValue ?? ""}
            onChange={(e) => {
              const minValue = isComputedValueString && isNaN(e.target.value) ? "" : e.target.value;
              setMinValue(minValue);
              handleMinValue(e);
            }}
            // onBlur={(e) => minDebounced(e, 1000)}
            // onBlur={(e) => {
            //   handleMinValue(e);
            // }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            disabled={isMaxDisabled}
            label="Maximum Value"
            variant="filled"
            type="text"
            name="maxVal"
            {...(!isComputedValueString && {
              InputProps: {
                inputComponent: NumericFormatCustom,
              },
            })}
            value={maxValue ?? ""}
            onChange={(e) => {
              const maxValue = isComputedValueString && isNaN(e.target.value) ? "" : e.target.value;
              setMaxValue(maxValue);
            }}
            // onBlur={(e) => maxDebounced(e, 1000)}
            onBlur={(e) => {
              handleMaxValue(e);
            }}
          />
        </Grid>
        {isValueDate && (
          <Grid container item xs={12} columnSpacing={"16px"}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Computed Value Min"
                variant="filled"
                type="text"
                value={getComputedDate?.min ?? format(new Date(), "yyyy-MM-dd")}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Computed Value Max"
                variant="filled"
                type="text"
                value={getComputedDate?.max ?? format(new Date(), "yyyy-MM-dd")}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  } else if (FUNCTIONS_WITH_ENUMS.includes(criteriaType ?? "")) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Select
            multiple
            fullWidth
            size="small"
            value={enumValues}
            onChange={handleListChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected ?? []).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {listOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </React.Fragment>
    );
  } else if (CRITERIA[criteriaType]) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          {criteriaObjects.includes(criteriaType) ? (
            <TextField
              fullWidth
              size="small"
              label="Value"
              variant="filled"
              type="text"
              name="value"
              sx={{ display: !getComputedDate ? "none" : "block" }}
              value={getComputedDate}
              disabled
            />
          ) : (
            <TextField
              fullWidth
              size="small"
              label="Value"
              variant="filled"
              type="text"
              {...(!isComputedValueString &&
                typeof value !== "object" &&
                !(value instanceof Object) && {
                  InputProps: {
                    inputComponent: NumericFormatCustom,
                  },
                })}
              name="value"
              value={value !== null ? value.toString() : ""}
              onChange={(e) => {
                const regexWithSpaces = /^[a-zA-Z0-9. ]*$/;
                const value = isComputedValueString && !regexWithSpaces.test(e.target.value) ? "" : e.target.value;
                setValue(value);
                handleValue(e);
                // debounce(() => handleValue(e), 750)();
              }}
            />
          )}
        </Grid>
        {isValueDate && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Computed Value"
              variant="filled"
              type="text"
              value={getComputedDate ?? format(new Date(), "yyyy-MM-dd")}
              disabled
            />
          </Grid>
        )}
      </React.Fragment>
    );
  } else return null;
};

export default CriteriaInput;
