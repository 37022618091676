import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
import { debounce } from "lodash";
import {
  lenderSelector,
  setCurrentLender,
  setEditLenderPayload,
  updateCurrentlyEditingFee,
  updateCurrentLenderFees,
} from "../../../../store/slices/lenderSlice";
import Fee from "../Card/Fee";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";

const FeeSection = React.memo(() => {
  const dispatch = useDispatch();
  const [editLender, { data: editLenderData, isLoading: editLenderLoading }] = useEditLenderMutation();

  const { lender: lenderState, currentLenderFees } = useSelector(lenderSelector);
  const lenderId = lenderState?._id;

  const [newFeeData, setNewFeeData] = useState(null);
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);

  useEffect(() => {
    if (editLenderData && !editLenderLoading) debounce(() => dispatch(setCurrentLender(editLenderData)), 500);
  }, [editLenderLoading]);

  const handleAddFee = () => {
    const newFee = {
      capitalised: false,
      criteria: [],
      frequency: "upfront",
      name: "Fee title",
      value: 0,
    };
    const fees = [
      ...lenderState.fees.map((fee) => {
        const { _id, ...rest } = fee;
        return rest;
      }),
      newFee,
    ];

    // dispatch(
    //   setCurrentLender({
    //     _id: lenderId,
    //     ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     fees,
    //   }),
    // );

    dispatch(updateCurrentLenderFees(fees));
    dispatch(updateCurrentlyEditingFee(newFee));

    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          fees,
        },
      }),
    );
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     fees,
    //   },
    // });

    setNewFeeData(newFee);
    setAddDialogIsOpen(true);
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Implemented Fees</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        {lenderState.fees.length > 0 && !addDialogIsOpen && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            xs={9}
            direction="row"
            style={{ margin: "0px 0px 7px 0px" }}
            rowGap={"16px"}
          >
            {lenderState.fees.map((fee, index) => (
              <Fee
                data={fee}
                key={index}
                index={index}
                editLender={editLender}
                lender={lenderState}
                editLenderLoading={editLenderLoading}
              />
            ))}
          </Grid>
        )}
        {newFeeData && addDialogIsOpen && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            xs={9}
            direction="row"
            style={{ margin: "0px 0px 7px 0px" }}
            rowGap={"16px"}
          >
            <Fee
              data={newFeeData}
              key={currentLenderFees.length === 0 ? 0 : currentLenderFees.length - 1}
              index={currentLenderFees.length === 0 ? 0 : currentLenderFees.length - 1}
              editLender={editLender}
              lender={lenderState}
              editLenderLoading={editLenderLoading}
              addDialogIsOpen={addDialogIsOpen}
              setAddDialogIsOpen={setAddDialogIsOpen}
            />
          </Grid>
        )}
        <Button
          sx={{ width: "200px", ml: "8px" }}
          xs={12}
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddFee}
        >
          ADD FEES
        </Button>
      </Grid>
    </Grid>
  );
});

export default FeeSection;
