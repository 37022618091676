import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DeleteProductDialog = ({
  isLoading = false,
  isDeleteOpen,
  setIsDeleteOpen,
  handleRemoveProduct,
  handleRemoveBulkProduct,
  product,
  isBulkDelete
}) => {
  const handleClose = () => setIsDeleteOpen(false);

  return (
    <Dialog
      open={isDeleteOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Remove {isBulkDelete ? "bulk " : ""}Product</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Do you wish to remove the {isBulkDelete ? "bulk" : "selected"} product?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          variant={"contained"}
          color="error"
          onClick={() => {
            if (isBulkDelete) {
              handleRemoveBulkProduct()
            } else {
              handleRemoveProduct(product)
            }
          }}
          disabled={isLoading}
        >
          {isLoading ? 'DELETING...' : 'DELETE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductDialog;
