import React from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { isEqualWith } from "lodash";
import { updateCurrentlyEditingCommonCriteria } from "../../../store/slices/lenderSlice";
import { criteriaFunctions, criteriaObjects } from "../../../constants";
import CRITERIA from "../../../utils/criteria";

const AddCommonCriteriaInput = ({
  criterion,
  setIsDirty,
  getCriteriaName,
  commonCriteria,
  setCommonCriteria,
  keyId,
  criteriaIndex,
}) => {
  const dispatch = useDispatch();
  const comparator = (obj1, obj2) =>
    obj1.attribute === obj2.attribute && obj1.condition === obj2.condition && obj1.object === obj2.object;

  const handleCriteriaType = (e, newValue) => {
    const criteriaType = newValue;

    if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 2) {
      const { attribute, object, condition } = CRITERIA[criteriaType](0, 0);
      const newCommonCriteria = commonCriteria.map((c, index) => {
        // if (index === criteriaIndex)
        if (c.id === keyId){
          return {
            ...c,
            attribute,
            object,
            condition,
          };
        }
        return c;
      });
      const isCommonCriteriaExist = commonCriteria.some((c) =>
        isEqualWith(c, { attribute, object, condition }, comparator),
      );

      if (!isCommonCriteriaExist) {
        setCommonCriteria(newCommonCriteria);
        dispatch(updateCurrentlyEditingCommonCriteria(newCommonCriteria));
      } else {
        setCommonCriteria(commonCriteria.filter((c) => c.attribute !== ""));
        dispatch(updateCurrentlyEditingCommonCriteria(commonCriteria.filter((c) => c.attribute !== "")));
      }
    } else if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 1) {
      const { attribute, object, condition } = CRITERIA[criteriaType](0);
      const newCommonCriteria = commonCriteria.map((c, index) => {
          if (c.id === keyId){
          return {
            ...c,
            attribute,
            object,
            condition,
          };
        }
        return c;
      });
      const isCommonCriteriaExist = commonCriteria.some((c) =>
        isEqualWith(c, { attribute, object, condition }, comparator),
      );

      if (!isCommonCriteriaExist) {
        setCommonCriteria(newCommonCriteria);
        dispatch(updateCurrentlyEditingCommonCriteria(newCommonCriteria));
      } else setCommonCriteria(commonCriteria.filter((c) => c.attribute !== ""));
    } else if (criteriaObjects.includes(criteriaType)) {
      const { label, attribute, object, condition, value } = CRITERIA[criteriaType];
      const newCommonCriteria = commonCriteria.map((c, index) => {
        const { valueMinMax, ...rest } = c;
          if (c.id === keyId){
          return {
            ...rest,
            label,
            attribute,
            object,
            condition,
            value,
          };
        }
        return c;
      });
      const isCommonCriteriaExist = commonCriteria.some((c) =>
        isEqualWith(c, { attribute, object, condition }, comparator),
      );
      if (!isCommonCriteriaExist) {
        setCommonCriteria(newCommonCriteria);
        dispatch(updateCurrentlyEditingCommonCriteria(newCommonCriteria));
        setIsDirty(true);
      } else {
        setCommonCriteria(commonCriteria.filter((c) => c.attribute !== ""));
        dispatch(updateCurrentlyEditingCommonCriteria(commonCriteria.filter((c) => c.attribute !== "")));
      }
    }
  };

  const handleInputChange = (e, newValue) => handleCriteriaType(e, newValue);

  return getCriteriaName(criterion) ? (
    <Typography>{getCriteriaName(criterion)}</Typography>
  ) : (
    <Autocomplete
      options={Object.keys(CRITERIA).map((key) => key)}
      getOptionLabel={(option) => option || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Criteria Type"
          size="small"
          variant="outlined"
          margin="dense"
          fullWidth
          sx={{ height: "100%" }}
        />
      )}
      onInputChange={handleInputChange}
    />
  );
};

export default AddCommonCriteriaInput;
