import React from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, prefix, isAllowedFlag, maxDigits = 7, maxPlaces = 2, decimalScale = 2, ...other } = props;

  const isAllowedNumberInput = (values) => {
    const { floatValue } = values;
    // Allow empty values
    if (floatValue === undefined) return true;
    // Ensure the number does not exceed ${maxDigits} before the decimal point and decimal ${maxPlaces}
    const [integer, fraction] = floatValue.toString().split(".");
    return (!integer || integer.length <= maxDigits) && (!fraction || fraction.length <= maxPlaces);
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      decimalScale={decimalScale}
      fixedDecimalScale
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={prefix != null ? prefix : "$"}
      isAllowed={isAllowedFlag ? isAllowedNumberInput : null}
    />
  );
});

export const currencyMaskedValue = (value) => {
  const val = value?.replace(/[$,]/g, "");
  // return `${val}.00`;
  return val;
};
