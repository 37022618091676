import React, { useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, MenuItem, Select, TextField } from "@mui/material";
import { format } from "date-fns";
import {
  AGE_OF_ASSETS_AT_END,
  ConditionFieldOptions,
  FUNCTIONS_DATE_VALUES,
  FUNCTIONS_PARSE_TO_STRING,
  FUNCTIONS_WITH_ENUMS,
  criteriaFunctions,
  criteriaObjects,
  employmentTypes,
  livingArrangementsTypes,
  typeOfSaleList,
} from "../../../constants";
import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";
import assets from "../../../assetTypes.json";
import consumerAssetTypes from "../../../utils/consumerAssetTypes";
import { lenderSelector, updateCurrentlyEditingCommonCriteria } from "../../../store/slices/lenderSlice";
import CRITERIA from "../../../utils/criteria";

const CommonCriteriaInput = (props) => {
  const {
    originalCommonCriteria,
    // commonCriteria,
    keyId,
    criteriaIndex,
    getCriteriaName,
    // setOriginalCommonCriteria,
    setCommonCriteria,
    setIsDirty,
    newlyAddedCriteria,
    setNewlyAddedCriteria,
    //updateCriteriaInput
  } = props;
  // const getMinValue = () => {
  //   if (typeof commonCriteria[criteriaIndex].value === "object") return commonCriteria[criteriaIndex].value.min;
  //   else return 0;
  // };
  // const getMaxValue = () => {
  //   if (typeof commonCriteria[criteriaIndex].value === "object") return commonCriteria[criteriaIndex].value.max;
  //   else return 0;
  // };

  const dispatch = useDispatch();
  const {
    lender: lenderState,
    currentlyEditingCommonCriteria,
    originalCommonCriteria: originalCommonCriteriaState,
  } = useSelector(lenderSelector);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const minValueRef = useRef(null);
  const maxValueRef = useRef(null);

  const criteriaType = getCriteriaName(currentlyEditingCommonCriteria?.[criteriaIndex] ?? {});

  const getComputedDate = useMemo(() => {
    if (criteriaType) {
      if (criteriaObjects.includes(criteriaType)) return CRITERIA[criteriaType].value;
      else if (CRITERIA[criteriaType]) {
        if (CRITERIA[criteriaType].length === 1) return CRITERIA[criteriaType](value === "" ? 0 : value).value;
        else {
          const computedDate = () => {
            let min;
            let max;
            if (minValue === "" || 0 || !minValue) min = 0;
            else min = CRITERIA[criteriaType](minValue, maxValue).value.min;
            if (maxValue === "" || 0 || !maxValue) max = 0;
            else max = CRITERIA[criteriaType](minValue, maxValue).value.max;
            return {
              min,
              max,
            };
          };
          return computedDate();
        }
      }
      return null;
    }
    return null;
  }, [value, minValue, maxValue, criteriaType]);

  // if (!commonCriteria || !commonCriteria[criteriaIndex]) return null;

  const isComputedValueString = FUNCTIONS_PARSE_TO_STRING.some((prefix) => criteriaType?.includes(prefix));

  const isValueDate = FUNCTIONS_DATE_VALUES.some(
    (prefix) =>
      criteriaType?.includes(prefix) && !AGE_OF_ASSETS_AT_END.some((criteriaName) => criteriaName === criteriaType),
  );

  const handleMinMaxValues = (e) => {
    const currentCriteriaIsNew = originalCommonCriteria[criteriaIndex]?.new ?? false;

    const newValue = isComputedValueString && isNaN(e.target.value) ? "" : e.target.value;
    let updatedCriteria;
    if (e.target.name === "min") {
      updatedCriteria = (currentlyEditingCommonCriteria ?? []).map((c, index) => {
        const isValueEmpty = !newValue && currentCriteriaIsNew;
        // if (index === criteriaIndex) {
        if (c.id === keyId) {
          let computedValue = CRITERIA[criteriaType](parseFloat(newValue), maxValue);

          if (c.id) {
            computedValue.id = c.id;
          }

          if (isValueEmpty) {
            computedValue.blank = true;
          }
          if (currentCriteriaIsNew) {
            computedValue.new = true;
          }
          return computedValue;
        }
        return c;
      });
      setMinValue(isNaN(parseFloat(newValue)) ? null : parseFloat(newValue));
      minValueRef.current = isNaN(parseFloat(newValue)) ? null : parseFloat(newValue);
      // updateCriteriaInput(criteriaIndex, "minValue", parseFloat(newValue));
    } else {
      updatedCriteria = (currentlyEditingCommonCriteria ?? []).map((c, index) => {
        const isValueEmpty = !newValue && currentCriteriaIsNew;
        if (c.id === keyId) {
          let computedValue = CRITERIA[criteriaType](minValue, parseFloat(newValue));

          if (c.id) {
            computedValue.id = c.id;
          }

          if (isValueEmpty) {
            computedValue.blank = true;
          }
          if (currentCriteriaIsNew) {
            computedValue.new = true;
          }
          return computedValue;
        }
        return c;
      });
      setMaxValue(isNaN(parseFloat(newValue)) ? null : parseFloat(newValue));
      maxValueRef.current = isNaN(parseFloat(newValue)) ? null : parseFloat(newValue);
      // updateCriteriaInput(criteriaIndex, "maxValue", parseFloat(newValue));
    }
    setCommonCriteria(updatedCriteria);
    dispatch(updateCurrentlyEditingCommonCriteria(updatedCriteria));
    setNewlyAddedCriteria(updatedCriteria.filter((criteria) => criteria.new === true));

    if ((!minValueRef.current || !maxValueRef.current) && !currentCriteriaIsNew) {
      setCommonCriteria(originalCommonCriteria);
      dispatch(updateCurrentlyEditingCommonCriteria([...originalCommonCriteriaState, ...newlyAddedCriteria]));
    }
    setIsDirty(true);
  };

  const handleValue = (e) => {
    // const isThereNewCriteria = originalCommonCriteria.some((criteria) => criteria?.new === true);
    const currentCriteriaIsNew = originalCommonCriteria[criteriaIndex]?.new ?? false;

    const regexWithSpaces = /^[a-zA-Z0-9. ]*$/;
    let newValue;
    if (!regexWithSpaces.test(e.target.value)) return;
    if (isComputedValueString && isNaN(e.target.value)) return;
    else if (!isComputedValueString) newValue = e.target.value;
    else if (!isNaN(e.target.value) && e.target.value !== "") newValue = parseFloat(e.target.value);
    else newValue = e.target.value;
    setValue(newValue);
    // updateCriteriaInput(criteriaIndex, "value", newValue);

    if (newValue || currentCriteriaIsNew) {
      const updatedCriteria = (currentlyEditingCommonCriteria ?? []).map((c, index) => {
        const isValueEmpty = !newValue && currentCriteriaIsNew;
        if (c.id === keyId) {
          let computedValue = CRITERIA[criteriaType](isComputedValueString ? newValue : parseFloat(newValue));

          if (c.id) {
            computedValue.id = c.id;
          }

          if (isValueEmpty) {
            computedValue.blank = true;
          }
          if (currentCriteriaIsNew) {
            computedValue.new = true;
          }
          return computedValue;
        }
        return c;
      });
      setCommonCriteria(updatedCriteria);
      dispatch(updateCurrentlyEditingCommonCriteria(updatedCriteria));
      setNewlyAddedCriteria(updatedCriteria.filter((criteria) => criteria.new === true));
    }

    if (!newValue && !currentCriteriaIsNew) {
      setCommonCriteria(originalCommonCriteria);
      dispatch(updateCurrentlyEditingCommonCriteria([...originalCommonCriteriaState, ...newlyAddedCriteria]));
    }

    setIsDirty(true);
  };

  if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 2) {
    return (
      <Grid container item xs={12} columnSpacing={"16px"}>
        <Grid container item xs={6}>
          <TextField
            fullWidth
            label="Min"
            size="small"
            variant="outlined"
            margin="dense"
            value={minValue}
            onChange={handleMinMaxValues}
            type="text"
            {...(!isComputedValueString && {
              InputProps: {
                inputComponent: NumericFormatCustom,
              },
            })}
            name="min"
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            fullWidth
            label="Max"
            size="small"
            variant="outlined"
            margin="dense"
            value={maxValue}
            onChange={handleMinMaxValues}
            type="text"
            {...(!isComputedValueString && {
              InputProps: {
                inputComponent: NumericFormatCustom,
              },
            })}
            name="max"
          />
        </Grid>
        {isValueDate && (
          <Grid container item xs={12} columnSpacing={"16px"}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Computed Value Min"
                variant="outlined"
                margin="dense"
                type="text"
                value={getComputedDate?.min ?? format(new Date(), "yyyy-MM-dd")}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Computed Value Max"
                variant="outlined"
                margin="dense"
                type="text"
                value={getComputedDate?.max ?? format(new Date(), "yyyy-MM-dd")}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  } else if (FUNCTIONS_WITH_ENUMS.includes(criteriaType ?? "")) {
    const listName = FUNCTIONS_WITH_ENUMS.find(
      (name, index) => name === getCriteriaName(currentlyEditingCommonCriteria?.[criteriaIndex]),
    );
    let listOptions = [];
    const handleListChange = (event) => {
      const currentCriteriaIsNew = originalCommonCriteria[criteriaIndex]?.new ?? false;

      const {
        target: { value },
      } = event;
      const newValue = typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(newValue);
      // updateCriteriaInput(criteriaIndex, "selectedOptions", newValue);
      const updatedCriteria = (currentlyEditingCommonCriteria ?? []).map((c, index) => {
        const isValueEmpty = newValue.length === 0 && currentCriteriaIsNew;
        if (c.id === keyId) {
          let computedValue = CRITERIA[criteriaType](newValue);

          if (c.id) {
            computedValue.id = c.id;
          }

          if (isValueEmpty) {
            computedValue.blank = true;
          }
          if (currentCriteriaIsNew) {
            computedValue.new = true;
          }
          return computedValue;
        }
        return c;
      });
      setCommonCriteria(updatedCriteria);
      dispatch(updateCurrentlyEditingCommonCriteria(updatedCriteria));
      setNewlyAddedCriteria(updatedCriteria.filter((criteria) => criteria.new === true));

      if (newValue.length === 0 && !currentCriteriaIsNew) {
        setCommonCriteria(originalCommonCriteria);
        dispatch(updateCurrentlyEditingCommonCriteria([...originalCommonCriteriaState, ...newlyAddedCriteria]));
      }
      setIsDirty(true);
    };
    switch (listName) {
      case "typeOfSale":
        listOptions = Object.values(typeOfSaleList).map((value) => value);
        break;
      case "livingArrangements":
        listOptions = Object.values(livingArrangementsTypes).map((value) => value);
        break;
      case "employmentType":
        listOptions = Object.values(employmentTypes).map((value) => value);
        break;
      case "assetType":
        if (lenderState?.type === "commercial") {
          listOptions = assets.assetTypes.map((asset) => asset.label);
        } else {
          // For consumer and personal
          listOptions = consumerAssetTypes.map((asset) => asset.label);
        }

        break;
      case "assetCondition":
        listOptions = ConditionFieldOptions;
        break;
      default:
        break;
    }

    return (
      <Select
        multiple
        fullWidth
        value={selectedOptions}
        size="small"
        variant="outlined"
        onChange={handleListChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {listOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  } else if (CRITERIA[criteriaType]) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          {criteriaObjects.includes(criteriaType) ? (
            <TextField
              fullWidth
              size="small"
              label="Value"
              variant="outlined"
              margin="dense"
              type="text"
              name="value"
              sx={{ display: !getComputedDate ? "none" : "block" }}
              value={getComputedDate}
              disabled
            />
          ) : (
            <TextField
              fullWidth
              size="small"
              label="Value"
              variant="outlined"
              margin="dense"
              type="text"
              {...(!isComputedValueString &&
                typeof value !== "object" &&
                !(value instanceof Object) && {
                  InputProps: {
                    inputComponent: NumericFormatCustom,
                  },
                })}
              value={value}
              name="value"
              onChange={handleValue}
            />
          )}
        </Grid>
        {isValueDate && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Computed Value"
              variant="outlined"
              margin="dense"
              type="text"
              value={getComputedDate ?? format(new Date(), "yyyy-MM-dd")}
              disabled
            />
          </Grid>
        )}
      </React.Fragment>
    );
  } else return null;
};

export default CommonCriteriaInput;
