import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import { saveValue, sendBankStatementsLink, userDetailsSelector } from "../../store/slices/applicationFormSlice";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE, creditCardModalInfo } from "../../constants";
import { userSelector } from "../../store/slices/userSlice";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import { HtmlTooltip } from "../ProductSelector/Product";

const CustomerFinancialStatement = React.memo(({ customer, applicationId }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { bankStatementsRequest } = useSelector(userDetailsSelector);
  const bankStatementsLoading = "";
  // const [delay, setDelay] = useState(null);
  const [bankStatementDays, setBankStatementDays] = useState("90");

  // const [buttonColorText, setButtonColorText] = useState({
  //   buttonBackground: "primary",
  //   buttonText: "Send Bank Statements Link",
  // });

  const handle = {
    setStatementsDays: (day) => {
      setBankStatementDays(day);
    },
    handleSendBankStatementsLink: (customerId, applicationId, mobile, bankStatementDays) => {
      dispatch(saveValue({ bankStatementsLoading: true }));
      dispatch(
        sendBankStatementsLink({
          customerId,
          applicationId,
          bankStatementDays,
          mobile,
        }),
      ).then((res) => {
        if (res) {
          enqueueSnackbar(SUCCESS_MESSAGE.bankStatements, {
            variant: "success",
            autoHideDuration: 5000,
          });

          // handle.bankStatementsButtonSuccessStyle();
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    // bankStatementsButtonSuccessStyle: () => {
    //   setButtonColorText({
    //     buttonBackground: "success",
    //     buttonText: "link sent",
    //   });
    //   setTimeout(() => {
    //     setButtonColorText({
    //       buttonBackground: "secondary",
    //       buttonText: "Send bank statements link",
    //     });
    //   }, 6000);
    // },
  };

  return (
    <React.Fragment>
      <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
        Bank Statements
      </Typography>

      {customer?.bankStatements && (
        <>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Bank accounts summary (current balance)
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }} spacing={2}>
            {customer?.bankStatements &&
              customer?.bankStatements?.bankAccounts?.map((bank, i) => (
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{bank.bankName}</Typography>
                  {bank.bankAccounts.map((account) => (
                    <Typography variant="body2">
                      <strong>{account.accountName}</strong>: {account.currentBalance}
                    </Typography>
                  ))}
                </Grid>
              ))}
          </Grid>
        </>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Credits
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i < 3)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Responsible lending flags
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 2 && i < 7)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Liabilities
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 6 && i < 10)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Debits
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 9 && metric.value * 1 !== 0)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      <Stack
        spacing={1}
        direction="row"
        style={{ margin: customer?.documents.length < 0 ? "0 0 20px" : "0" }}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        {customer?.documents?.length > 0 &&
          customer?.documents
            ?.filter((doc) => ["Bank statements"].includes(doc.name))
            .map((doc) => <Document document={doc} customer={customer} name="financialStatement" />)}
      </Stack>
      <Grid item sm={6} marginTop={"20px"} marginBottom={"20px"}>
        <Typography variant="subtitle2">Bank statements days</Typography>
        <RadioButtonGroup
          required
          name={"statementsDays"}
          value={bankStatementDays}
          handleValue={(value) => {
            handle.setStatementsDays(value);
          }}
          optionStyle={{
            width: "50%",
            padding: "8px",
            minHeight: "20px",
          }}
          options={[
            { icon: "90", label: "90" },
            { icon: "180", label: "180" },
            { icon: "270", label: "270" },
            { icon: "365", label: "365" },
          ]}
        />
      </Grid>

      <Grid item sm={6}>
        <HtmlTooltip
          tooltipPadding={"20px"}
          title={
            !userDetails?.is_service_enable && (
              <React.Fragment>
                <Typography color="inherit" marginBottom="5px">
                  {creditCardModalInfo.mainTitle}
                </Typography>
                <Typography color="inherit" fontSize="14px" marginBottom="10px">
                  {creditCardModalInfo.subTitle}
                </Typography>
                <Link to="/billing/creditCard">
                  <Button variant="outlined" size="small">
                    {creditCardModalInfo.btnText}
                  </Button>
                </Link>
              </React.Fragment>
            )
          }
        >
          <Card>
            <CardActionArea
              disableRipple={!customer?.phoneNumbers?.[0]?.number ? true : false}
              onClick={() => {
                if (!userDetails?.is_service_enable || !bankStatementDays) return;
                if (!customer?.phoneNumbers?.[0]?.number) return;
                handle.handleSendBankStatementsLink(
                  customer._id,
                  applicationId,
                  customer?.phoneNumbers.slice(-1)[0],
                  bankStatementDays,
                );
                // dispatch(saveValue({ linkSent: true }));
                // setDelay(30000);
              }}
            >
              <CustomCardSending
                title={"Get bank statements"}
                disabled={!userDetails?.is_service_enable || !customer?.phoneNumbers?.[0]?.number || !bankStatementDays}
                description={`Send a self service link and get
              access to their bank statements via illion Bank Statements.`}
                imagePath={"/static/illion-logo.png"}
                loadingState={bankStatementsLoading}
                financialState={true}
                icon={false}
                lastTitle={"Sending SMS link..."}
              />
            </CardActionArea>
          </Card>
          {!customer?.phoneNumbers.slice(-1)[0] && (
            <Typography variant="caption">Sending a bank statements link requires a customer mobile number.</Typography>
          )}
          {bankStatementsRequest && <Typography variant="caption">{SUCCESS_MESSAGE.bankStatements}</Typography>}
        </HtmlTooltip>
      </Grid>
    </React.Fragment>
  );
});

export default CustomerFinancialStatement;
