/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import AssetValue from "./QuoteComponents/AssetValue";
import Deposit from "./QuoteComponents/Deposit";
import AssetType from "./QuoteComponents/AssetType";
import TypeOfSale from "./QuoteComponents/TypeOfSale";
import AgeOfAsset from "./QuoteComponents/AgeOfAsset";
import Term from "./QuoteComponents/Term";
import Balloon from "./QuoteComponents/Balloon";
// import WelcomeModal from "./QuoteComponents/WelcomeModal";
import QuoteEntity from "./QuoteComponents/QuoteEntity";
import LengthOfEmployment from "./QuoteComponents/LengthOfEmployment";
import EmploymentType from "./QuoteComponents/EmploymentType";
import QuoteCreditScore from "./QuoteComponents/QuoteCreditScore";
import LivingArrangements from "./QuoteComponents/LivingArrangements";
import QuoteDateOfBirth from "./QuoteComponents/QuoteDateOfBirth";
import AssetCondition from "./QuoteComponents/AssetCondition";
import { APPLICATION_TYPES, ERRORS_MESSAGE } from "../../constants";
import { applicationSelector, clearState, getApplication, saveValue, updateAppStatusToWorkShop, userDetailsSelector } from "../../store/slices/applicationFormSlice";
import mixpanel from "../../services/mixpanel";
import { resetState } from "../../store/slices/userSlice";
// import Walkthrough from "../Walkthrough";
import PageLoader from "../Application/PageLoader";
import ProductSelector from "../ProductSelector";
import {
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
} from "../../store/slices/apiSlice";

const formFieldsStyle = {
  margin: "0 0 14px",
}

const Quote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const disclaimer = localStorage.disclaimer
  // const [disclaimer, setDisclaimer] = useState(localStorage.disclaimer)
  const { applicationId } = useParams();
  const { gettingQuote, startApplicationBtnLoader } = useSelector(userDetailsSelector);

  const application = useSelector(applicationSelector);

  const [fieldErrors, setFieldErrors] = useState({
    assetValue: ''
  })

  const [quoteValues, setQuoteValue] = useState({
    assetValue: 0,
    deposit: 0,
  });

  async function handleApplicationLoad() {
    dispatch(saveValue({ gettingQuote: true, isQuoteOpen: true, open: true }))
    if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
  }

  const [skipConsumer, setSkipConsumer] = useState(true);
  const [skipPersonal, setSkipPersonal] = useState(true);
  const [skipCommercial, setSkipCommercial] = useState(true);

  const consumerResult = useGetConsumerLendersQuery({}, { skip: skipConsumer });
  const personalResult = useGetPersonalLendersQuery({}, { skip: skipPersonal });
  const commercialResult = useGetCommercialLendersQuery({}, { skip: skipCommercial });

  useEffect(() => {
    switch (application.applicationType) {
      case "consumer":
        setSkipConsumer(false);
        setSkipPersonal(true);
        setSkipCommercial(true);
        break;
      case "personal":
        setSkipConsumer(true);
        setSkipPersonal(false);
        setSkipCommercial(true);
        break;
      case "commercial":
        setSkipConsumer(true);
        setSkipPersonal(true);
        setSkipCommercial(false);
        break;
      default:
        setSkipConsumer(true);
        setSkipPersonal(true);
        setSkipCommercial(true);
        break;
    }
  }, [application.applicationType]);

  // Determine which result to use based on applicationType
  const result =
    application.applicationType === "consumer"
      ? consumerResult
      : application.applicationType === "personal"
        ? personalResult
        : application.applicationType === "commercial"
          ? commercialResult
          : { data: null, error: null, isLoading: false };

  const { data: lenderData } = result;

  useEffect(() => {
    mixpanel.track_pageview({
      "page": "Quote",
      applicationId: applicationId,
      applicationType: application.applicationType
    });

    handleApplicationLoad();

    return () => {
      dispatch(clearState());
    };
  }, [applicationId]);

  const calculatorRef = useRef(null);

  useEffect(() => {
    const inactivityTimer = setTimeout(() => {
      localStorage.clear();
      navigate("/login");
      dispatch(resetState());
    }, 3600000)
    return () => {
      clearTimeout(inactivityTimer);
    }
  }, [application])

  const isCommercial = application.applicationType === APPLICATION_TYPES.COMMERCIAL
  const isConsumer = application.applicationType === APPLICATION_TYPES.CONSUMER

  const quoteTitle = `${application?.applicationType?.charAt(0).toUpperCase() + application?.applicationType?.slice(1)} quote - ${application.humanId}`

  useEffect(() => {
    localStorage.setItem("disclaimer", true)
  }, [disclaimer])

  const handle = {
    startApplicationHandler: () => {
      // Application started
      // step to follow
      // call start application api
      // update application status to quote to workshop
      // delete/remove credit profile object due to score

      const updateStatusPayload = {
        applicationId,
        customerId: application.customers[0]?._id,
      }

      dispatch(updateAppStatusToWorkShop(updateStatusPayload)).then((res) => {
        if (res?.payload?.data?.data) {
          // Navigate to application route
          navigate(`/application/${applicationId}`);
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    }
  }

  return (
    <>
      {/* <Walkthrough /> */}
      {/* {!disclaimer &&
        <Alert
          severity="warning"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={() => setDisclaimer(true)}>
              don't show again
            </Button>
          }
        >
          Quotes are still subject to lender approval and discretion.
        </Alert>
      } */}
      <Grid
        container
        item
        style={{
          // marginTop: "64px",
          // padding: "30px 0px 100px"
        }}
      >

        <Grid container item sm={4} md={3} lg={3}
          ref={calculatorRef}
          style={{
            padding: "6px 0",
          }} >
          <Box
            style={{
              overflow: "hidden auto",
              height: "calc(100vh - 105px)",
              position: "fixed",
              width: `${calculatorRef?.current?.offsetWidth}px`,
              paddingBottom: "50px"
            }}>
            <Grid style={{ maxWidth: `${calculatorRef?.current?.offsetWidth}px`, padding: "0 20px", }}>
              <Typography variant="h6" style={{ marginBottom: "15px" }}>{quoteTitle}</Typography>

              <Grid container item direction="row" spacing={1}>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <AssetValue
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                    quoteValues={quoteValues}
                    setQuoteValue={setQuoteValue}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <Deposit
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                    quoteValues={quoteValues}
                    setQuoteValue={setQuoteValue}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={1}>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <AssetCondition />
                </Grid>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <AgeOfAsset />
                </Grid>
              </Grid>

              <Grid item md={12} sm={12} style={formFieldsStyle}>
                <AssetType
                  applicationType={application.applicationType}
                />
              </Grid>
              <Grid item md={12} sm={12} style={formFieldsStyle}>
                <TypeOfSale />
              </Grid>

              {/* <Grid item md={12} sm={12} style={formFieldsStyle}>
                <AgeOfAsset />
              </Grid> */}

              {/* <Grid container spacing={1} style={{ background: "white", padding: "15px" }}> */}
              <Grid container item direction="row" spacing={3}>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 10px 0" }}>
                  <Term />
                </Grid>

                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 10px 0" }}>
                  <Balloon />
                </Grid>
              </Grid>

              {isCommercial &&
                <Grid container item direction="row" spacing={1} >
                  <Grid item md={8} sm={12} xs={12} style={formFieldsStyle}>
                    <LivingArrangements />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12} style={formFieldsStyle}>
                    <QuoteCreditScore />
                  </Grid>
                </Grid>
              }

              {!isCommercial &&
                <>
                  <Grid container item direction="row" spacing={1} >
                    <Grid item md={12} sm={12} xs={12} style={formFieldsStyle}>
                      <LivingArrangements />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" spacing={1} >
                    <Grid item md={8} sm={12} xs={12} style={formFieldsStyle}>
                      <QuoteDateOfBirth />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={formFieldsStyle}>
                      <QuoteCreditScore />
                    </Grid>
                  </Grid>
                </>
              }

              {isConsumer && <Grid sm={12} item style={formFieldsStyle}>
                <EmploymentType />
              </Grid>
              }

              {isConsumer && <Grid sm={12} item style={{ margin: "0 0 10px 0" }}>
                <LengthOfEmployment />
              </Grid>
              }

              {isCommercial && <Grid sm={12} item style={formFieldsStyle}>
                <QuoteEntity application={application} />
              </Grid>
              }

              <Grid
                sm={12}
                item
                style={{
                  margin: "0 0 10px 0",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handle.startApplicationHandler}
                  disabled={startApplicationBtnLoader}
                >
                  {startApplicationBtnLoader ? 'Starting...' : 'Start Application'}
                </Button>
              </Grid >
            </Grid >
          </Box>
        </Grid>

        {
          gettingQuote ? (
            <Grid
              container
              item
              style={{
                marginTop: "64px",
                // padding: "30px 0px 100px",
                // height: "calc(100vh - 100px)",
                justifyContent: "center",
                // alignItems: "center"
              }}
            >
              <PageLoader text="Quote loading..." />
            </Grid>
          ) : (
            <Grid conatiner item sm={8} md={9} lg={9} style={{
              padding: 0,
              // background: "#f5f5f5"
            }}>
              <Box
                style={{
                  // background: color.lightPink
                  padding: "10px 20px",
                  // width: "calc(25vw - 60px)",
                  position: "relative",
                  // overflowY: "auto",
                  // overflowX: "hidden",
                  // height: "calc(100vh - 105px)",
                  scrollbarColor: "white rebeccapurple",
                  scrollbarWidth: "thin",
                  // marginTop: "-55px",
                }}
              >
                {lenderData ? (
                  // isQuoteOpen={true}
                  <ProductSelector color="secondary" lenderData={lenderData} />
                ) : (
                  <PageLoader text={"Lenders Loading"} />
                )}
              </Box>
            </Grid>
          )
        }


      </Grid >


      {/* <WelcomeModal /> */}
    </>
  );
}

export default Quote