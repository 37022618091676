import * as esprima from "esprima";
import * as Yup from "yup";
const moment = require("moment");

export const getInputValidationRules = (criteriaFunction, inputValidationRules) => {
  let validationRules = null;
  let tokenizedFunction;
  try {
    tokenizedFunction = esprima.parseScript(criteriaFunction.toString());
  } catch {
    // Weird fix to avoid parsing error since Esprima can't parse function declarations
    const functionDeclaration = criteriaFunction.toString();
    const functionExpression = `(${functionDeclaration})`;
    tokenizedFunction = esprima.parseScript(functionExpression);
  }
  try {
    validationRules = {};
    const validationRulesIdentifier = [];

    // Recursively traverse the AST
    const traverse = (node, callback) => {
      callback(node);
      for (let key in node) {
        if (node.hasOwnProperty(key) && typeof node[key] === "object" && node[key] !== null) {
          traverse(node[key], callback);
        }
      }
    };

    traverse(tokenizedFunction, (node) => {
      if (node.type === "Property" && node.key.name === "inputValidationRules") {
        const elements = node.value.elements || [];
        elements.forEach((element) => {
          if (element.type === "MemberExpression" && element.property.type === "Identifier") {
            const ruleName = element.property.name;
            validationRulesIdentifier.push(ruleName);
          }
        });
      }
    });

    if (validationRulesIdentifier.length > 0) {
      validationRulesIdentifier.forEach((ruleName) => {
        validationRules = { ...validationRules, ...inputValidationRules[ruleName] };
      });
    }

    return validationRules;
  } catch (error) {
    return validationRules;
  }
};

export const buildValidationSchema = (validationRules) => {
  let schema;

  // Input type initiation
  if (validationRules?.type === "text") {
    schema = Yup.string().typeError("Input must be a string");
  }

  if (validationRules?.type === "numeric" || validationRules?.type === "money") {
    schema = Yup.number().typeError("Input must be a number");
  }

  // Other validation rules

  if (validationRules.required) {
    schema = schema.required("This field is required");
  }

  if (validationRules?.min) {
    schema = schema.min(validationRules?.min, `Minimum value allowed is ${validationRules?.min}`);
  }

  if (validationRules?.max) {
    schema = schema.max(validationRules?.max, `Maximum value allowed is ${validationRules?.max}`);
  }

  if (validationRules.positive) {
    schema = schema.positive("Only positive integer allowed");
  }

  return schema;
};

export const inputValidationRules = {
  text: {
    type: "text",
    minLength: 3,
    maxLength: 100,
  },
  numeric: {
    type: "numeric",
    positive: true,
    max: 9999,
    maxLength: 4,
  },
  money: {
    type: "money", // This isn't actually a valid prop for text input, used only to use the existing NumericFormatCustom component
    positive: true,
    maxLength: 15,
  },
  required: {
    required: true,
  },
  noDecimal: {
    decimalScale: 0, // Limits the number of digits after the decimal point, 0 = no decimal point
  },
};

const criteria = {
  standard: {
    label: "Standard",
    object: "asset",
    attribute: "assetValue",
    condition: "is",
    value: true,
  },
  // propertyOwner: {
  //   label: "Property owner",
  //   object: "customerProfile",
  //   attribute: "livingArrangements",
  //   condition: "exists",
  //   value: "Property Owner",
  // },
  // hasDefault: {
  //   label: "Has default",
  //   object: "creditProfile",
  //   attribute: "hasAdverseCredit",
  //   condition: "exists",
  //   value: true,
  // },
  // noDefault: {
  //   label: "Has default",
  //   object: "creditProfile",
  //   attribute: "hasAdverseCredit",
  //   condition: "doesntExist",
  //   value: false,
  // },
  dateOfBirthLessThan: (value) => {
    const years = moment().subtract(value, "years").format("YYYY-MM-DD");
    return {
      label: `Age of customer <${value} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "greaterThan",
      value: years,
      inputValidationRules: [inputValidationRules.numeric, inputValidationRules.required],
    };
  },
  dateOfBirthGreaterThan: (value) => {
    const years = moment().subtract(value, "years").format("YYYY-MM-DD");

    return {
      label: `Age of customer >${value} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "lessThan",
      value: years,
    };
  },
  dateOfBirthBetween: (min, max) => {
    const minYears = moment().subtract(min, "years").format("YYYY-MM-DD");
    const maxYears = moment().subtract(max, "years").format("YYYY-MM-DD");

    return {
      label: `Age of customer ${min}-${max} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "betweenLow",
      value: {
        min: maxYears,
        max: minYears,
      },
      inputValidationRules: [inputValidationRules.numeric, inputValidationRules.required],
    };
  },
  // noDeposit: {
  //   label: "No deposit",
  //   object: "loanDetails",
  //   attribute: "deposit",
  //   condition: "zero",
  // },
  nonGST: {
    label: "Not GST registered",
    object: "entity",
    attribute: "gst",
    condition: "isNot",
  },
  termIsLessThan: (value) => ({
    label: `Term <${value} months`,
    object: "loanDetails",
    attribute: "term",
    condition: "lessThan",
    value,
  }),
  termIsBetween: (min, max) => ({
    label: `Term ${min} - ${max} months`,
    object: "loanDetails",
    attribute: "term",
    condition: "betweenLow",
    value: {
      min,
      max,
    },
  }),
  termIsLessThanOrEqualTo: (value) => ({
    label: `Term max ${value} months`,
    object: "loanDetails",
    attribute: "term",
    condition: "lessThanOrEqualTo",
    value,
  }),
  termIsGreaterThan: (value) => ({
    label: `Term >${value} months`,
    object: "loanDetails",
    attribute: "term",
    condition: "greaterThan",
    value,
  }),
  // depositGreaterThanOrEqualTo: (value) => ({
  //   label: "20% deposit",
  //   object: "loanDetails",
  //   attribute: "deposit",
  //   condition: "greaterThanOrEqualTo",
  //   value,
  // }),
  // nonPropertyOwner: {
  //   label: "Not a property owner",
  //   object: "customerProfile",
  //   attribute: "livingArrangements",
  //   condition: "doesntExist",
  //   value: "Property Owner",
  // },
  eachCreditScoreGreaterThan: (value) => ({
    label: `Credit score >${value}`,
    object: "creditProfile",
    attribute: "score",
    condition: "eachIsGreaterThan",
    value,
  }),
  creditScoreGreaterThan: (value) => ({
    label: `Credit score >${value}`,
    object: "creditProfile",
    attribute: "score",
    condition: "greaterThan",
    value,
  }),
  creditScoreLessThan: (value) => ({
    label: `Credit score <${value}`,
    object: "creditProfile",
    attribute: "score",
    condition: "lessThan",
    value,
  }),
  creditScoreBetween: (min, max) => ({
    label: `Credit score ${min} - ${max}`,
    object: "creditProfile",
    attribute: "score",
    condition: "between",
    value: {
      min,
      max,
    },
  }),
  employmentType: (value) => ({
    label: "Employment type",
    object: "employer",
    attribute: "employmentType",
    condition: "hasOne",
    value,
  }),
  timeOfEmploymentGreaterThan: (value) => ({
    label: `Employed >${value} months`,
    object: "employer",
    attribute: "timeOfEmployment",
    condition: "greaterThan",
    value,
  }),
  livingArrangements: (value) => ({
    label: "Living arrangements",
    object: "customerProfile",
    attribute: "livingArrangements",
    condition: "hasOne",
    value,
  }),
  typeOfSale: (value) => ({
    label: "Type of sale",
    object: "asset",
    attribute: "typeOfSale",
    condition: "hasOne",
    value,
  }),
  typeOfSaleMany: (value) => ({
    label: "Type of sale",
    object: "asset",
    attribute: "typeOfSale",
    condition: "hasOne",
    value,
  }),
  hasDeposit: (value) =>
    // console.log({ value })
    ({
      label: `${value}% deposit`,
      object: "loanDetails",
      attribute: "deposit",
      condition: "greaterThanOrEqualTo",
      value,
    }),
  depositBetween: (min, max) => ({
    label: `Deposit ${min}-${max}% `,
    object: "loanDetails",
    attribute: "deposit",
    condition: "between",
    value: {
      min,
      max,
    },
  }),
  noDeposit: (value) => ({
    label: `No deposit`,
    object: "loanDetails",
    attribute: "deposit",
    condition: "zero",
    value,
  }),
  assetValueGreaterThan: function (value) {
    return {
      label: `Asset value >$${value}`,
      object: "asset",
      attribute: "assetValue",
      condition: "greaterThan",
      value,
      inputValidationRules: [inputValidationRules.money, inputValidationRules.required],
    };
  },
  assetValueBetween: function (min, max) {
    return {
      label: `Asset value $${min} - $${max}`,
      object: "asset",
      attribute: "assetValue",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  assetType: function (value) {
    return {
      label: "Asset Type",
      object: "asset",
      attribute: "assetType",
      condition: "hasOne",
      value,
    };
  },
  assetCondition: function (value) {
    return {
      label: "Asset Condition",
      object: "asset",
      attribute: "condition",
      condition: "hasOne",
      value,
    };
  },
  // ageOfAssetNewOrDemo: {
  //   label: `Asset New/Demo`,
  //   object: "asset",
  //   attribute: "ageOfAsset",
  //   condition: "hasOne",
  //   value: ["New", "Demo"],
  // },
  ageOfAssetGreaterThan: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age >${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "lessThan",
      value: monthsToYears,
    };
  },
  ageOfAssetGreaterThanOrEqualTo: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age >${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "lessThanOrEqualTo",
      value: monthsToYears,
    };
  },
  ageOfAssetLessThan: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age <${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "greaterThan",
      value: monthsToYears,
    };
  },
  ageOfAssetLessThanOrEqualTo: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");

    return {
      label: `Asset age <${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "greaterThanOrEqualTo",
      value: monthsToYears,
    };
  },
  ageOfAssetBetween: function (min, max) {
    const monthsToYearsMin = moment().subtract(min, "months").format("YYYY");
    const monthsToYearsMax = moment().subtract(max, "months").format("YYYY");
    return {
      label: `Asset age ${min} - ${max} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "betweenLow",
      value: {
        min: monthsToYearsMax,
        max: monthsToYearsMin,
      },
    };
  },
  ageOfAssetAtEndGreaterThan: function (value) {
    return {
      label: `End of term age >${value} yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "greaterThan",
      value,
    };
  },
  ageOfAssetAtEndLessThan: function (value) {
    return {
      label: `End of term age <${value} yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "lessThan",
      value,
    };
  },
  ageOfAssetAtEndBetween: function (min, max) {
    return {
      label: `End of term age ${min} - ${max}yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  loanAmountGreaterThan: function (value) {
    return {
      label: `Loan amount >$${value}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "greaterThan",
      value,
    };
  },
  loanAmountLessThan: function (value) {
    return {
      label: `Loan amount <$${value}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "lessThan",
      value,
    };
  },
  loanAmountBetween: function (min, max) {
    return {
      label: `Loan amount $${min} - $${max}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "betweenLow",
      value: {
        min,
        max,
      },
      inputValidationRules: [inputValidationRules.money, inputValidationRules.required],
    };
  },
  loanPurpose: function (value) {
    return {
      label: `Loan purpose: ${value}`,
      object: "loanDetails",
      attribute: "purpose",
      condition: "hasOne",
      value,
    };
  },

  timeInBusinessBetween: function (min, max) {
    return {
      label: `Time in business ${min} - ${max} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "betweenLow",
      value: {
        min: moment(moment().add(-max, "M")).format("YYYY-MM-DD"),
        max: moment(moment().add(-min, "M")).format("YYYY-MM-DD"),
      },
      inputValidationRules: [inputValidationRules.numeric, inputValidationRules.required],
    };
  },
  timeInBusinessLessThan: function (value) {
    return {
      label: `Time in business <${value} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "greaterThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  timeInBusinessGreaterThan: function (value) {
    return {
      label: `Time in business >${value} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "lessThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationGreaterThan: function (value) {
    return {
      label: `GST reg. >${value} mths`,
      object: "entity",
      attribute: "gst",
      condition: "lessThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationLessThan: function (value) {
    return {
      label: `GST reg. <${value} mths`,
      object: "entity",
      attribute: "gst",
      condition: "greaterThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationBetween: function (min, max) {
    return {
      label: `GST reg. ${min} - ${max} mths`,
      object: "entity",
      attribute: "gst",
      condition: "betweenLow",
      value: {
        min: moment(moment().add(-max, "M")).format("YYYY-MM-DD"),
        max: moment(moment().add(-min, "M")).format("YYYY-MM-DD"),
      },
    };
  },
  customerDefaultAmountLessThan: (value) => ({
    label: `Customer defaults value <$${value}`,
    object: "customer",
    attribute: "defaultValue",
    condition: "lessThan",
    value: value,
  }),
  brokerageAmount: (min, max) => ({
    label: `Brokerage amount`,
    object: "loanDetails",
    attribute: "brokerageRate",
    condition: "betweenLow",
    value: {
      min,
      max,
    },
  }),
  brokerageIsEqualTo: (value) => ({
    label: `Brokerage`,
    object: "loanDetails",
    attribute: "brokerage",
    condition: "equal",
    value,
  }),
  brokerageIsBetween: (min, max) => ({
    label: `Brokerage`,
    object: "loanDetails",
    attribute: "brokerage",
    condition: "betweenLow",
    value: {
      min,
      max,
    },
  }),
  // compound: (obj) => ({
  //   label: obj.name,
  //   object: "loanDetails",
  //   conditional: obj.conditional,
  //   condition: "compound",
  //   primary: obj.primary,
  //   criteria: obj.criteria,
  // }),
};
export default criteria;
