import React, { useState } from "react";
import { Grid } from "@mui/material";
import QuoteCalculator from "./QuoteCalculator";
import RepaymentSection from "./RepaymentSection";

const ManualQuote = ({ application }) => {
  // console.log("application", application)

  const [evaluatedQuoteFees, setEvaluatedQuoteFees] = useState({
    repayments: 0,
    brokerage: 0,
    brokerageAmount: 0,
    financeAmount: 0,
    loanAmount: 0,
    effectiveRate: 0,
    feesTotal: 0,
  });

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: "0 0 0 15px",
      }}
    >
      <div
        style={{
          padding: "12px 15px 12px 12px",
        }}
      >
        {application && (
          <>
            <QuoteCalculator
              evaluatedQuoteFees={evaluatedQuoteFees}
              setEvaluatedQuoteFees={setEvaluatedQuoteFees}
              application={application}
            />
            <RepaymentSection evaluatedQuoteFees={evaluatedQuoteFees} application={application} />
          </>
        )}
      </div>
    </Grid>
  );
};

export default ManualQuote;
