import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const lenderApi = createApi({
  reducerPath: "lenderApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL || "" }),
  tagTypes: ["commercial", "consumer", "personal"],
  endpoints: (builder) => ({
    getCommercialLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "commercial" },
      }),
      providesTags: ["commercial"],
    }),
    getConsumerLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "consumer" },
      }),
      providesTags: ["consumer"],
    }),
    getPersonalLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "personal" },
      }),
      providesTags: ["personal"],
    }),
  }),
});

export const allLendersApi = createApi({
  reducerPath: "allLendersApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL || "" }),
  tagTypes: ["lender", "lenderProducts", "lenders"],
  endpoints: (builder) => ({
    getAllLenders: builder.query({
      query: (data) => {
        let { lender = null, type = null, limit = 10, cursor = 1, sort = null, columnFilter = null } = data;
        if (lender === "") lender = null;
        if (sort && !sort.field) sort = null;
        return {
          url: "/lender",
          method: "POST",
          body: { limit, lender, type, cursor, sort, columnFilter },
          keepUnusedDataFor: 360,
        };
      },
      transformResponse: (response) => response.data?.getAllLendersData ?? [],
      providesTags: ["lenders"],
    }),
    getLender: builder.query({
      query: (lenderId) => ({
        url: `/lender/${lenderId}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        lender: response.data?.lender ?? {},
        requiredFieldOptions: response.data?.requiredFieldOptions ?? [],
      }),
      providesTags: ["lender"],
    }),
    addLender: builder.mutation({
      query: (data) => ({
        url: "/lender/create",
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["lenders"],
    }),
    editLender: builder.mutation({
      query: (data) => ({
        url: `/lender/${data.lenderId}`,
        method: "PATCH",
        body: data.body,
      }),
      transformResponse: (response) => response?.data ?? {},
      invalidatesTags: ["lender"],
    }),
    updateLenderArrayFields: builder.mutation({
      // Used to update Lender fields that are array to be faster compared to calling editLender every time which is expensive
      query: (data) => {
        const { lenderId, ...body } = data;
        return { url: `/lender/${lenderId}/arrayFields`, method: "PATCH", body };
      },
      invalidatesTags: ["lender"],
    }),
    deleteLender: builder.mutation({
      query: (id) => ({
        url: `/lender/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["lenders"],
    }),
    getProductTiersByLender: builder.query({
      query: (data) => {
        let { lenderId = null, name = null, limit = 10, cursor = 1, sort = null, columnFilter = null } = data;
        if (name === "") name = null;
        if (sort && !sort.field) sort = null;
        return {
          url: `/lender/${lenderId}/productTiers`,
          method: "POST",
          body: { limit, cursor, name, sort, columnFilter },
          keepUnusedDataFor: 360,
        };
      },
      transformResponse: (response) => response.data?.getAllProductTiersData ?? [],
      providesTags: ["lenderProducts"],
    }),
    addProductTier: builder.mutation({
      query: (data) => {
        const { body, lenderId } = data;
        return {
          url: `/lender/${lenderId}/productTiers/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["lenderProducts"],
    }),
    updateProductTier: builder.mutation({
      query: (data) => {
        let { productTierId = null } = data;
        return {
          url: `/lender/productTiers/${productTierId}`,
          method: "PATCH",
          body: data.body,
          keepUnusedDataFor: 360,
        };
      },
      transformResponse: (response) => response.data?.getAllProductTiersData ?? {},
      invalidatesTags: ["lenderProducts"],
    }),
    bulkUpdateProductTiers: builder.mutation({
      query: (data) => ({
        url: `/lender/productTiers/bulk/update`,
        method: "PATCH",
        body: data.body,
        keepUnusedDataFor: 360,
      }),
      transformResponse: (response) => response.data?.getAllProductTiersData ?? {},
      invalidatesTags: ["lenderProducts"],
    }),
    deleteProductTier: builder.mutation({
      query: (id) => ({
        url: `/lender/productTiers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["lenderProducts"],
    }),
    deleteBulkProductTiers: builder.mutation({
      query: (data) => ({
        url: `/lender/productTiers/bulk/delete`,
        method: "DELETE",
        body: data,
        keepUnusedDataFor: 360,
      }),
      invalidatesTags: ["lenderProducts"],
    }),
    cloneBulkProductTiers: builder.mutation({
      query: (data) => ({
        url: `/lender/productTiers/bulk/clone`,
        method: "POST",
        body: data,
        keepUnusedDataFor: 360,
      }),
      invalidatesTags: ["lenderProducts"],
    }),
  }),
});

export const { useGetCommercialLendersQuery, useGetConsumerLendersQuery, useGetPersonalLendersQuery } = lenderApi;

export const {
  useLazyGetAllLendersQuery,
  useLazyGetLenderQuery,
  useLazyGetProductTiersByLenderQuery,
  useEditLenderMutation,
  useUpdateLenderArrayFieldsMutation,
  useUpdateProductTierMutation,
  useBulkUpdateProductTiersMutation,
  useAddLenderMutation,
  useDeleteLenderMutation,
  useAddProductTierMutation,
  useDeleteProductTierMutation,
  useDeleteBulkProductTiersMutation,
  useCloneBulkProductTiersMutation,
} = allLendersApi;
