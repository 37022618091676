import React, { useRef } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { CustomPopper } from "./add";
import ProductCriteriaContent from "../ProductCriteriaContent";
import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";
import { productTypes } from "../../../constants";

const ProductDialog = ({ product, setProduct, isDialogOpen, setIsDialogOpen, editProduct }) => {
  const criteriaRefs = useRef([]);

  const productTypeOptions = productTypes.map((label) => ({ label, value: label }));

  const productTierId = product?._id;
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setProduct(null);
  };

  const handleAddCriteria = () => {
    const criteria = {
      tempId: uuidv4(),
      productTier: productTierId,
      label: "",
      object: "",
      attribute: "",
      condition: "",
      value: 0,
    };
    let editedProductWithCriteria = [...product.criteria];
    editedProductWithCriteria.unshift(criteria);
    const editedProduct = {
      ...product,
      criteria: editedProductWithCriteria,
    };
    setProduct(editedProduct);
  };

  const handleAddInfo = () => setProduct({ ...product, info: [...product.info, ""] });

  const handleEditInfo = (e, index) => {
    const updatedInfo = product.info.map((info, i) => {
      if (i === index) return e.target.value;
      else return info;
    });
    setProduct({ ...product, info: updatedInfo });
  };

  const handleRemoveInfo = (index) => {
    const updatedInfo = product.info.filter((info, i) => i !== index);
    setProduct({ ...product, info: updatedInfo });
  };

  const handleIsRateToggle = (e) => {
    if (product?.isRateToggle || Array.isArray(product?.rate))
      setProduct({ ...product, rate: 0, isRateToggle: e.target.checked });
    else setProduct({ ...product, rate: [0, 0], isRateToggle: e.target.checked });
  };

  const handleSubmit = () => {
    let isValid = true;

    // TODO: Add validation for product details

    criteriaRefs.current.forEach((ref) => {
      if (ref && !ref.validateSubmission()) {
        isValid = false;
      }
    });

    if (isValid) {
      handleSaveChanges();
    }
  };

  const handleSaveChanges = () => {
    const { _id, createdAt, updatedAt, __v, ...rest } = product;
    let body = rest;

    let bodyCriteria = body.criteria;

    bodyCriteria = bodyCriteria.map((criterion) => {
      const criterionCopy = { ...criterion };

      if ("tempId" in criterionCopy) {
        delete criterionCopy.tempId;
      }

      if ("_id" in criterionCopy) {
        delete criterionCopy._id;
      }

      return criterionCopy;
    });

    body.criteria = bodyCriteria;

    editProduct({
      productTierId: _id,
      body,
    });
    handleDialogClose();
  };

  return (
    <Dialog onClose={handleDialogClose} open={isDialogOpen} fullWidth maxWidth="md">
      <DialogTitle pb={"0"}>Edit Product Details</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleDialogClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disableRipple={true}
      >
        <Close />
      </IconButton>
      <DialogContent pt={"0"}>
        <Grid container item xs={12} direction={"row"} spacing={"16px"} mt={"0"} alignItems={"center"}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              variant="outlined"
              margin="dense"
              type="text"
              name="name"
              value={product?.name}
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              value={product?.productType}
              options={productTypeOptions}
              onChange={(e, newValue) => setProduct({ ...product, productType: newValue.value })}
              renderInput={(params) => (
                <TextField {...params} label="Product Type" variant="outlined" margin="dense" size="small" />
              )}
              PopperComponent={CustomPopper}
            />
          </Grid>

          <Grid
            item
            xs={3}
            {...(product?.isRateToggle || (Array.isArray(product?.rate) && { container: true, columnSpacing: "8px" }))}
          >
            {!product?.isRateToggle && !Array.isArray(product?.rate) ? (
              <TextField
                label="Rate"
                fullWidth
                size="small"
                variant="outlined"
                margin="dense"
                type="text"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  inputProps: {
                    prefix: "",
                  },
                }}
                value={product?.rate}
                onChange={(e) => setProduct({ ...product, rate: e.target.value })}
              />
            ) : (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Min"
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                    value={product?.rate[0]}
                    onChange={(e) => setProduct({ ...product, rate: [e.target.value, product.rate[1]] })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Max"
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                    value={product?.rate[1]}
                    onChange={(e) => setProduct({ ...product, rate: [product.rate[0], e.target.value] })}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={product?.isRateToggle || Array.isArray(product?.rate)}
                  onChange={(e) => handleIsRateToggle(e)}
                />
              }
              label="Switch to Range"
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction={"row"}
          alignItems={"center"}
          mb={"8px"}
          // {...(product?.criteria && product?.criteria.length > 0 && { mb: 8 })}
        >
          <Typography mb={"8px"} mr={"16px"}>
            Criteria
          </Typography>
          <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
            <Add />
          </IconButton>
        </Grid>
        {product?.criteria && product?.criteria.length > 0 && (
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            columnSpacing={"24px"}
            rowSpacing={"5px"}
            // {...(product.criteria.length > 4 && { maxHeight: "370px", sx: { overflowY: "scroll" } })}
          >
            {product?.criteria.map((productCriteria, index) => (
              <React.Fragment key={productCriteria._id || productCriteria?.tempId}>
                <ProductCriteriaContent
                  key={productCriteria._id || productCriteria?.tempId}
                  keyId={productCriteria._id || productCriteria?.tempId}
                  criteria={productCriteria}
                  index={index}
                  product={product}
                  setProduct={setProduct}
                  criteriaRefs={criteriaRefs}
                />
                {(product?.criteria ?? []).length - 1 !== index && (
                  <Grid item xs={12}>
                    <Divider flexItem />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        )}
        <Typography mb={2} mt={2}>
          Info
        </Typography>
        <Grid container item xs={12} direction={"column"} mb={5} rowSpacing={"24px"}>
          {(product?.info ?? []).map((info, index) => (
            <Grid key={index} item xs={12} alignItems={"center"}>
              <TextField size="small" sx={{ width: "90%" }} value={info} onChange={(e) => handleEditInfo(e, index)} />
              <IconButton disableRipple onClick={() => handleRemoveInfo(index)}>
                <Delete />
              </IconButton>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<Add />} sx={{ width: "fit-content" }} onClick={handleAddInfo}>
              ADD INFO
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;
