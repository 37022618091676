import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
// import { debounce } from "lodash";
import {
  // useEditLenderMutation,
  useUpdateLenderArrayFieldsMutation,
} from "../../../../store/slices/apiSlice";
import { CREDENTIAL_ATTRIBUTES } from "../../../../constants";

const CredentialSection = React.memo(({ lenderState }) => {
  // setLender
  // const [editLender] = useEditLenderMutation();
  const [updateLenderArrayFields] = useUpdateLenderArrayFieldsMutation();

  const [lender, setLender] = useState(lenderState);
  const lenderId = lender?._id;

  useEffect(() => {
    if (lenderState) {
      setLender(lenderState);
    }
  }, [lenderState]);

  const handleAddCredential = () => {
    const newCredential = { label: "", attribute: "" };
    const updatedCredentials = [...lender.lenderCredentials, newCredential];

    setLender((prevLender) => ({
      ...prevLender,
      lenderCredentials: updatedCredentials,
    }));

    updateLenderArrayFields({
      lenderId,
      lenderCredentials: updatedCredentials,
    });
  };

  const handleRemoveCredential = (index) => {
    const updatedCredentials = lender.lenderCredentials.filter((_, pos) => pos !== index);

    setLender((prevLender) => ({
      ...prevLender,
      lenderCredentials: updatedCredentials,
    }));

    updateLenderArrayFields({
      lenderId,
      lenderCredentials: updatedCredentials,
    });
  };

  const handleEditCredential = (e, index) => {
    const updatedCredentials = lender.lenderCredentials.map((credential, pos) =>
      pos === index ? { ...credential, [e.target.name]: e.target.value } : credential,
    );

    setLender((prevLender) => ({
      ...prevLender,
      lenderCredentials: updatedCredentials,
    }));

    updateLenderArrayFields({
      lenderId,
      lenderCredentials: updatedCredentials,
    });
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Credentials Needed</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          xs={9}
          direction="row"
          style={{ margin: "0px 0px 7px 0px" }}
          rowGap={"16px"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {lender?.lenderCredentials?.map((credential, index) => (
            <Grid key={index} item xs={12}>
              <Stack direction={"row"} xs={12} gap={"16px"} alignItems={"center"}>
                <TextField
                  fullWidth
                  size="small"
                  value={credential?.label}
                  xs={5}
                  label={`Credential Label #${index + 1}`}
                  name="label"
                  onBlur={(e) => handleEditCredential(e, index)}
                  onChange={(e) => {
                    const updatedCredentials = lender.lenderCredentials.map((cred, pos) =>
                      pos === index ? { ...cred, [e.target.name]: e.target.value } : cred,
                    );
                    setLender((prevLender) => ({
                      ...prevLender,
                      lenderCredentials: updatedCredentials,
                    }));
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  select
                  value={credential?.attribute}
                  xs={5}
                  label={`Credential Attribute #${index + 1}`}
                  onBlur={(e) => handleEditCredential(e, index)}
                  onChange={(e) => {
                    const newCredentialList = lender.lenderCredentials.map((credential, position) => {
                      if (position === index) return { ...credential, [e.target.name]: e.target.value };
                      return credential;
                    });

                    const updatedCredentials = newCredentialList.map((cred, pos) =>
                      pos === index ? { ...cred, [e.target.name]: e.target.value } : cred,
                    );
                    setLender((prevLender) => ({
                      ...prevLender,
                      lenderCredentials: updatedCredentials,
                    }));
                  }}
                  name="attribute"
                >
                  {CREDENTIAL_ATTRIBUTES.filter(
                    (attribute) =>
                      attribute === credential?.attribute ||
                      !lender.lenderCredentials?.map((obj) => obj.attribute).includes(attribute),
                  ).map((attribute, idx) => (
                    <MenuItem key={idx} value={attribute}>
                      {attribute}
                    </MenuItem>
                  ))}
                </TextField>
                <Button variant="outlined" size="small" xs={2} onClick={() => handleRemoveCredential(index)}>
                  REMOVE
                </Button>
              </Stack>
            </Grid>
          ))}
          <Button
            sx={{ width: "200px", ml: "8px" }}
            xs={12}
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddCredential}
          >
            ADD CREDENTIAL
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default CredentialSection;
