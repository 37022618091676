import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE } from "../../constants";
import { loginUser, userSelector } from "../../store/slices/userSlice";
import regex from "../../utils/regex";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tokenExpired = localStorage.getItem("tokenExpired");

  const [allValues, setAllValues] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (tokenExpired) {
      localStorage.removeItem("token");
    }
  }, [tokenExpired]);

  useEffect(() => {
    function preventBack() {
      window.history.forward();
    }
    preventBack();
    window.onunload = function () {
      return null;
    };
  }, [user]);

  const handle = {
    login: async () => {
      if (!emailError && !passwordError) {
        let data = {
          email: allValues.email,
          password: allValues.password,
          clientId: CLIENT_ID,
        };
        dispatch(loginUser(data)).then((res) => {
          if (res.payload?.data?.data?.token) {
            navigate("/lenders"); // "/applications"
            // navigate(`${res.payload?.data?.data?.user?.userType !== 'admin' ? '/applications': '/products'}`);
            localStorage.removeItem("tokenExpired");
          } else {
            if (res.payload?.response_error) {
              enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          }
        });
      }
    },
    onChangeFn: (value, name) => {
      if (name === "email") {
        if (regex.email.test(value.toLowerCase())) setEmailError(false);
        else setEmailError(true);
      } else if (name === "password") {
        if (regex.password.test(value)) setPasswordError(false);
        else setPasswordError(true);
      }
      setAllValues({
        ...allValues,
        [name]: value,
      });
    },
  };

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Welcome back!
          </Typography>
          <Typography fontWeight="400" style={{ marginBottom: "10px" }}>
            Sign into your account below.
          </Typography>

          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            value={allValues?.email}
            onChange={(event) => handle.onChangeFn(event.target.value, "email")}
            margin="dense"
            error={emailError}
            helperText={emailError ? "Invalid email." : null}
          />
          <TextField
            fullWidth
            type="password"
            name="password"
            label="Password"
            value={allValues?.password}
            margin="dense"
            error={passwordError}
            helperText={
              passwordError
                ? "password must contain an uppercase letter, a number, a special character and be 8 or more characters in length."
                : null
            }
            onChange={(event) => handle.onChangeFn(event.target.value, "password")}
          />
          {user.errorMessage && <Typography color="#d32f2f">{user.errorMessage}</Typography>}
          <LoadingButton
            fullWidth
            loading={""}
            loadingPosition="end"
            variant="contained"
            disabled={""}
            onClick={() => handle.login()}
            style={{ margin: "20px 0 40px" }}
          >
            Login
          </LoadingButton>
          <Typography>
            Forgot your password? <Link to="/password/forgot">Reset password</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
