import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Close, Delete, Edit, FileCopyTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import {
  // clearState,
  lenderSelector,
  // setCurrentLender,
  updateCurrentLenderBrokerages,
  updateCurrentlyEditingFee,
  setEditLenderPayload,
} from "../../../../store/slices/lenderSlice";
import { NumericFormatCustom } from "../../../../utils/currencyMaskFormat";
import CriteriaContent from "../CriteriaContent";

const Brokerage = React.memo(({ data, index: brokerageIndex, editLender, lender, editLenderLoading }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const lenderId = lender?._id;
  const [brokerage, setBrokerage] = useState(data);

  const {
    currentlyEditingFee,
    currentLenderBrokerages,
    editLenderPayload,
    lender: lenderState,
  } = useSelector(lenderSelector);

  useEffect(() => {
    if (data) setBrokerage(data);
  }, [data]);

  const [editIsOpen, setEditIsOpen] = useState(false);

  const handleEditClose = () => {
    // dispatch(clearState());
    // dispatch(setCurrentLender(lenderState ?? lender));
    // setBrokerage(lenderState?.brokerage[brokerageIndex]);
    setEditIsOpen(false);
  };

  const handleEditOpen = () => {
    dispatch(updateCurrentlyEditingFee(data));
    setEditIsOpen(true);
  };

  const handleEditDetails = (brokerage) => {
    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          brokerage,
        },
      }),
    );
    // debounce(() =>
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     brokerage,
    //   },
    // });
    // 500)();
  };

  const handleDebounced = useCallback(debounce(handleEditDetails, 500), []);

  const handleDeleteBrokerage = () => {
    const updatedBrokerage = lenderState.brokerage.filter((b) => b._id !== brokerage._id);
    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        brokerage: updatedBrokerage,
      },
    });
  };

  const handleCloneBrokerage = () => {
    const { _id, ...rest } = brokerage;
    rest.criteria = rest.criteria.map(({ _id, createdAt, updatedAt, __v, ...rest }) => rest);

    const newBrokerages = [...lenderState.brokerage, rest];

    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        brokerage: newBrokerages,
      },
    });
  };

  const handleAddCriteria = () => {
    const criteria = {
      productTier: null,
      label: "",
      object: "",
      attribute: "",
      condition: "",
      value: 0,
    };
    const { _id, ...rest } = brokerage;
    const editedBrokerageWithCriteria = [
      criteria,
      ...currentlyEditingFee?.criteria.map((c) => {
        const { _id, ...rest } = c;
        return rest;
      }),
    ];
    const editedBrokerage = {
      ...rest,
      criteria: editedBrokerageWithCriteria,
    };
    setBrokerage(editedBrokerage);
    dispatch(updateCurrentlyEditingFee(editedBrokerage));
    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          brokerage: [
            ...lenderState.brokerage.map((b) => {
              const { _id: brokerageId, ...rest } = b;
              if (brokerageId === currentlyEditingFee._id) return editedBrokerage;
              return rest;
            }),
          ],
        },
      }),
    );
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     brokerage: [
    //       ...lender.brokerage.map((b) => {
    //         const { _id: brokerageId, ...rest } = b;
    //         if (brokerageId === brokerage._id) return editedBrokerage;
    //         return rest;
    //       }),
    //     ],
    //   },
    // });
  };

  const handleSaveChanges = () => {
    editLender(editLenderPayload).then((res) => {
      if (res?.data && !res.error) {
        handleEditClose();
        enqueueSnackbar("Brokerage updated successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar("Failed to update brokerage", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper elevation={0} variant="outlined" sx={{ height: "100%" }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container xs={12} justifyContent={"end"}>
                <Stack direction="row" gap={"8px"}>
                  <Tooltip title="Clone" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleCloneBrokerage}
                      disabled={editLenderLoading}
                    >
                      <FileCopyTwoTone
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleEditOpen}
                      disabled={editLenderLoading}
                    >
                      <Edit
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleDeleteBrokerage}
                      disabled={editLenderLoading}
                    >
                      <Delete
                        fontSize="small"
                        sx={{ ":hover": { color: "red", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Stack direction={"column"} rowGap={"12px"} my={1.5}>
                <Typography>
                  Max:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {lenderState?.brokerage[brokerageIndex]?.max}
                  </Typography>
                </Typography>
                <Typography>
                  Fee Value:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {lenderState?.brokerage[brokerageIndex]?.value}
                  </Typography>
                </Typography>
              </Stack>
              <Typography>
                Criteria:{" "}
                <Typography component={"span"} fontWeight={"bold"}>
                  {" "}
                  {(lenderState?.brokerage[brokerageIndex]?.criteria ?? []).length > 0
                    ? `${(lenderState?.brokerage[brokerageIndex]?.criteria ?? []).length}`
                    : "None"}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Dialog onClose={handleEditClose} open={editIsOpen} fullWidth maxWidth="md">
        <DialogTitle>Edit Brokerage Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} direction={"row"} alignItems={"center"} rowGap={"24px"} mb={5}>
            <Grid container item xs={12} columnSpacing={"32px"}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Max"
                  variant="filled"
                  type="text"
                  name="max"
                  value={currentlyEditingFee?.max}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(e) => {
                    const editedBrokerage = {
                      ...currentlyEditingFee,
                      [e.target.name]: parseFloat(e.target.value),
                    };

                    const newBrokerage = currentLenderBrokerages.map((f, i) => {
                      const { _id: brokerageId, ...rest } = f;
                      const { _id: id, ...brokerageObj } = editedBrokerage;

                      if (i === brokerageIndex) return brokerageObj;
                      else return rest;
                    });

                    setBrokerage(editedBrokerage);
                    dispatch(updateCurrentLenderBrokerages(newBrokerage));
                    dispatch(updateCurrentlyEditingFee(editedBrokerage));
                    handleDebounced(newBrokerage, 500);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Value"
                  variant="filled"
                  type="text"
                  name="value"
                  value={currentlyEditingFee?.value}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(e) => {
                    const editedBrokerage = {
                      ...currentlyEditingFee,
                      [e.target.name]: parseFloat(e.target.value),
                    };

                    const newBrokerage = currentLenderBrokerages.map((f, i) => {
                      const { _id: brokerageId, ...rest } = f;
                      const { _id: id, ...brokerageObj } = editedBrokerage;

                      if (i === brokerageIndex) return brokerageObj;
                      else return rest;
                    });

                    setBrokerage(editedBrokerage);
                    dispatch(updateCurrentLenderBrokerages(newBrokerage));
                    dispatch(updateCurrentlyEditingFee(editedBrokerage));
                    handleDebounced(newBrokerage, 500);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            direction={"row"}
            alignItems={"center"}
            {...(brokerage?.criteria && brokerage?.criteria.length > 0 && { mb: 8 })}
          >
            <Typography mb={"8px"} mr={"16px"}>
              Criteria
            </Typography>
            <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
              <Add />
            </IconButton>
          </Grid>

          {currentlyEditingFee?.criteria && currentlyEditingFee?.criteria.length > 0 && (
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              columnSpacing={"24px"}
              rowSpacing={"36px"}
              {...(currentlyEditingFee.criteria.length > 4 && { maxHeight: "370px", sx: { overflowY: "scroll" } })}
            >
              {currentlyEditingFee?.criteria.map((brokerageCriteria, index) => (
                <React.Fragment key={index}>
                  <CriteriaContent
                    key={index}
                    criteria={brokerageCriteria}
                    index={index}
                    lenderDetail={brokerage}
                    lender={lenderState}
                    editLender={editLender}
                    editLenderLoading={editLenderLoading}
                    keyString="brokerage"
                    setBrokerage={setBrokerage}
                    brokerageIndex={brokerageIndex}
                  />
                  {(currentlyEditingFee?.criteria ?? []).length - 1 !== index && (
                    <Grid item xs={12}>
                      <Divider flexItem />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={handleSaveChanges} sx={{ mt: "24px" }} disabled={editLenderLoading}>
              {editLenderLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});

export default Brokerage;
