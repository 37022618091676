import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
// import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import * as Yup from "yup";
import { fieldRequiredMessage } from "../../../constants";
import { updateAsset, assetSelector, saveAsset } from "../../../store/slices/applicationFormSlice";
import regex from "../../../utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "../../../utils/currencyMaskFormat";
import { isValidInput } from "../../../components/Utils/helperFunction";

export default function AssetValue({ quoteValues, setQuoteValue, fieldErrors, setFieldErrors }) {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const { assetValue, _id } = useSelector(assetSelector);

  const validationSchema = Yup.object({
    assetValue: Yup.string()
      .required(fieldRequiredMessage.assetValue)
      .matches(regex.allowOnlyNumber, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 7 digits"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      const isValid = isValidInput(e);
      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setQuoteValue({ ...quoteValues, [name]: e });
        await validateField(name, e);
      }
    },
    blurFn: async (fieldName, value) => {
      // handleMixpanelQuoteEvent(applicationId, "asset value", quoteAssetValue)
      dispatch(saveAsset({ [fieldName]: value }));
      if (Number(assetValue) !== Number(value)) {
        if (_id) dispatch(updateAsset({ _id, [fieldName]: value || 0 }));
      }
    },
  }

  useEffect(() => {
    setQuoteValue({ assetValue: assetValue } || 0)
  }, [assetValue])


  return (
    <TextField
      id={"assetValue"}
      fullWidth
      variant="filled"
      type="text"
      name="assetValue"
      label="Asset value"
      size="small"
      value={quoteValues.assetValue} // assetValue
      error={fieldErrors?.assetValue}
      helperText={fieldErrors?.assetValue}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      onChange={(event) => handle.onChangeField(event?.target?.value, "assetValue")}
      onBlur={(e) => handle.blurFn("assetValue", currencyMaskedValue(e.target.value))}
    />
  )
}