import React, { useMemo, useRef } from "react";
import {
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Popper,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import {
  Add,
  Close,
  //  Delete
} from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { PercentageFormatCustom } from "../../../utils/percentageMaskFormat";
import ProductCriteriaContent from "../ProductCriteriaContent";
import { productTypes } from "../../../constants";
// import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";

export function CustomPopper(props) {
  // Custom popper component for the autocomplete options to fit container
  const { anchorEl } = props;
  const width = anchorEl ? anchorEl.clientWidth : "auto";

  return <Popper {...props} style={{ maxHeight: "100px", width, overflowY: "auto" }} placement="bottom-start" />;
}

const AddProductDialog = ({ newProduct, setNewProduct, isAddOpen, handleClose, handleAddProduct }) => {
  const criteriaRefs = useRef([]); // TODO: For validations

  const productTypeOptions = productTypes.map((label) => ({ label, value: label }));

  const submitDisabled = useMemo(
    () =>
      !newProduct.name ||
      !newProduct.productType ||
      newProduct.name === "" ||
      newProduct.productType === "" ||
      !newProduct.criteria ||
      newProduct.criteria.length === 0 ||
      newProduct.criteria.some((criteria) => !criteria.object || !criteria.attribute || !criteria.condition),
    [newProduct.name, newProduct.productType, newProduct.criteria],
  );

  const handleAddCriteria = () => {
    const criteria = {
      tempId: uuidv4(),
      // productTier: productTierId,
      label: "",
      object: "",
      attribute: "",
      condition: "",
      value: 0,
    };
    const editedProductWithCriteria = [
      criteria,
      ...newProduct?.criteria?.map((c) => {
        const { _id, ...rest } = c;
        return rest;
      }),
    ];
    const editedProduct = {
      ...newProduct,
      criteria: editedProductWithCriteria,
    };
    setNewProduct(editedProduct);
  };

  // const handleAddInfo = () => setNewProduct({ ...newProduct, info: [...newProduct.info, ""] });

  const handleIsRateToggle = (e) => {
    if (newProduct.isRateToggle) setNewProduct({ ...newProduct, rate: 0, isRateToggle: e.target.checked });
    else setNewProduct({ ...newProduct, rate: [0, 0], isRateToggle: e.target.checked });
  };

  // const handleEditInfo = (e, index) => {
  //   const updatedInfo = newProduct.info.map((info, i) => {
  //     if (i === index) return e.target.value;
  //     else return info;
  //   });
  //   setNewProduct({ ...newProduct, info: updatedInfo });
  // };

  // const handleRemoveInfo = (index) => {
  //   const updatedInfo = newProduct.info.filter((info, i) => i !== index);
  //   setNewProduct({ ...newProduct, info: updatedInfo });
  // };

  return (
    <Dialog open={isAddOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle pb={"0"}>Add Product Details</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disableRipple={true}
      >
        <Close />
      </IconButton>
      <DialogContent pt={"0"}>
        <Grid container item xs={12} direction={"row"} spacing={"16px"} mt={"0"} alignItems={"center"}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              variant="outlined"
              margin="dense"
              type="text"
              name="name"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              value={newProduct.productType}
              options={productTypeOptions}
              onChange={(e, newValue) => setNewProduct({ ...newProduct, productType: newValue.value })}
              renderInput={(params) => (
                <TextField {...params} label="Product Type" variant="outlined" margin="dense" size="small" />
              )}
              PopperComponent={CustomPopper}
            />
          </Grid>

          <Grid
            item
            xs={3}
            {...(newProduct?.isRateToggle ||
              (Array.isArray(newProduct?.rate) && { container: true, columnSpacing: "8px" }))}
          >
            {!newProduct?.isRateToggle && !Array.isArray(newProduct?.rate) ? (
              <TextField
                label="Rate"
                fullWidth
                size="small"
                variant="outlined"
                margin="dense"
                type="text"
                InputProps={{
                  inputComponent: PercentageFormatCustom,
                  inputProps: {
                    prefix: "",
                  },
                }}
                value={newProduct?.rate}
                onChange={(e) => setNewProduct({ ...newProduct, rate: Number(e.target.value) })}
              />
            ) : (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Min"
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    InputProps={{
                      inputComponent: PercentageFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                    value={newProduct?.rate[0]}
                    onChange={(e) =>
                      setNewProduct({ ...newProduct, rate: [Number(e.target.value), newProduct.rate[1]] })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Max"
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    InputProps={{
                      inputComponent: PercentageFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                    value={newProduct?.rate[1]}
                    onChange={(e) =>
                      setNewProduct({ ...newProduct, rate: [newProduct.rate[0], Number(e.target.value)] })
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={newProduct?.isRateToggle || Array.isArray(newProduct?.rate)}
                  // control={<Switch checked={newProduct.isRateToggle} onChange={(e) => handleIsRateToggle(e)} />}
                  onChange={(e) => handleIsRateToggle(e)}
                />
              }
              label="Switch to Range"
            />
          </Grid>
        </Grid>
        {/* <Typography mb={2}>Info</Typography>
        <Grid container item xs={12} direction={"column"} mb={5} rowSpacing={"24px"}>
          {(newProduct?.info ?? []).map((info, index) => (
            <Grid key={index} item xs={12} alignItems={"center"}>
              <TextField size="small" sx={{ width: "90%" }} value={info} onChange={(e) => handleEditInfo(e, index)} />
              <IconButton disableRipple onClick={() => handleRemoveInfo(index)}>
                <Delete />
              </IconButton>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<Add />} sx={{ width: "fit-content" }} onClick={handleAddInfo}>
              ADD INFO
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          direction={"row"}
          alignItems={"center"}
          mb={"8px"}
          // {...(newProduct?.criteria && newProduct?.criteria.length > 0 && { mb: 8 })}
        >
          <Typography mb={"8px"} mr={"16px"}>
            Criteria
          </Typography>
          <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
            <Add />
          </IconButton>
        </Grid>
        {newProduct?.criteria && newProduct?.criteria.length > 0 && (
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            columnSpacing={"24px"}
            rowSpacing={"5px"}
            // {...(newProduct.criteria.length > 4 && { maxHeight: "370px", sx: { overflowY: "scroll" } })}
          >
            {newProduct?.criteria.map((productCriteria, index) => (
              <React.Fragment key={productCriteria?._id || productCriteria?.tempId}>
                <ProductCriteriaContent
                  key={productCriteria?._id || productCriteria?.tempId}
                  keyId={productCriteria?._id || productCriteria?.tempId}
                  criteria={productCriteria}
                  index={index}
                  product={newProduct}
                  setProduct={setNewProduct}
                  criteriaRefs={criteriaRefs}
                />
                {(newProduct?.criteria ?? []).length - 1 !== index && (
                  <Grid item xs={12}>
                    <Divider flexItem />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Close</Button> */}
        <Button onClick={handleAddProduct} variant="contained" disabled={submitDisabled}>
          ADD NEW PRODUCT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductDialog;
