import React from "react";
import { Box, Chip, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
// import { debounce } from "lodash";
import { LENDER_ACTIONS } from "../../../../constants";
import PageLoader from "../../../../components/Application/PageLoader";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";

const ActionSection = React.memo(({ lender }) => { // setLender
  // const [editLender, { data: editLenderData, isLoading: editLenderLoading }] = useEditLenderMutation();
  const [editLender, { isLoading: editLenderLoading }] = useEditLenderMutation();

  // useEffect(() => {
  //   if (editLenderData && !editLenderLoading) debounce(() => setLender(editLenderData), 500);
  // }, [editLenderLoading]);

  const lenderId = lender?._id;
  const handleActionListChange = (event) => {
    // for list enums on select component
    const {
      target: { value },
    } = event;
    const actions = typeof value === "string" ? value.split(",") : value;
    // setLender({
    //   ...lender,
    //   // On autofill we get a stringified value.
    //   actions,
    // });
    // debounce(() => {
      editLender({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
          actions,
        },
      });
    // }, 1000)();
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Actions Needed</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        alignContent={"center"}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          xs={9}
          direction="column"
          style={{ margin: "0px 0px 7px 0px" }}
          rowGap={"16px"}
        >
          {editLenderLoading ? (
            <PageLoader text="Actions updating..." />
          ) : (
            <Select
              multiple
              fullWidth
              size="small"
              value={lender?.actions ?? []}
              onChange={handleActionListChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {(selected ?? []).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {LENDER_ACTIONS.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ActionSection;
