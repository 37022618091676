import React from "react";

import { useDispatch } from "react-redux";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import { dollarStringOptions } from "../../constants";
import { sendDisclosure } from "../../store/slices/applicationFormSlice";

const BmaSendDisclosure = ({
  productDetails,
  application,
  handleOpenDisclosureDialog,
  handleCloseDisclosureDialog,
  disclosureFields,
  setDisclosureFields,
  openDisclosureDialog,
  updateDisclosureRate,
  loanDetails,
}) => {
  const dispatch = useDispatch();

  // const handle = {
  //   saveQuote: async () => {
  //     if (loanDetails?._id)
  //       await dispatch(
  //         updateLoanDetails(
  //           merge({
  //             ...loanDetails,
  //             ...{
  //               loanAmount: productDetails?.loanAmount,
  //               rate: productDetails?.rate,
  //               repayments: productDetails?.repayments,
  //               brokerage: productDetails?.brokerage,
  //               // fees: handleFees(),
  //             },
  //           }),
  //         ),
  //       ).unwrap();
  //   },
  // };

  return (
    <React.Fragment>
      <Dialog open={openDisclosureDialog} onClose={handleCloseDisclosureDialog}>
        <DialogTitle>Send Proposal Disclosure Statement</DialogTitle>
        <DialogContent>
          <DialogContentText>Please review and update the details below.</DialogContentText>
          <Stack direction="column">
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Indicative rate"
              variant="outlined"
              value={disclosureFields.rate}
              onChange={(e) => {
                // setDisclosureFields({
                //   ...disclosureFields,
                //   rate: e.target.value,
                // });
                updateDisclosureRate({ rate: e.target.value });
              }}
            />
            {/* <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Indicative monthly repayment"
              variant="outlined"
              value={disclosureFields.repayments}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  repayments: e.target.value,
                });
              }}
            /> */}
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Origination fee"
              variant="outlined"
              value={disclosureFields.originationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  originationFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Inspection fee"
              variant="outlined"
              value={disclosureFields.inspectionFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  inspectionFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Application fee"
              variant="outlined"
              value={disclosureFields.applicationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  applicationFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="rate"
              label="Asset registration fee"
              variant="outlined"
              value={disclosureFields.registrationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  registrationFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Lender monthly account keeping fee"
              variant="outlined"
              value={disclosureFields.accountFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  accountFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Referral fee"
              variant="outlined"
              value={disclosureFields.referralFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  referralFee: e.target.value,
                });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Referrer"
              variant="outlined"
              value={disclosureFields.referrer}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  referrer: e.target.value,
                });
              }}
            />

            {/* Repayments */}
            <Grid item sm={12} spacing={1} direction="row" style={{ margin: "15px 0px 0px 0px" }}>
              <Typography
                sx={{ display: "inline" }}
                variant="body2"
                style={{
                  marginRight: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  Repayments:
                </Typography>
                <span>{disclosureFields?.repayments?.toLocaleString("en-US", dollarStringOptions) || "0"}/Mth</span>
              </Typography>

              <Typography
                sx={{ display: "inline" }}
                variant="body2"
                style={{
                  marginRight: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  Effective Rate:
                </Typography>
                <span>{(1 * disclosureFields?.effectiveRate).toFixed(2)}%</span>
              </Typography>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisclosureDialog}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseDisclosureDialog();
              dispatch(
                sendDisclosure({
                  ...disclosureFields,
                  ...{
                    applicationId: application._id,
                    lenderType: productDetails?.lender,
                    effectiveRate: round(disclosureFields?.effectiveRate, 2),
                    rate: +disclosureFields?.rate,
                  },
                }),
              );
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default BmaSendDisclosure;