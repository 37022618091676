import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Close, Delete, Edit, FileCopyTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import {
  // clearState,
  lenderSelector,
  // setCurrentLender,
  updateCurrentLenderLoadings,
  updateCurrentlyEditingFee,
  setEditLenderPayload,
} from "../../../../store/slices/lenderSlice";
import { fCurrency } from "../../../../utils/formatNumber";
import { NumericFormatCustom } from "../../../../utils/currencyMaskFormat";
import { LOADING_VALUE_TYPES } from "../../../../constants";
import CriteriaContent from "../CriteriaContent";

const Loading = React.memo(({ data, index: loadingIndex, editLender, lender, editLenderLoading }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const lenderId = lender?._id;
  const [loading, setLoading] = useState(null);

  const {
    currentlyEditingFee,
    currentLenderLoadings,
    editLenderPayload,
    lender: lenderState,
  } = useSelector(lenderSelector);

  useEffect(() => {
    if (data) setLoading(data);
  }, [data]);

  const [editIsOpen, setEditIsOpen] = useState(false);

  const handleEditClose = () => {
    // dispatch(clearState());
    // dispatch(setCurrentLender(lenderState ?? lender));
    // setLoading(lenderState?.loading[loadingIndex]);
    setEditIsOpen(false);
  };

  const handleEditOpen = () => {
    dispatch(updateCurrentlyEditingFee(data));
    setEditIsOpen(true);
  };
  const handleEditDetails = (loading) => {
    // debounce(
    //   () =>
    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          loading,
        },
      }),
    );
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     loading,
    //   },
    // });
    //   500,
    // )();
  };

  const handleDebounced = useCallback(debounce(handleEditDetails, 500), []);

  const handleDeleteLoading = () => {
    const updatedLoading = lenderState.loading.filter((l) => l._id !== loading._id);
    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        loading: updatedLoading,
      },
    });
  };

  const handleCloneLoading = () => {
    const { _id, ...rest } = loading;
    rest.criteria = rest.criteria.map(({ _id, createdAt, updatedAt, __v, ...rest }) => rest);

    const newLoadings = [...lenderState.loading, rest];

    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        loading: newLoadings,
      },
    });
  };

  const handleAddCriteria = () => {
    const criteria = {
      productTier: null,
      label: "",
      object: "",
      attribute: "",
      condition: "",
      value: 0,
    };
    const { _id, ...rest } = loading;
    const editedLoadingWithCriteria = [
      criteria,
      ...currentlyEditingFee?.criteria?.map((c) => {
        const { _id, ...rest } = c;
        return rest;
      }),
    ];
    const editedLoading = {
      ...rest,
      criteria: editedLoadingWithCriteria,
    };
    setLoading(editedLoading);
    dispatch(updateCurrentlyEditingFee(editedLoading));
    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          loading: [
            ...lenderState.loading.map((f) => {
              const { _id: loadingId, ...rest } = f;
              if (loadingId === currentlyEditingFee._id) return editedLoading;
              return rest;
            }),
          ],
        },
      }),
    );

    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     loading: [
    //       ...lender.loading.map((f) => {
    //         const { _id: loadingId, ...rest } = f;
    //         if (loadingId === loading._id) return editedLoading;
    //         return rest;
    //       }),
    //     ],
    //   },
    // });
  };

  const handleSaveChanges = () => {
    editLender(editLenderPayload).then((res) => {
      if (res?.data && !res.error) {
        handleEditClose();
        enqueueSnackbar("Loading updated successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar("Failed to update loading", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper elevation={0} variant="outlined" sx={{ height: "100%" }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container xs={12} justifyContent={"end"}>
                <Stack direction="row" gap={"8px"}>
                  <Tooltip title="Clone" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleCloneLoading}
                      disabled={editLenderLoading}
                    >
                      <FileCopyTwoTone
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleEditOpen}
                      disabled={editLenderLoading}
                    >
                      <Edit
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleDeleteLoading}
                      disabled={editLenderLoading}
                    >
                      <Delete
                        fontSize="small"
                        sx={{ ":hover": { color: "red", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Typography variant="h5" component="div" my={1.5}>
                {lenderState?.loading[loadingIndex]?.name ?? ""}
              </Typography>
              <Stack direction={"column"} rowGap={"12px"} mb={5}>
                <Typography>
                  Loading Value:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {lenderState?.loading[loadingIndex]?.valueType !== "Percentage"
                      ? fCurrency(lenderState?.loading[loadingIndex]?.value.toFixed(2) ?? 0)
                      : lenderState?.loading[loadingIndex]?.value}
                  </Typography>
                </Typography>
                <Typography>
                  Value Type:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {(lenderState?.loading[loadingIndex]?.valueType ?? "").charAt(0).toUpperCase() +
                      (lenderState?.loading[loadingIndex]?.valueType ?? "").slice(1)}
                  </Typography>
                </Typography>
              </Stack>
              <Typography>
                Criteria:{" "}
                <Typography component={"span"} fontWeight={"bold"}>
                  {" "}
                  {(lenderState?.loading[loadingIndex]?.criteria ?? []).length > 0
                    ? `${(lenderState?.loading[loadingIndex]?.criteria ?? []).length}`
                    : "None"}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Dialog onClose={handleEditClose} open={editIsOpen} fullWidth maxWidth="md">
        <DialogTitle>Edit Loading Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} direction={"row"} alignItems={"center"} rowGap={"24px"} mb={5}>
            <Grid container item xs={12} columnSpacing={"32px"}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  variant="filled"
                  type="text"
                  name="name"
                  value={currentlyEditingFee?.name}
                  onChange={(e) => {
                    const editedLoading = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const newLoading = currentLenderLoadings.map((f, i) => {
                      const { _id: loadingId, ...rest } = f;
                      const { _id: id, ...loadingObj } = editedLoading;

                      if (i === loadingIndex) return loadingObj;
                      else return rest;
                    });
                    setLoading(editedLoading);
                    dispatch(updateCurrentLenderLoadings(newLoading));
                    dispatch(updateCurrentlyEditingFee(editedLoading));
                    handleDebounced(newLoading, 500);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={"32px"}>
              <Grid item xs={6}>
                <TextField
                  id={"value"}
                  fullWidth
                  size="small"
                  variant="filled"
                  type="text"
                  name="value"
                  label="Loading Value"
                  value={currentlyEditingFee?.value}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(e) => {
                    const editedLoading = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const newLoading = currentLenderLoadings.map((f, i) => {
                      const { _id: loadingId, ...rest } = f;
                      const { _id: id, ...loadingObj } = editedLoading;

                      if (i === loadingIndex) return loadingObj;
                      else return rest;
                    });

                    setLoading(editedLoading);
                    dispatch(updateCurrentLenderLoadings(newLoading));
                    dispatch(updateCurrentlyEditingFee(editedLoading));
                    handleDebounced(newLoading, 500);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Value Type"
                  select
                  variant="filled"
                  name="valueType"
                  value={currentlyEditingFee?.valueType}
                  onChange={(e) => {
                    const editedLoading = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };

                    const newLoading = currentLenderLoadings.map((f, i) => {
                      const { _id: loadingId, ...rest } = f;
                      const { _id: id, ...loadingObj } = editedLoading;

                      if (i === loadingIndex) return loadingObj;
                      else return rest;
                    });

                    setLoading(editedLoading);
                    dispatch(updateCurrentLenderLoadings(newLoading));
                    dispatch(updateCurrentlyEditingFee(editedLoading));
                    handleDebounced(newLoading, 500);
                  }}
                >
                  {LOADING_VALUE_TYPES.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value.charAt(0).toUpperCase() + value.slice(1)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            direction={"row"}
            alignItems={"center"}
            {...(loading?.criteria && loading?.criteria.length > 0 && { mb: 8 })}
          >
            <Typography mb={"8px"} mr={"16px"}>
              Criteria
            </Typography>
            <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
              <Add />
            </IconButton>
          </Grid>

          {currentlyEditingFee?.criteria && currentlyEditingFee?.criteria.length > 0 && (
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              columnSpacing={"24px"}
              rowSpacing={"36px"}
              {...(currentlyEditingFee.criteria.length > 4 && { maxHeight: "370px", sx: { overflowY: "scroll" } })}
            >
              {currentlyEditingFee?.criteria.map((loadingCriteria, index) => (
                <React.Fragment key={index}>
                  <CriteriaContent
                    key={index}
                    criteria={loadingCriteria}
                    index={index}
                    lenderDetail={loading}
                    lender={lenderState}
                    editLender={editLender}
                    editLenderLoading={editLenderLoading}
                    keyString="loading"
                    setLoading={setLoading}
                    loadingIndex={loadingIndex}
                  />
                  {(currentlyEditingFee?.criteria ?? []).length - 1 !== index && (
                    <Grid item xs={12}>
                      <Divider flexItem />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={handleSaveChanges} sx={{ mt: "24px" }} disabled={editLenderLoading}>
              {editLenderLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});

export default Loading;
