import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { allLendersApi } from "./apiSlice";
import { reusableFetch } from "../../utils/useAxios";

export const getLender = createAsyncThunk("lender/getLender", async ({ lenderId }, { rejectWithValue }) => {
  const lender = await reusableFetch(`lender/${lenderId}`, "GET", {}, rejectWithValue);
  return lender;
});

const initialState = {
  lender: null,
  currentLenderFees: null,
  currentLenderLoadings: null,
  currentLenderBrokerages: null,
  isGettingLenderData: false,
  requiredFieldOptions: [],
  currentlyEditingCommonCriteria: [],
  currentlyEditingFee: null,
  editLenderPayload: null,
  originalCommonCriteria: [],
};

export const lenderSlice = createSlice({
  name: "lender",
  initialState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
    clearState: () => initialState,
    setCurrentLender(state, { payload }) {
      state.lender = payload;
      state.currentLenderFees = payload.fees;
      state.currentLenderLoadings = payload.loading;
      state.currentLenderBrokerages = payload.brokerage;
    },
    updateCurrentLenderFees: (state, { payload }) => {
      state.currentLenderFees = payload;
    },
    updateCurrentLenderLoadings: (state, { payload }) => {
      state.currentLenderLoadings = payload;
    },
    updateCurrentLenderBrokerages: (state, { payload }) => {
      state.currentLenderBrokerages = payload;
    },
    updateCurrentlyEditingCommonCriteria: (state, { payload }) => {
      state.currentlyEditingCommonCriteria = payload;
    },
    updateCurrentlyEditingFee: (state, { payload }) => {
      state.currentlyEditingFee = payload;
    },
    setEditLenderPayload: (state, { payload }) => {
      state.editLenderPayload = payload;
    },
    setOriginalCommonCriteria: (state, { payload }) => {
      state.originalCommonCriteria = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLender.pending, (state, { payload }) => {
        state.isGettingLenderData = true;
      })
      .addCase(getLender.fulfilled, (state, { payload }) => {
        state.isGettingLenderData = false;
        state.lender = payload?.data?.data?.lender;
        state.requiredFieldOptions = payload?.data?.data?.requiredFieldOptions || [];
        state.currentLenderFees = state.lender.fees;
        state.currentLenderLoadings = state.lender.loading;
        state.currentLenderBrokerages = state.lender.brokerage;
      })
      .addCase(getLender.rejected, (state, { payload }) => {
        state.isGettingLenderData = false;
      });
    // Using builder pattern here since object notation doesn't work with RTK query apiSlice
    builder.addMatcher(
      allLendersApi.endpoints.editLender.matchFulfilled ||
        allLendersApi.endpoints.updateLenderArrayFields.matchFulfilled,
      (state, { payload }) => {
        if (payload._id) {
          state.lender = payload;
          state.currentLenderFees = payload.fees;
          state.currentLenderLoadings = payload.loading;
          state.currentLenderBrokerages = payload.brokerage;
        }
      },
    );
  },
});

export const {
  saveValue,
  clearState,
  setCurrentLender,
  setEditLenderPayload,
  setOriginalCommonCriteria,
  updateCurrentlyEditingCommonCriteria,
  updateCurrentlyEditingFee,
  updateCurrentLenderFees,
  updateCurrentLenderLoadings,
  updateCurrentLenderBrokerages,
} = lenderSlice.actions;
export const lenderSelector = (state) => state.lender;
