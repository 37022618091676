import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import _ from "lodash";
import { criteriaObjects, criteriaFunctions } from "../../../constants";
import CRITERIA from "../../../utils/criteria";
import ProductCriteriaInput from "../ProductCriteriaInput";

const ProductCriteriaContent = ({ criteria, index, keyId, product, setProduct, criteriaRefs }) => {
  const [criteriaType, setCriteriaType] = useState(null);
  const [isFieldDirty, setIsFieldDirty] = useState(false);

  useEffect(() => {
    const { _id, tempId, updatedAt, createdAt, productTier, ...rest } = criteria;
    let criteriaKey = criteriaObjects.find((key) => {
      // Weird bug, the CRITERIA[key] contains an _id when it shouldn't
      const { _id, tempId, ...toCompare } = { ...CRITERIA[key] };
      return _.isEqual(toCompare, rest);
    });

    if (!criteriaKey) {
      if (typeof criteria?.value === "object" && criteria?.value instanceof Object && !Array.isArray(criteria?.value)) {
        const criteriaFunctionKeys = criteriaFunctions.filter((key) => CRITERIA[key].length > 1);
        criteriaKey = criteriaFunctionKeys.find((key) =>
          _.isEqual(CRITERIA[key](criteria?.value?.min, criteria?.value?.max), rest),
        );
        if (!criteriaKey) {
          // for date values
          const { attribute, condition, object } = rest;
          criteriaKey = criteriaFunctionKeys.find((key) => {
            const {
              attribute: criteriaAttribute,
              condition: criteriaCondition,
              object: criteriaObject,
            } = CRITERIA[key](criteria?.value?.min, criteria?.value?.max);
            return _.isEqual(
              { attribute: criteriaAttribute, condition: criteriaCondition, object: criteriaObject },
              { attribute, condition, object },
            );
          });
        }
      } else {
        const criteriaFunctionKeys = criteriaFunctions.filter((key) => CRITERIA[key].length === 1);
        criteriaKey = criteriaFunctionKeys.find((key) => _.isEqual(CRITERIA[key](criteria?.value), rest));
        if (!criteriaKey) {
          const { attribute, condition, object } = rest;
          criteriaKey = criteriaFunctionKeys.find((key) => {
            const {
              attribute: criteriaAttribute,
              condition: criteriaCondition,
              object: criteriaObject,
            } = CRITERIA[key](criteria?.value);
            return _.isEqual(
              { attribute: criteriaAttribute, condition: criteriaCondition, object: criteriaObject },
              { attribute, condition, object },
            );
          });
        }
      }
    }
    setCriteriaType(criteriaKey);
  }, []);

  const handleCriteriaType = (e) => {
    setCriteriaType(e.target.value);
    setIsFieldDirty(true);
  };

  const handleInputChange = (e, newValue) => setCriteriaType(newValue);

  const handleAutoComplete = (e) => setIsFieldDirty(true);

  const handleRemoveCriteria = () => {
    const updatedCriteria = product.criteria.filter((c, i) => {
      const criterionIndex = c._id ?? c.tempId;
      return criterionIndex !== keyId;
    });

    const updatedProduct = {
      ...product,
      criteria: updatedCriteria,
    };

    setProduct(updatedProduct);
  };

  return (
    <React.Fragment key={keyId}>
      <Grid item xs={5}>
        <Autocomplete
          value={criteriaType}
          options={Object.keys(CRITERIA).map((key) => key)}
          getOptionLabel={(option) => option || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Criteria Type"
              onChange={handleCriteriaType}
              variant="outlined"
              margin="dense"
              fullWidth
              // sx={{ height: "100%" }}
            />
          )}
          onChange={handleAutoComplete}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid container item xs={6} columnSpacing={"16px"} rowGap={"16px"} alignItems={"stretch"}>
        {criteriaType && (
          <ProductCriteriaInput
            criteria={criteria}
            criteriaType={criteriaType}
            isFieldDirty={isFieldDirty}
            product={product}
            setProduct={setProduct}
            // index={keyId}
            index={index}
            keyId={keyId}
            ref={(el) => (criteriaRefs.current[keyId] = el)}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton disableRipple onClick={handleRemoveCriteria}>
          <Delete />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
};

export default ProductCriteriaContent;
