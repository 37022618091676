import React, { useEffect, useMemo, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { Box, Chip, Grid, MenuItem, Select, TextField } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import {
  AGE_OF_ASSETS_AT_END,
  ConditionFieldOptions,
  criteriaFunctions,
  criteriaObjects,
  employmentTypes,
  FUNCTIONS_DATE_USING_MONTHS,
  FUNCTIONS_DATE_USING_YEARS,
  FUNCTIONS_DATE_VALUES,
  FUNCTIONS_PARSE_TO_STRING,
  FUNCTIONS_WITH_ENUMS,
  livingArrangementsTypes,
  typeOfSaleList,
} from "../../../constants";
import { lenderSelector } from "../../../store/slices/lenderSlice";
import assets from "../../../assetTypes.json";
import consumerAssetTypes from "../../../utils/consumerAssetTypes";
import CRITERIA, {
  buildValidationSchema,
  getInputValidationRules,
  inputValidationRules,
} from "../../../utils/criteria";
import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";

const ProductCriteriaInput = forwardRef(({ criteria, criteriaType, isFieldDirty, product, setProduct, keyId, index }, ref) => {
  const { lender: lenderState } = useSelector(lenderSelector);
  const [valueFieldError, setValueFieldError] = useState("");
  const [minValueFieldError, setMinValueFieldError] = useState("");
  const [maxValueFieldError, setMaxValueFieldError] = useState("");

  useEffect(() => {
    // valueRef.current = "";
    // maxValueRef.current = "";
    // minValueRef.current = "";
    setValueFieldError("");
    setMaxValueFieldError("");
    setMinValueFieldError("");
    validValueRef.current = true;
    validMaxValueRef.current = true;
    validMinValueRef.current = true;

    if (criteriaObjects.includes(criteriaType)) {
      // Load object criteria for saving since it doesn't rely on input field onChange
      let updatedCriteria = { ...CRITERIA[criteriaType] };

      if (criteria?.tempId) {
        updatedCriteria.tempId = criteria.tempId;
      } else if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }
      
      // Not sure why _id gets inserted to the updatedCriteria created from new criteria
      if (updatedCriteria.tempId && updatedCriteria._id) {
        delete updatedCriteria._id;
      }

      const criteriaArr = product.criteria.map((c, i) => {
        const criterionIndex = c._id ?? c.tempId;
        // if (criterionIndex === index) {
        if (criterionIndex === keyId) {
          return {
            ...updatedCriteria,
            ...(c?.productTier && { productTier: c.productTier })
          }
        } else {
          return c
        }
      });
      const updatedProduct = {
        ...product,
        criteria: criteriaArr,
      };
      setProduct(updatedProduct);
    }
  }, [criteriaType]);

  let validationSchema = null;

  const validationRules = criteriaFunctions.includes(criteriaType)
    ? getInputValidationRules(CRITERIA[criteriaType], inputValidationRules)
    : null;

  let inputProps = null;

  if (validationRules !== null && Object.keys(validationRules).length > 0) {
    validationSchema = buildValidationSchema(validationRules);

    if (validationRules?.type === "numeric") {
      inputProps = { inputComponent: NumericFormat };
    } else if (validationRules?.type === "money") {
      inputProps = { inputComponent: NumericFormatCustom };
    }
  }

  const listName = FUNCTIONS_WITH_ENUMS.find((name) => name === criteriaType);

  const isComputedValueString = FUNCTIONS_PARSE_TO_STRING.some((prefix) => criteriaType?.includes(prefix));

  const isValueDate = FUNCTIONS_DATE_VALUES.some(
    (prefix) =>
      criteriaType?.includes(prefix) && !AGE_OF_ASSETS_AT_END.some((criteriaName) => criteriaName === criteriaType),
  );

  const initialEnumValues = () => {
    if (listName && !Array.isArray(criteria?.value)) return [criteria?.value];
    else if (listName && Array.isArray(criteria?.value)) return criteria?.value;
    else return [];
  };

  const isValueDateUsingMonths = FUNCTIONS_DATE_USING_MONTHS.some((prefix) => criteriaType?.includes(prefix));

  const isValueDateUsingYears = FUNCTIONS_DATE_USING_YEARS.some((prefix) => criteriaType?.includes(prefix));

  const isAgeOfAsset = FUNCTIONS_DATE_VALUES.some(
    (name) => criteriaType.includes(name) && !AGE_OF_ASSETS_AT_END.some((type) => type === criteriaType),
  );
  const valueDate = () => {
    if (isValueDateUsingMonths && !isAgeOfAsset) return moment(moment()).diff(criteria?.value, "M");
    else if (isValueDateUsingMonths && isAgeOfAsset) {
      if (criteriaType.includes("GreaterThan")) {
        const matches = (criteria?.label ?? "").match(/>(\d+)\s*mths/);
        return matches && matches[1] ? parseInt(matches[1]) : 0;
      } else if (criteriaType.includes("LessThan")) {
        const matches = (criteria?.label ?? "").match(/<(\d+)\s*mths/);
        return matches && matches[1] ? parseInt(matches[1]) : 0;
      }
    } else if (isValueDateUsingYears)
      return criteria?.value === 0 ? null : moment(moment()).diff(criteria?.value, "years").toString();
  };

  const valueDateMinMax = () => {
    let min;
    let max;
    if (isValueDateUsingMonths && !isAgeOfAsset) {
      min = moment(moment()).diff(criteria?.value?.max, "M");
      max = moment(moment()).diff(criteria?.value?.min, "M");
    } else if (isValueDateUsingMonths && isAgeOfAsset) {
      if (criteriaType.includes("Between")) {
        const matches = (criteria?.label ?? "").match(/(\d+)\s*-\s*(\d+)\s*mths/);
        if (matches && matches[1] && matches[2]) {
          min = parseInt(matches[1]);
          max = parseInt(matches[2]);
        } else {
          min = 0;
          max = 0;
        }
      }
    } else if (isValueDateUsingYears) {
      min = moment(moment()).diff(criteria?.value?.max, "years");
      max = moment(moment()).diff(criteria?.value?.min, "years");
    }
    return {
      min,
      max,
    };
  };

  const [value, setValue] = useState((!isValueDate ? criteria?.value : valueDate()) ?? null);
  const [enumValues, setEnumValues] = useState(initialEnumValues());
  const [minValue, setMinValue] = useState(isValueDate ? valueDateMinMax().min : criteria?.value?.min ?? null);
  const [maxValue, setMaxValue] = useState(isValueDate ? valueDateMinMax().max : criteria?.value?.max ?? null);

  const valueRef = useRef(value);
  // const enumValuesRef = useRef(enumValues);
  const minValueRef = useRef(minValue);
  const maxValueRef = useRef(maxValue);

  const [validValue, setValidValue] = useState(false);
  const [validMinValue, setValidMinValue] = useState(false);
  const [validMaxValue, setValidMaxValue] = useState(false);

  const validValueRef = useRef(validValue);
  const validMinValueRef = useRef(validMinValue);
  const validMaxValueRef = useRef(validMaxValue);

  let listOptions = []; // for list enums on select component

  switch (
  listName // for list enums on select component
  ) {
    case "typeOfSale":
      listOptions = Object.values(typeOfSaleList).map((value) => value);
      break;
    case "livingArrangements":
      listOptions = Object.values(livingArrangementsTypes).map((value) => value);
      break;
    case "employmentType":
      listOptions = Object.values(employmentTypes).map((value) => value);
      break;
    case "assetType":
      if (lenderState?.type === "commercial") {
        listOptions = assets.assetTypes.map((asset) => asset.label);
      } else {
        // For consumer and personal
        listOptions = consumerAssetTypes.map((asset) => asset.label);
      }

      break;
    case "assetCondition":
      listOptions = ConditionFieldOptions;
      break;
    default:
      break;
  }

  const validateInput = (value, valueType) => {
    if (validationSchema !== null) {
      try {
        validationSchema.validateSync(value);
        if (valueType === "value") {
          setValueFieldError("");
          setValidValue(true);
          validValueRef.current = true;
        } else if (valueType === "minValue") {
          if (maxValueRef.current && minValueRef.current >= maxValueRef.current) {
            setMinValueFieldError("Minimum value should not be greater or equal to maximum value");
            setValidMinValue(false);
            validMinValueRef.current = false;
            return;
          }
          setMinValueFieldError("");
          setValidMinValue(true);
          validMinValueRef.current = true;
        } else if (valueType === "maxValue") {
          if (minValueRef.current && minValueRef.current >= maxValueRef.current) {
            setMaxValueFieldError("Maximum value should not be lesser or equal to minimum value");
            setValidMaxValue(false);
            validMaxValueRef.current = false;
            return;
          }
          setMaxValueFieldError("");
          setValidMaxValue(true);
          validMaxValueRef.current = true;
        }
      } catch (err) {
        if (valueType === "value") {
          setValueFieldError(err.message);
          setValidValue(false);
          validValueRef.current = false;
        } else if (valueType === "minValue") {
          setMinValueFieldError(err.message);
          setValidMinValue(false);
          validMinValueRef.current = false;
        } else if (valueType === "maxValue") {
          setMaxValueFieldError(err.message);
          setValidMaxValue(false);
          validMaxValueRef.current = false;
        }
      }
    }
  };

  const validateSubmission = () => {
    // TODO: Add validations for criteria inputs with dropdowns

    if (!validationSchema) {
      // Allow input if no validation schema
      return true;
    }

    if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 2) {
      validateInput(minValueRef.current, "minValue");
      validateInput(maxValueRef.current, "maxValue");

      if (validMaxValueRef.current && validMinValueRef.current) {
        return true;
      }
      validValueRef.current = false;
      return false;
    }
    if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 1) {
      validateInput(valueRef.current, "value");

      if (validValueRef.current) {
        return true;
      }
      validValueRef.current = false;
      return false;
    }
    validValueRef.current = false;
    return false;
  };

  useImperativeHandle(ref, () => ({
    validateSubmission,
  }));

  const handleListChange = (event) => {
    // for list enums on select component
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valuesArr = typeof value === "string" ? value.split(",") : value;
    setEnumValues(valuesArr);
    let updatedCriteria = CRITERIA[criteriaType](valuesArr);

    if (criteria?.tempId) {
      updatedCriteria.tempId = criteria.tempId;
    } else if (criteria?._id) {
      updatedCriteria._id = criteria._id;
    }

    const criteriaArr = product.criteria.map((c, i) => {
      const criterionIndex = c._id ?? c.tempId;
      // if (criterionIndex === index) return updatedCriteria;
      if (criterionIndex === keyId) return updatedCriteria;
      else return c;
    });
    const updatedProduct = {
      ...product,
      criteria: criteriaArr,
    };
    setProduct(updatedProduct);
  };

  const handleMinValue = (e) => {
    if (maxValue !== "") {
      let updatedCriteria = CRITERIA[criteriaType](parseFloat(e.target.value), parseFloat(maxValue));

      if (criteria?.tempId) {
        updatedCriteria.tempId = criteria.tempId;
      } else if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }

      const criteriaArr = product.criteria.map((c, i) => {
        const criterionIndex = c._id ?? c.tempId;
        // if (criterionIndex === index) return updatedCriteria;
        if (criterionIndex === keyId) return updatedCriteria;
        else return c;
      });
      const updatedProduct = {
        ...product,
        criteria: criteriaArr,
      };
      setProduct(updatedProduct);
    }
  };

  const handleMaxValue = (e) => {
    if (minValue !== "") {
      let updatedCriteria = CRITERIA[criteriaType](parseFloat(minValue), parseFloat(e.target.value));

      if (criteria.tempId) {
        updatedCriteria.tempId = criteria.tempId;
      } else if (criteria?._id) {
        updatedCriteria._id = criteria._id;
      }

      const criteriaArr = product.criteria.map((c, i) => {
        const criterionIndex = c._id ?? c.tempId;
        // if (criterionIndex === index) return updatedCriteria;
        if (criterionIndex === keyId) return updatedCriteria;
        else return c;
      });
      const updatedProduct = {
        ...product,
        criteria: criteriaArr,
      };
      setProduct(updatedProduct);
    }
  };

  const handleValue = (e) => {
    const regexWithSpaces = /^[a-zA-Z0-9. ]*$/;
    const value = isComputedValueString && !regexWithSpaces.test(e.target.value) ? "" : e.target.value;
    setValue(value);
    valueRef.current = value;

    validateInput(value, "value");

    if (validValueRef.current) {
      if (value !== "") {
        const updatedValue = isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value);
        let updatedCriteria = CRITERIA[criteriaType](updatedValue);

        if (criteria.tempId) {
          updatedCriteria.tempId = criteria.tempId;
        } else if (criteria?._id) {
          updatedCriteria._id = criteria._id;
        }

        const criteriaArr = product.criteria.map((c, i) => {
          const criterionIndex = c._id ?? c.tempId;
          // if (criterionIndex === index) return updatedCriteria;
          if (criterionIndex === keyId) return updatedCriteria;
          else return c;
        });
        const updatedProduct = {
          ...product,
          criteria: criteriaArr,
        };
        setProduct(updatedProduct);
      }
    }
  };

  const getComputedDate = useMemo(() => {
    if (criteriaType) {
      if (criteriaObjects.includes(criteriaType)) return CRITERIA[criteriaType].value;
      else if (CRITERIA[criteriaType]) {
        if (CRITERIA[criteriaType].length === 1) return CRITERIA[criteriaType](value === "" ? 0 : value).value;
        else {
          const computedDate = () => {
            let min;
            let max;
            if (minValue === "" || 0 || !minValue) min = criteria?.value?.min;
            else min = CRITERIA[criteriaType](minValue, maxValue).value.min;
            if (maxValue === "" || 0 || !maxValue) max = criteria?.value?.max;
            else max = CRITERIA[criteriaType](minValue, maxValue).value.max;
            return {
              min,
              max,
            };
          };
          return computedDate();
        }
      }
      return null;
    }
    return null;
  }, [value, minValue, maxValue, criteriaType]);

  useEffect(() => {
    if (isFieldDirty) {
      setValue("");
      setMaxValue("");
      setMinValue("");
      setEnumValues([]);
    }
  }, [isFieldDirty, criteriaType]);

  if (criteriaFunctions.includes(criteriaType) && CRITERIA[criteriaType].length === 2) {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          <TextField
            fullWidth
            // {...(!isComputedValueString && {
            //   InputProps: {
            //     inputComponent: NumericFormatCustom,
            //   },
            // })}
            label="Minimum Value"
            variant="outlined"
            type="text"
            name="minVal"
            value={minValue ?? ""}
            size="small"
            margin="dense"
            error={!!minValueFieldError}
            helperText={minValueFieldError || ""}
            inputProps={validationRules}
            InputProps={inputProps}
            onChange={(e) => {
              const newMinValue = isComputedValueString && isNaN(e.target.value) ? "" : e.target.value;
              setMinValue(newMinValue);
              // minValueRef.current = newMinValue;
              minValueRef.current = parseFloat(newMinValue);
              if (maxValue && parseFloat(newMinValue) > parseFloat(maxValue)) {
                setMinValueFieldError("Minimum value should not exceed maximum value");
                setValidMinValue(false);
                validMinValueRef.current = false;
              } else {
                setMinValueFieldError("");
                setValidMinValue(true);
                validMinValueRef.current = true;
                validateInput(newMinValue, "minValue");
              }

              if (validMinValueRef.current) {
                handleMinValue(e);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Maximum Value"
            variant="outlined"
            type="text"
            name="maxVal"
            // {...(!isComputedValueString && {
            //   InputProps: {
            //     inputComponent: NumericFormatCustom,
            //   },
            // })}
            value={maxValue ?? ""}
            size="small"
            margin="dense"
            error={!!maxValueFieldError}
            helperText={maxValueFieldError || ""}
            inputProps={validationRules}
            InputProps={inputProps}
            onChange={(e) => {
              const NewMaxValue = isComputedValueString && isNaN(e.target.value) ? "" : e.target.value;

              setMaxValue(NewMaxValue);
              // maxValueRef.current = NewMaxValue;
              maxValueRef.current = parseFloat(NewMaxValue);

              // if (minValue && minValue > NewMaxValue) {
              if (minValue && parseFloat(minValue) > parseFloat(NewMaxValue)) {
                setMaxValueFieldError("Maximum value should not be lesser than minimum value");
                setValidMaxValue(false);
                validMaxValueRef.current = false;
              } else {
                setMaxValueFieldError("");
                setValidMaxValue(true);
                validMaxValueRef.current = true;
                validateInput(NewMaxValue, "maxValue");
              }

              if (validMaxValueRef.current) {
                handleMaxValue(e);
              }
            }}
          />
        </Grid>
        {isValueDate &&
          <>
            {/* <Grid container item xs={12} columnSpacing={"16px"}> */}
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Computed Value Min"
                variant="outlined"
                type="text"
                value={getComputedDate?.min ?? format(new Date(), "yyyy-MM-dd")}
                size="small"
                margin="dense"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Computed Value Max"
                variant="outlined"
                type="text"
                value={getComputedDate?.max ?? format(new Date(), "yyyy-MM-dd")}
                size="small"
                margin="dense"
                disabled
              />
            </Grid>
            {/* </Grid> */}
          </>
        }
      </React.Fragment>
    );
  } else if (FUNCTIONS_WITH_ENUMS.includes(criteriaType ?? "")) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Select
            multiple
            fullWidth
            size="small"
            margin="dense"
            value={enumValues}
            onChange={handleListChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected ?? []).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {listOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </React.Fragment>
    );
  } else if (CRITERIA[criteriaType]) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          {criteriaObjects.includes(criteriaType) ? (
            <TextField
              fullWidth
              label="Value"
              variant="outlined"
              type="text"
              name="value"
              sx={{ display: !getComputedDate ? "none" : "block" }}
              value={getComputedDate}
              size="small"
              margin="dense"
              disabled
            />
          ) : (
            <TextField
              fullWidth
              label="Value"
              variant="outlined"
              type={validationRules?.type || "text"}
              // {...(!isComputedValueString &&
              //   typeof value !== "object" &&
              //   !(value instanceof Object) && {
              //     InputProps: {
              //       inputComponent: NumericFormatCustom,
              //     },
              //   })}
              name="value"
              value={value !== null ? value.toString() : ""}
              error={!!valueFieldError}
              helperText={valueFieldError || ""}
              size="small"
              margin="dense"
              inputProps={validationRules}
              InputProps={inputProps}
              onChange={handleValue}
            />
          )}
        </Grid>
        {isValueDate && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Computed Value"
              variant="outlined"
              type="text"
              value={getComputedDate ?? format(new Date(), "yyyy-MM-dd")}
              size="small"
              margin="dense"
              disabled
            />
          </Grid>
        )}
      </React.Fragment>
    );
  } else return null;
});

export default ProductCriteriaInput;
