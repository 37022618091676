import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  // FormControl,
  Grid,
  // Input,
  Stack,
  Switch,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import clsx from "clsx";
import { Delete, Edit, FileCopyTwoTone } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
// import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import AddProductDialog from "./ProductDialog/add";
import DeleteProductDialog from "./ProductDialog/delete";
import ProductDialog from "./ProductDialog";
import CommonCriteriaDialog from "./CommonCriteriaDialog";
import {
  setCurrentLender,
  setOriginalCommonCriteria,
  updateCurrentlyEditingCommonCriteria,
} from "../../store/slices/lenderSlice";
import {
  useAddProductTierMutation,
  useDeleteProductTierMutation,
  useLazyGetProductTiersByLenderQuery,
  useLazyGetLenderQuery,
  useUpdateProductTierMutation,
  useDeleteBulkProductTiersMutation,
  useCloneBulkProductTiersMutation,
} from "../../store/slices/apiSlice";
import PageLoader from "../Application/PageLoader";
import { PercentageFormatCustom } from "../../utils/percentageMaskFormat";
import {
  AGE_OF_ASSETS_AT_END,
  ERRORS_MESSAGE,
  FUNCTIONS_DATE_USING_MONTHS,
  FUNCTIONS_DATE_USING_YEARS,
  FUNCTIONS_DATE_VALUES,
  productTypes,
} from "../../constants";
import { fCurrency, fPercent } from "../../utils/formatNumber";
import { generateRandomId } from "../Utils/helperFunction";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   margin: {
//     margin: theme.spacing(1),
//   },
//   withoutLabel: {
//     marginTop: theme.spacing(3),
//   },
//   textField: {
//     width: "61ch",
//   },
// }));

function camelCaseToTitleCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
    .split(" ") // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words back together with a space
}

function getColumnHeaderString(column) {
  switch (column) {
    case "score":
      return "Score";
    case "ageOfAsset":
      return "Age";
    case "dateOfBirth":
      return "DoB";
    case "loanAmount":
      return "Loan";
    case "brokerageAmount":
      return "Brokerage";
    case "livingArrangements":
      return "Arrangement";
    default:
      return camelCaseToTitleCase(column);
  }
}

// const RateEditCell = (props) => {
//   const [editProduct] = useUpdateProductTierMutation();
//   const [maxValue, setMaxValue] = useState(props.cell.getValue()[1] ?? 0);
//   const [minValue, setMinValue] = useState(props.cell.getValue()[0] ?? 0);
//   const [value, setValue] = useState(Array.isArray(props.cell.getValue()) ? 0 : props.cell.getValue() ?? 0);
//   const [rangeToggle, setRangeToggle] = useState(Array.isArray(props.cell.getValue()) ?? false);
//   const [isHovered, setIsHovered] = useState(false);

//   const handleValue = (e) => {
//     const newValue = e.target.value;
//     switch (e.target.name) {
//       case "min":
//         setMinValue(newValue);
//         break;
//       case "max":
//         setMaxValue(newValue);
//         break;
//       default:
//         setValue(newValue);
//         break;
//     }
//   };
//   const handleKeydown = (e) => {
//     if (e.key === "Enter") {
//       props.table.setEditingCell(null);
//       switch (e.target.name) {
//         case "value":
//           debounce(
//             () =>
//               editProduct({
//                 productTierId: props.row.original._id,
//                 body: {
//                   ...props.row.original,
//                   rate: parseFloat(value),
//                 },
//               }),
//             1000,
//           )();
//           break;
//         default:
//           debounce(
//             () =>
//               editProduct({
//                 productTierId: props.row.original._id,
//                 body: {
//                   ...props.row.original,
//                   rate: [parseFloat(minValue), parseFloat(maxValue)],
//                 },
//               }),
//             1000,
//           )();
//       }
//     } else if (e.key === "Escape") {
//       props.table.setEditingCell(null);
//       setMinValue(props.cell.getValue()[0] ?? 0);
//       setMaxValue(props.cell.getValue()[1] ?? 0);
//       setValue(Array.isArray(props.cell.getValue()) ? 0 : props.cell.getValue() ?? 0);
//     }
//   };

//   const handleBlur = (e) => {
//     const newValue = e.target.value;
//     switch (e.target.name) {
//       case "min":
//         if (parseFloat(newValue) < parseFloat(maxValue)) setMinValue(parseFloat(newValue));
//         else setMinValue(parseFloat(maxValue - 1));
//         break;
//       case "max":
//         if (parseFloat(newValue) > parseFloat(minValue)) setMaxValue(parseFloat(newValue));
//         else setMaxValue(parseFloat(minValue + 1));
//         break;
//       default:
//         setValue(parseFloat(newValue));
//         break;
//     }
//     if (!isHovered) {
//       props.table.setEditingCell(null);
//       switch (e.target.name) {
//         case "value":
//           debounce(
//             () =>
//               editProduct({
//                 productTierId: props.row.original._id,
//                 body: {
//                   ...props.row.original,
//                   rate: parseFloat(value),
//                 },
//               }),
//             1000,
//           )();
//           break;
//         default:
//           debounce(
//             () =>
//               editProduct({
//                 productTierId: props.row.original._id,
//                 body: {
//                   ...props.row.original,
//                   rate: [parseFloat(minValue), parseFloat(maxValue)],
//                 },
//               }),
//             1000,
//           )();
//       }
//     }
//   };

//   const handleRangeToggle = () => setRangeToggle(!rangeToggle);

//   return (
//     <div style={{ padding: "8px" }} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}>
//       <Grid container xs={12}>
//         {rangeToggle ? (
//           <>
//             <Grid item xs={6}>
//               <TextField
//                 size="small"
//                 label="Min"
//                 name="min"
//                 onChange={(e) => handleValue(e)}
//                 onKeyDown={(e) => handleKeydown(e)}
//                 onBlur={handleBlur}
//                 value={minValue}
//                 inputProps={{ inputComponent: (props) => <NumericFormatCustom prefix="" {...props} /> }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 size="small"
//                 label="Max"
//                 name="max"
//                 onChange={(e) => handleValue(e)}
//                 onKeyDown={(e) => handleKeydown(e)}
//                 onBlur={handleBlur}
//                 value={maxValue}
//                 inputProps={{ inputComponent: (props) => <NumericFormatCustom prefix="" {...props} /> }}
//               />
//             </Grid>
//           </>
//         ) : (
//           <Grid item xs={12}>
//             <TextField
//               size="small"
//               label="Value"
//               name="value"
//               onChange={(e) => handleValue(e)}
//               onKeyDown={(e) => handleKeydown(e)}
//               value={value}
//               onBlur={handleBlur}
//               inputProps={{
//                 inputComponent: (props) => <NumericFormatCustom prefix="" {...props} />,
//               }}
//             />
//           </Grid>
//         )}
//       </Grid>
//       <br />
//       <FormControlLabel control={<Switch checked={rangeToggle} onChange={handleRangeToggle} />} label="Toggle Range" />
//     </div>
//   );
// };

// const BasicEditCell = (props) => {
//   const [editProduct] = useUpdateProductTierMutation();
//   const [value, setValue] = useState(props.cell.renderValue() ?? "");

//   const handleBlur = () => {
//     if (value === "") setValue(props.cell.renderValue());
//     else if (value !== props.cell.renderValue())
//       editProduct({
//         productTierId: props.row.original._id,
//         body: {
//           ...props.row.original,
//           [props.column.id]: value,
//         },
//       });
//     props.table.setEditingCell(null);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") handleBlur();
//     else if (e.key === "Escape") {
//       setValue(props.cell.renderValue());
//       props.table.setEditingCell(null);
//     }
//   };

//   return (
//     <TextField value={value} onKeyDown={handleKeyDown} onChange={(e) => setValue(e.target.value)} onBlur={handleBlur} />
//   );
// };

const ProductNameEditPopup = ({ cell, row }) => {
  const [editProduct] = useUpdateProductTierMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(cell.renderValue() ?? "");

  const handleOpenPopup = () => {
    setValue(cell.renderValue()); // Set the current value for editing
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleSave = () => {
    if (value !== cell.renderValue()) {
      editProduct({
        productTierId: row.original._id,
        body: {
          ...row.original,
          [cell.column.id]: value,
        },
      });
    }
    handleClose();
  };

  return (
    <>
      <div
        onClick={handleOpenPopup}
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          padding: "0",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {cell.renderValue()}
      </div>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Product Name</DialogTitle>
        <DialogContent style={{ paddingTop: "8px" }}>
          <TextField
            autoFocus
            fullWidth
            label="Product Name"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ProductTypeEditPopup = ({ cell, row }) => {
  const [editProduct] = useUpdateProductTierMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(cell.renderValue() ?? "");

  const productTypeOptions = productTypes.map((label) => ({ label, value: label }));

  const handleOpenPopup = () => {
    setValue(cell.renderValue()); // Set the current value for editing
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleSave = () => {
    if (value !== cell.renderValue()) {
      editProduct({
        productTierId: row.original._id,
        body: {
          ...row.original,
          [cell.column.id]: value,
        },
      });
    }
    handleClose();
  };

  return (
    <>
      <div
        onClick={handleOpenPopup}
        style={{
          cursor: "pointer",
          padding: "0",
          width: "100%",
          height: "100%",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {cell.renderValue()}
      </div>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            overflowY: "visible",
          },
        }}
      >
        <DialogTitle>Edit Product Type</DialogTitle>
        <DialogContent style={{ paddingTop: "8px" }}>
          <Autocomplete
            disablePortal
            value={value}
            options={productTypeOptions}
            onChange={(e, newValue) => {
              if (newValue) {
                setValue(newValue.value);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Product Type" variant="outlined" margin="dense" size="small" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RateEditPopup = ({ cell, row }) => {
  const [editProduct] = useUpdateProductTierMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [maxValue, setMaxValue] = useState(Array.isArray(cell.getValue()) ? cell.getValue()[1] : 0);
  const [minValue, setMinValue] = useState(Array.isArray(cell.getValue()) ? cell.getValue()[0] : 0);
  const [value, setValue] = useState(Array.isArray(cell.getValue()) ? 0 : cell.getValue() ?? 0);
  const [rangeToggle, setRangeToggle] = useState(Array.isArray(cell.getValue()) ?? false);
  const singleInputRef = useRef(null);
  const minInputRef = useRef(null);

  const rate = cell.getValue();

  useEffect(() => {
    if (isOpen) {
      if (rangeToggle) {
        minInputRef.current?.focus();
      } else {
        singleInputRef.current?.focus();
      }
    }
  }, [isOpen, rangeToggle]);

  const handleOpenPopup = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    if (rangeToggle) {
      // Save as an array if toggle is on
      editProduct({
        productTierId: row.original._id,
        body: {
          ...row.original,
          rate: [parseFloat(minValue), parseFloat(maxValue)],
        },
      });
    } else {
      // Save as a single value if toggle is off
      editProduct({
        productTierId: row.original._id,
        body: {
          ...row.original,
          rate: parseFloat(value),
        },
      });
    }
    handleClose();
  };

  return (
    <>
      <div
        onClick={handleOpenPopup}
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          padding: "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Array.isArray(rate) ? (
          <Stack>
            <Typography fontSize={"12px"}>
              <Typography fontSize={"12px"} fontWeight={"bold"} as="span">
                Min:{" "}
              </Typography>
              {rate[0] + "%"}
            </Typography>
            <Typography fontSize={"12px"}>
              <Typography fontSize={"12px"} fontWeight={"bold"} as="span">
                Max:{" "}
              </Typography>
              {rate[1] + "%"}
            </Typography>
          </Stack>
        ) : (
          <Typography fontSize={"12px"}>{rate + "%"}</Typography>
        )}
      </div>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Rate</DialogTitle>
        <DialogContent style={{ paddingTop: "8px" }}>
          <Grid container spacing={2}>
            {rangeToggle ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Min Rate"
                    name="min"
                    value={minValue}
                    onChange={(e) => setMinValue(Number(e.target.value))}
                    type="text"
                    InputProps={{
                      inputComponent: PercentageFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                    inputRef={minInputRef}
                    autoFocus={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Max Rate"
                    name="max"
                    value={maxValue}
                    onChange={(e) => setMaxValue(Number(e.target.value))}
                    type="text"
                    InputProps={{
                      inputComponent: PercentageFormatCustom,
                      inputProps: {
                        prefix: "",
                      },
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Rate"
                  value={value} // Use minValue for a single value
                  onChange={(e) => setValue(Number(e.target.value))}
                  type="text"
                  InputProps={{
                    inputComponent: PercentageFormatCustom,
                    inputProps: {
                      prefix: "",
                    },
                  }}
                  inputRef={singleInputRef}
                  autoFocus={true}
                />
              </Grid>
            )}
          </Grid>
          <FormControlLabel
            control={<Switch checked={rangeToggle} onChange={() => setRangeToggle(!rangeToggle)} />}
            label="Use Range"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LenderProductsTest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();
  const { lenderId } = useParams();
  const navigate = useNavigate();

  const [lenderTrigger, { data: getLenderData, getLenderIsLoading }] = useLazyGetLenderQuery(lenderId);
  const [trigger, { data, isLoading, isFetching }] = useLazyGetProductTiersByLenderQuery();
  const [editProduct, { isLoading: editProductLoading }] = useUpdateProductTierMutation();
  const [addProduct] = useAddProductTierMutation();
  const [deleteProduct, { isLoading: isDeleteLoader }] = useDeleteProductTierMutation();
  const [bulkDeleteProductTiers, { isLoading: isBulkDeleteLoader }] = useDeleteBulkProductTiersMutation();
  const [bulkCloneProductTiers] = useCloneBulkProductTiersMutation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [productsToEdit, setProductToEdit] = useState([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isBulkDeleteOpen, setIsBulkDeleteOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [commonCriteria, setCommonCriteria] = useState([]);
  const handleClose = () => setIsAddOpen(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    rate: 0,
    productType: "",
    isRateToggle: false,
    criteria: [],
  });
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  // const [columnOrder, setColumnOrder] = useState([])
  const rowVirtualizerInstanceRef = useRef(null);
  // const [isTableFetching, setIsTableFetching] = useState(false)
  const [density, setDensity] = useState("compact");

  const handleAddProduct = () => {
    const { isRateToggle, ...rest } = newProduct;
    addProduct({
      lenderId,
      body: rest,
    });
    setIsAddOpen(false);
  };

  const handleRemoveProduct = (product) => {
    deleteProduct(product._id);
    setIsDeleteOpen(false);
    setProduct(null);
  };

  const handleRemoveBulkProduct = () => {
    let productTierIdList = []; // Need selected products tier id's

    productsToEdit.forEach((product) => {
      productTierIdList.push(product?._id);
    });

    bulkDeleteProductTiers({ productTierIdList })
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.error?.data?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          setIsBulkDeleteOpen(false);
        }
      })
      .catch(() => {});
    setProductToEdit([]);
  };

  const handleCloneBulkProduct = () => {
    let productTierIdList = [];

    productsToEdit.forEach((product) => {
      productTierIdList.push(product?._id);
    });

    bulkCloneProductTiers({ lenderId, productTierIdList })
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.error?.data?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(res?.data?.status_message, {
            variant: "success",
            autoHideDuration: 5000,
          });
        }
      })
      .catch(() => {});
    setProductToEdit([]);
  };

  const handleEditProduct = (product) => {
    setIsDialogOpen(true);
    setProduct(product);
  };

  const handleDeleteDialog = (product) => {
    setIsDeleteOpen(true);
    setProduct(product);
  };

  const handleCloneProduct = (product) => {
    const { lender: lenderId, createdAt, updatedAt, __v, _id, ...rest } = product;

    addProduct({
      lenderId,
      body: {
        ...rest,
      },
    });
  };

  useEffect(() => {
    lenderTrigger(lenderId);
    trigger({ lenderId });
  }, [lenderId]);

  useEffect(() => {
    if (getLenderData && !getLenderIsLoading) {
      dispatch(setCurrentLender(getLenderData?.lender));
    }
  }, [getLenderData, getLenderIsLoading]);

  // useEffect(() => {
  //   if (!isFetching) setIsTableFetching(false)
  // }, [isFetching])

  useEffect(() => {
    const products = docs.filter((doc) => Object.keys(rowSelection).includes(doc._id));
    setProductToEdit(products);
  }, [rowSelection]);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  useEffect(() => {
    if (productsToEdit.length < 2) setIsDrawerOpen(false);
    if (productsToEdit.length > 1) {
      let criteria = [];
      const uniqueCriteria = new Set();
      productsToEdit.forEach((product) => {
        product?.criteria.forEach((criterion) => {
          const key = `${criterion.object}-${criterion.attribute}-${criterion.condition}`;
          uniqueCriteria.add(key);
        });
      });
      criteria = Array.from(uniqueCriteria).map((key, id) => {
        const [object, attribute, condition] = key.split("-");
        return { id: generateRandomId(7), object, attribute, condition, value: 0, valueMinMax: { min: 0, max: 0 } };
      });
      setCommonCriteria(criteria);
      dispatch(setOriginalCommonCriteria(criteria));
      dispatch(updateCurrentlyEditingCommonCriteria(criteria));
    } else {
      dispatch(updateCurrentlyEditingCommonCriteria([]));
      setCommonCriteria([]);
    }
  }, [productsToEdit]);

  const docs = useMemo(() => {
    if (data && !isFetching) return data?.docs;
    return [];
  }, [data, isLoading, isFetching]);

  const columns = useMemo(() => {
    const criteriaList = () => {
      switch (data?.lenderType) {
        case "commercial":
          return [
            "timeInBusiness",
            "gst",
            "score",
            "ageOfAsset",
            "assetType",
            "livingArrangements",
            "loanAmount",
            "deposit",
            "condition",
          ];
        case "consumer":
          return [
            "employmentType",
            "timeOfEmployment",
            "score",
            "ageOfAsset",
            "assetType",
            "livingArrangements",
            "loanAmount",
            "deposit",
            "condition",
            "dateOfBirth",
          ];
        case "personal":
          return ["employmentType", "score", "livingArrangements", "loanAmount"];
        default:
          return [];
      }
    };
    // const getColumnType = (string) => {
    //   if (string === "timeInBusiness" || string === "gst") {
    //     return "date";
    //   } else if (string === "ageOfAsset" || string === "creditScore") {
    //     return "number";
    //   } else if (string === "assetType" || string === "employmentType") {
    //     return "string";
    //   }
    // };
    const getValue = (criteria, type) => {
      const { value = null, label = "", condition = "", attribute = "" } = criteria;
      const isValueDateUsingMonths = FUNCTIONS_DATE_USING_MONTHS.some((prefix) => type?.includes(prefix));
      const isValueDateUsingYears = FUNCTIONS_DATE_USING_YEARS.some((prefix) => type?.includes(prefix));
      const isAgeOfAsset = FUNCTIONS_DATE_VALUES.some(
        (name) => type.includes(name) && !AGE_OF_ASSETS_AT_END.some((t) => type === t),
      );

      if (attribute === "timeOfEmployment") {
        return value;
      }

      if (type === "deposit") {
        return fPercent(value);
      }

      if (Array.isArray(value)) {
        return value.join(", ");
      }

      if (typeof value === "object" && value instanceof Object) {
        const isValueDateValid = moment(value.min).isValid() && moment(value.max).isValid();

        if (isValueDateUsingMonths && isValueDateValid) {
          if (isAgeOfAsset && condition.includes("between")) {
            const matches = (label ?? "").match(/(\d+)\s*-\s*(\d+)\s*mths/);
            if (matches && matches[1] && matches[2]) {
              return `${parseInt(matches[1])} - ${parseInt(matches[2])} month/s`;
            }
          }
          return `${moment(moment()).diff(value.max, "M")} - ${moment(moment()).diff(value.min, "M")} month/s`;
        }

        if (isValueDateUsingYears && isValueDateValid) {
          return `${moment(moment()).diff(value.max, "years")} - ${moment(moment()).diff(value.min, "years")}`;
        }
        if (attribute === "loanAmount") return `${fCurrency(value.min)} - ${fCurrency(value.max)}`;
        if (type === "score" && condition !== "between") return value;
        return `${value.min} - ${value.max}`;
      }

      if (typeof value === "string" || typeof value === "number") {
        if (condition?.includes("greaterThan")) {
          const matches = (label ?? "").match(/<(\d+)\s*mths/);
          if (matches && matches?.[1]) {
            return matches?.[1] && `${parseInt(matches[1])}`;
          }
        }
        if (condition?.includes("lessThan")) {
          const matches = (label ?? "").match(/>(\d+)\s*mths/);
          if (matches && matches?.[1]) {
            return matches?.[1] && `${parseInt(matches[1])}`;
          }
        }
        return typeof value === "number" && type !== "score" ? `${fCurrency(value)}` : value;
      }

      if (moment(value).isValid() && type !== "score") {
        if (isValueDateUsingMonths && isAgeOfAsset) {
          if (condition.includes("greaterThan")) {
            const matches = (label ?? "").match(/<(\d+)\s*mths/);
            if (matches && matches[1]) {
              return `${parseInt(matches[1])} month/s`;
            }
          }
          if (condition.includes("lessThan")) {
            const matches = (label ?? "").match(/>(\d+)\s*mths/);
            if (matches && matches[1]) {
              return `${parseInt(matches[1])} month/s`;
            }
          }
        }
        return moment(moment()).diff(value, "M");
      }

      if (moment(value).isValid() && isValueDateUsingYears) {
        return `${moment(moment()).diff(value, "years")} years`;
      }

      return value;
    };
    const criteriaSet = new Set();
    docs.forEach((doc) => {
      doc.criteria.forEach((c) => {
        if (criteriaList().includes(c.attribute) && !AGE_OF_ASSETS_AT_END.some((t) => c.attribute === t))
          criteriaSet.add(c.attribute);
      });
    });

    const columns = [
      {
        accessorKey: "actionButtons",
        header: "Actions",
        columnDefType: "display",
        enableEditing: false,
        minSize: 130, //min size enforced during resizing
        maxSize: 5, //max size enforced during resizing
        Cell: ({ row }) => (
          <div style={{ textAlign: "center", margin: 0 }}>
            <Tooltip title="Clone" arrow>
              <IconButton disableRipple onClick={() => handleCloneProduct(row.original)} variant="contained">
                <FileCopyTwoTone />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" arrow>
              <IconButton disableRipple onClick={() => handleEditProduct(row.original)} variant="contained">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <IconButton disableRipple onClick={() => handleDeleteDialog(row.original)} variant="contained">
                <Delete color="error" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      {
        enableEditing: false,
        accessorKey: "name",
        header: "Name",
        minSize: 110,
        maxSize: 0,
        Cell: (params) => <ProductNameEditPopup {...params} />,
      },
      {
        accessorKey: "productType",
        header: "Type",
        enableEditing: false,
        minSize: 100,
        maxSize: 0,
        Cell: (params) => <ProductTypeEditPopup {...params} />,
        // Edit: (params) => <BasicEditCell {...params} />,
        // Cell: ({ cell }) => <Typography fontSize={"14px"}>{cell.getValue()}</Typography>,
      },
      {
        accessorKey: "rate",
        header: "Rate",
        minSize: 80,
        maxSize: 0,
        enableEditing: false,
        Cell: (params) => <RateEditPopup {...params} />,
        // // Edit: (params) => <RateEditCell {...params} />,
        // Cell: (params) => {
        // const rate = params.cell.getValue();
        // if (Array.isArray(rate))
        //   return (
        //     <Stack>
        //       <Typography fontSize={"14px"}>
        //         <Typography fontWeight={"bold"} as="span">
        //           Min:{" "}
        //         </Typography>
        //         {rate[0] + "%"}
        //       </Typography>
        //       <Typography fontSize={"14px"}>
        //         <Typography fontWeight={"bold"} as="span">
        //           Max:{" "}
        //         </Typography>
        //         {rate[1] + "%"}
        //       </Typography>
        //     </Stack>
        //   );
        // return <Typography fontSize={"14px"}>{rate + "%"}</Typography>;
        // },
      },
      ...Array.from(criteriaSet, (c) => ({
        accessorKey: c,
        header: `${getColumnHeaderString(c)}`,
        minSize: Math.max(getColumnHeaderString(c).length * 14.3, 105),
        maxSize: 0,
        Cell: (params) => {
          let value;
          params.row.original.criteria.find((cc) => {
            if (cc.attribute === c) {
              if (cc.attribute === "deposit" && cc.condition === "between") {
                // Don't let depositBetween overwrite hasDeposit
                return null;
              }
              return (value = getValue(cc, c));
            }
            return null;
          });
          return value ?? "";
        },
        enableEditing: false,
        filterFn: (row) => {
          const filterValue = columnFilters.find((filter) => filter.id === c).value;
          let value;
          row.original.criteria.find((cc) => {
            if (cc.attribute === c) return (value = getValue(cc, c));
            return null;
          });
          return value !== undefined && value.toString().toLowerCase().includes(filterValue.toLowerCase());
        },
        sortingFn: (rowA, rowB) => {
          const regex = /(\d+|\D+)/g;
          let valueA;
          let valueB;

          rowA.original.criteria.find((cc) => {
            if (cc.attribute === c) return (valueA = getValue(cc, c));
            return null;
          });
          rowB.original.criteria.find((cc) => {
            if (cc.attribute === c) return (valueB = getValue(cc, c));
            return null;
          });

          const partsA = valueA?.toString()?.match(regex);
          const partsB = valueB?.toString()?.match(regex);

          for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
            const partA = partsA[i] || "";
            const partB = partsB[i] || "";

            const isNumericA = !isNaN(partA);
            const isNumericB = !isNaN(partB);

            if (isNumericA && isNumericB) {
              // Compare as numbers
              const diff = parseInt(partA, 10) - parseInt(partB, 10);
              if (diff !== 0) return diff;
            } else {
              // Compare as strings
              if (partA < partB) return -1;
              if (partA > partB) return 1;
            }
          }
          return 0;
        },
      })),
      {
        accessorKey: "updatedAt",
        header: "Updated",
        enableEditing: false,
        Cell: (params) => {
          const dateString = moment(params.cell.getValue()).format("M-DD-YY");
          const timeString = moment(params.cell.getValue()).format("h:mm a");
          return (
            <div>
              <Typography style={{ fontSize: "12px" }}>{dateString}</Typography>
              <Typography style={{ fontSize: "9px", color: "#999" }}>{timeString}</Typography>
            </div>
          );
        },
      },
    ];

    return columns;
  }, [docs, editProductLoading, isLoading, isFetching, columnFilters]);

  const table = useMaterialReactTable({
    data: editProductLoading ? [] : docs,
    columns,
    enablePagination: false,
    enableRowVirtualization: true,
    rowVirtualizerOptions: {
      overscan: 8,
      estimateSize: () => 340,
    },
    enableRowSelection: true,
    // enableRowActions: true,
    // positionActionsColumn: "last",
    enableEditing: true,
    editDisplayMode: "cell",
    state: {
      density,
      isLoading,
      showProgressBars: editProductLoading || isFetching,
      rowSelection,
      columnFilters,
      sorting,
      columnOrder: [
        "mrt-row-select",
        ...columns.filter((col) => col.accessorKey !== "updatedAt").map((col) => col.accessorKey),
        // "mrt-row-actions",
        "actionButtons",
        "updatedAt",
      ],
    },
    onDensityChange: setDensity,
    enableDensityToggle: true,
    rowVirtualizerInstanceRef,
    getRowId: (row) => row._id,
    // renderRowActionMenuItems: ({ row }) => [
    //   <IconButton disableRipple onClick={() => handleCloneProduct(row.original)} variant="contained">
    //     <FileCopyTwoTone />
    //   </IconButton>,
    //   <IconButton disableRipple onClick={() => handleEditProduct(row.original)} variant="contained">
    //     <Edit />
    //   </IconButton>,
    //   <IconButton disableRipple onClick={() => handleDeleteDialog(row.original)} variant="contained">
    //     <Delete color="error" />
    //   </IconButton>,
    // ],
    onRowSelectionChange: setRowSelection,
    enableStickyHeader: true,
    enableBottomToolbar: false,
    muiTableContainerProps: {
      sx: { maxHeight: "800px", tableLayout: "auto" }, //give the table a max height
    },
    enableColumnResizing: false,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    isMultiSortEvent: () => true,
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: () => table.setEditingCell(cell),
      sx: {
        fontSize: "12px",
        paddingTop: "0",
        paddingBottom: "0",
      },
    }),
    muiTableBodyRowProps: () => ({
      sx: {
        margin: 0,
      },
    }),
  });

  const handleOnBack = () => {
    navigate(`/lenders/${lenderId}`);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Grid
          container
          style={{
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "95vw",
          }}
        >
          <PageLoader text="Products loading..." />
        </Grid>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={2} sx={{ padding: "20px", minWidth: "99vw" }}>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <Button variant="text" sx={{ textTransform: "none", color: "black", ml: "-14px" }} onClick={handleOnBack}>
                <ArrowBackIcon htmlColor={deepPurple[500]} />
                {/* Back to details */}
              </Button>

              <Typography
                variant="h1"
                style={{
                  fontSize: "24px",
                  fontWeight: 800,
                  letterSpacing: "-0.5px",
                }}
              >
                Products
              </Typography>
              {/* <div className={classes.root}>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <Input
                    className="input-border-bottom"
                    id="application-search"
                    autoComplete={false}
                    variant="filled"
                    placeholder="Search…"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      debounced(e.target.value, 1000);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      searchTerm && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setSearchTerm("");
                              searchTerm !== "" && debounced("", 1000);
                            }}
                            color="secondary"
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </div> */}
              <Grid item xs={12}>
                <Stack justifyContent={"flex-end"} direction={"row"} columnGap={"16px"}>
                  <Button
                    variant="contained"
                    onClick={() => setIsDrawerOpen(true)}
                    sx={{ display: productsToEdit.length <= 1 ? "none" : "block" }}
                  >
                    EDIT PRODUCTS {`(${productsToEdit.length})`}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCloneBulkProduct}
                    sx={{ display: productsToEdit.length <= 1 ? "none" : "block" }}
                  >
                    CLONE PRODUCTS {`(${productsToEdit.length})`}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setIsBulkDeleteOpen(true)}
                    sx={{ display: productsToEdit.length <= 1 ? "none" : "block" }}
                  >
                    DELETE PRODUCTS {`(${productsToEdit.length})`}
                  </Button>
                  <Button variant="contained" onClick={() => setIsAddOpen(true)}>
                    ADD PRODUCT
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <Grid item sx={{ maxWidth: "100% !important", overflowX: "scroll" }}>
              <MaterialReactTable table={table} />
            </Grid>
          </Grid>
          <AddProductDialog
            isAddOpen={isAddOpen}
            handleClose={handleClose}
            handleAddProduct={handleAddProduct}
            newProduct={newProduct}
            setNewProduct={setNewProduct}
          />
          <DeleteProductDialog
            isLoading={isDeleteLoader}
            isDeleteOpen={isDeleteOpen}
            setIsDeleteOpen={setIsDeleteOpen}
            handleRemoveProduct={handleRemoveProduct}
            product={product}
            isBulkDelete={false}
          />
          <DeleteProductDialog
            isLoading={isBulkDeleteLoader}
            isDeleteOpen={isBulkDeleteOpen}
            setIsDeleteOpen={setIsBulkDeleteOpen}
            handleRemoveBulkProduct={handleRemoveBulkProduct}
            isBulkDelete={true}
          />
          <ProductDialog
            product={product}
            setProduct={setProduct}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            editProduct={editProduct}
          />
          <CommonCriteriaDialog
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            commonCriteria={commonCriteria}
            setCommonCriteria={setCommonCriteria}
            productsToEdit={productsToEdit}
            setProductToEdit={setProductToEdit}
            editProduct={editProduct}
            setRowSelection={setRowSelection}
            // setIsTableFetching={setIsTableFetching}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LenderProductsTest;
