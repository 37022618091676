import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import ActionSection from "./Sections/actions";
import BasicDetailSection from "./Sections/basicDetails";
import InformationSection from "./Sections/information";
import FieldSection from "./Sections/fields";
import CredentialSection from "./Sections/credentials";
import FeeSection from "./Sections/fees";
import LoadingSection from "./Sections/loading";
import BrokerageSection from "./Sections/brokerage";
import ProductTierSection from "./Sections/producttiers";
// import { useLazyGetLenderQuery } from "../../../store/slices/apiSlice";
// import { lenderSelector, setCurrentLender } from "../../../store/slices/lenderSlice";
import { getLender, lenderSelector } from "../../../store/slices/lenderSlice";
import PageLoader from "../../Application/PageLoader";

const Lender = React.memo(() => {
  const dispatch = useDispatch();
  const { lenderId } = useParams();
  // const navigate = useNavigate();
  const { lender: lenderState, requiredFieldOptions, isGettingLenderData } = useSelector(lenderSelector);

  // const [trigger, { data, isLoading, isError }] = useLazyGetLenderQuery(lenderId);
  useEffect(() => {
    if (lenderId) {
      // trigger(lenderId)
      dispatch(getLender({ lenderId }));
    }
  }, [lenderId]);

  // useEffect(() => isError && navigate("/lenders"), [isError]);

  // const [lender, setLender] = useState(null); // Not in Use need to remove

  // const [fieldOptions, setFieldOptions] = useState(null);

  // useEffect(() => {
  //   if (data && !isLoading) {
  //     setLender(data?.lender ?? {});
  //     dispatch(setCurrentLender(data?.lender));
  //     setFieldOptions(data?.requiredFieldOptions ?? []);
  //   }
  //   // TODO: no-op, remove once all components under lender no longer depend on this state since we are using Redux lender state
  //   if (lender) {
  //   }
  // }, [data, isLoading]);

  return (
    // <React.Fragment>
    //   {getLenderLoading ? (
    //     <Grid
    //       container
    //       style={{
    //         marginTop: "64px",
    //         padding: "30px 0px 100px",
    //         height: "calc(100vh - 100px)",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <PageLoader text="Lender loading..." />
    //     </Grid>
    //   ) : (
    //     <Grid container item xs={12}>
    //       <Grid container item xs={12} padding={{ xs: 0, sm: "20px 40px 20px 40px" }}>
    //         <Typography
    //           variant="h3"
    //           style={{
    //             fontSize: "22px",
    //             margin: "0 0 50px",
    //           }}
    //         >
    //           Edit Lender Product
    //         </Typography>
    //         <BasicDetailSection lender={lenderState} setLender={setLender} />
    //         <ProductTierSection lender={lenderState} />
    //         <InformationSection lender={lenderState} setLender={setLender} />
    //         <ActionSection lender={lenderState} setLender={setLender} />
    //         <FieldSection
    //           lender={lenderState}
    //           setLender={setLender}
    //           fieldOptions={fieldOptions}
    //           setFieldOptions={setFieldOptions}
    //         />
    //         <CredentialSection lender={lenderState} setLender={setLender} />
    //         <FeeSection lender={lenderState} setLender={setLender} />
    //         <LoadingSection lender={lenderState} setLender={setLender} />
    //         <BrokerageSection lender={lenderState} setLender={setLender} />
    //       </Grid>
    //     </Grid>
    //   )}
    // </React.Fragment>
    <React.Fragment>
      {/* {(!isLoading && lenderState) ? ( */}
      {!isGettingLenderData && lenderState ? (
        <Grid container item xs={12} minWidth={"95vw"}>
          <Grid container item xs={12} padding={{ xs: 0, sm: "20px 40px 20px 40px" }}>
            <Typography
              variant="h3"
              style={{
                fontSize: "22px",
                margin: "0 0 50px",
              }}
            >
              Edit Lender Product
            </Typography>
            <BasicDetailSection lender={lenderState} />
            <ProductTierSection lender={lenderState} />
            <InformationSection lender={lenderState} />
            <ActionSection lender={lenderState} />
            <FieldSection
              lender={lenderState}
              // setLender={setLender}
              fieldOptions={requiredFieldOptions}
              // fieldOptions={fieldOptions}
              // setFieldOptions={setFieldOptions}
            />
            {/* <CredentialSection lender={lenderState} setLender={setLender} /> */}
            <CredentialSection lenderState={lenderState} />
            <FeeSection
              lender={lenderState} //setLender={setLender}
            />
            <LoadingSection lender={lenderState} />
            <BrokerageSection lender={lenderState} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          style={{
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "95vw",
          }}
        >
          <PageLoader text="Lender loading..." />
        </Grid>
      )}
    </React.Fragment>
  );
});

export default Lender;
